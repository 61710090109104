// Importing createSlice from the Redux Toolkit library
import { createSlice } from "@reduxjs/toolkit";

// Get the workspaceId from the localStorage
const workspaceId = localStorage.getItem('workspaceId');

// Initial state for the reducer
const initialState = {
    workspaceName: '',     // String to hold the workspace name
    workspaceId: workspaceId,   // String to hold the workspace ID retrieved from localStorage
}

// Creating a slice of the Redux store using createSlice
export const workspaceSlice = createSlice({
    name: "workspace",    // Name of the slice, which can be used to access the state in the Redux store
    initialState,         // Initial state of the slice
    reducers: {
        // Reducer function to set the workspaceName state with the provided payload
        setWorkspaceName: (state, action) => {
            state.workspaceName = action.payload;
        },
        // Reducer function to set the workspaceId state with the provided payload
        setWorkspaceId: (state, action) => {
            state.workspaceId = action.payload;
        },
    },
});

// Exporting the action creators generated by createSlice
export const { setWorkspaceName, setWorkspaceId } = workspaceSlice.actions;

// Exporting the reducer generated by createSlice
export default workspaceSlice.reducer;

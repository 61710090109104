// Import required modules from external libraries and components
import React, { useState } from "react";
import { IonButton, IonInput, IonGrid, IonRow, IonCol, IonLabel, IonIcon, IonToast } from "@ionic/react";
import { personCircleSharp, keySharp, phonePortraitSharp, mailSharp } from 'ionicons/icons';
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import IonIconButton from "./Button/IonIconButton";
import { ENV } from "../config/config";
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";

// Define the RegistrationStep1 component as a functional component with props 'next' and 'flag'
export const RegistrationStep1: React.FC<any> = ({ next, flag}) => {
    const { getValues, control, formState: { errors } } = useFormContext(); // retrieve all hook methods 
    // State variables to manage toasts and messages
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [buttonActive , setButtonActive] = useState(true);
    // Function to open a URL in a new tab
    const openInNewTab = (url: string | URL | undefined) => {
        window.open(ENV.WebsiteURL + url, '_blank', 'noopener,noreferrer');
    };
    // The main JSX content of the component

    return (
        <div className="slide-main ion-padding">
            <div className="form-content">
                <IonGrid>
                    {/* Back arrow and text */}
                    <div className="back-arrow">
                        <IonIconButton buttonClassName="ion-text-right" routerLink="/index" icon="/assets/images/arrow-left-icon.svg" />
                        <h4>Back to Sign In</h4>
                    </div>
                    <h1>Registration with CSM</h1>
                    {/* Form inputs */}
                    <IonRow>
                        <IonCol size="12" className="email-field">
                            <IonLabel className="form-lable">First Name*</IonLabel>
                            <div className="input-with-icon">
                                <IonIcon icon={personCircleSharp} />
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <IonInput
                                            type="text"
                                            onIonChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            placeholder="Add Text"
                                            mode="md" />
                                    )}
                                    control={control}
                                    name="firstName"
                                    rules={{
                                        required: "First Name is required",
                                        minLength: {
                                            value: 3,
                                            message: "First Name should have minimum 3 character."
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: "First Name should not exceed 25 character."
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/i,
                                            message: "Enter a valid name",

                                        }
                                    }} />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="firstName"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="12" className="password-field">
                            <IonLabel className="form-lable">Last Name*</IonLabel>
                            <div className="input-with-icon">
                                <IonIcon icon={personCircleSharp} />
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <IonInput
                                            type="text"
                                            onIonChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            placeholder="Add Text"
                                            mode="md" />
                                    )}
                                    control={control}
                                    name="lastName"
                                    rules={{
                                        required: "Last Name is required",
                                        minLength: {
                                            value: 3,
                                            message: "Last Name should have minimum 3 character."
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: "Last name should not exceed 25 character."
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/i,
                                            message: "Enter a valid name",

                                        }
                                    }} />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="12" className="forget-password">
                            <IonLabel className="form-lable">Mobile No.*</IonLabel>
                            <div className="input-with-icon">
                                <IonIcon icon={phonePortraitSharp} />
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <IonInput
                                            type="tel" // Change type to tel for mobile numbers
                                            onIonChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                                            placeholder="Add Text"
                                            mode="md"
                                            onKeyDown={(e) => {
                                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' ) {
                                                    e.preventDefault();
                                                }

                                              
                                                if (value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                    e.preventDefault(); // Prevent input if length is already 10
                                                }
                                               
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name="telephone"
                                    rules={{
                                        required: "Telephone is required",
                                        minLength: {
                                            value: 10,
                                            message: "Please enter a valid phone number"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Please enter a valid phone number"
                                        }
                                    }} />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="telephone"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="12" className="email-field">
                            <IonLabel className="form-lable">Email*</IonLabel>
                            <div className="input-with-icon">
                                <IonIcon icon={mailSharp} />
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <IonInput
                                            type="email"
                                            onIonChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Add Text"
                                            mode="md" />
                                    )}
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Invalid email address",

                                        },
                                        minLength: {
                                            value: 6,
                                            message: "Email is too short"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "Email is too long"
                                        }
                                    }} />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="12" className="password-field">
                            <IonLabel className="form-lable">Password*</IonLabel>
                            <div className="input-with-icon">
                                <IonIcon icon={keySharp} />
                                <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <IonInput
                                            type="password"
                                            onIonChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Add Text"
                                            mode="md" />
                                    )}
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: 'Password is required',
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/i,
                                            message: 'The password must match the following criteria: Minimum 8 characters, Include at least 1 letter, Include at least 1 number,Include at least 1 special character and maximum 12 characters long.'
                                        }
                                    }} />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="password"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="8" className="sign-up-btn ion-align-self-center ion-margin-auto">
                            <IonButton expand="block" shape="round" fill="solid" disabled={!buttonActive}
                                onClick={
                                    () => {
                                        if(buttonActive){

                                       
                                        setButtonActive(false)
                                        if (getValues('email') == undefined || getValues('firstName') == undefined ||
                                            getValues('lastName') == undefined || getValues('telephone') == undefined
                                            || getValues('password') == undefined ||
                                            getValues('email') == "" || getValues('firstName') == "" ||
                                            getValues('lastName') == "" || getValues('telephone') == ""
                                            || getValues('password') == "") {
                                            next(
                                                ['firstName', 'lastName', 'telephone', 'email', 'password']
                                            )
                                        }
                                        else {
                                            axios({
                                                method: 'get',
                                                url: ENV.ENDPOINT + '/users?filters[email][$eq]=' + getValues('email'),
                                                headers: {
                                                    Authorization: HeaderAuthorization.Authorization,
                                                    PageLocation: "registration",
                                                },

                                            }).then((res) => {

                                                if (res.data.length != 0) {
                                                    setColordata("danger");
                                                    setMessage("Email is already taken");
                                                    setShowToast1(true)
                                                    flag(false)
                                                }
                                                else {
                                                    axios({
                                                        method: 'get',
                                                        url: ENV.ENDPOINT + '/users?filters[mobileNumber][$eq]=' + getValues('telephone'),
                                                        headers: {
                                                            Authorization: HeaderAuthorization.Authorization,
                                                            PageLocation: "registration",
                                                        },
                                                    }).then((response) => {
                                                        if (response.data.length != 0) {
                                                            setColordata("danger");
                                                            setMessage("Mobile is already taken");
                                                            setShowToast1(true)
                                                            flag(false)
                                                        }
                                                        else {
                                                            let username = getValues('firstName') + getValues('lastName');
                                                            axios({
                                                                method: 'get',
                                                                url: ENV.ENDPOINT + '/users?filters[username][$eq]=' + username,
                                                                headers: {
                                                                    Authorization: HeaderAuthorization.Authorization,
                                                                    PageLocation: "registration",
                                                                },
                                                            }).then((response) => {
                                                                if (response.data.length != 0) {
                                                                    setColordata("danger");
                                                                    setMessage("Username is already taken");
                                                                    setShowToast1(true)
                                                                    flag(false)
                                                                } else {
                                                                    next(
                                                                        ['firstName', 'lastName', 'telephone', 'email', 'password']
                                                                    ).then((res: any) => {
                                                                        if (res == false) {
                                                                            flag(false)
                                                                        }
                                                                        else {
                                                                            flag(true)
                                                                        }
                                                                    })
                                                                }
                                                            }).catch(() => {
                                                                setColordata("danger");
                                                                setMessage("User Name is Invalid");
                                                                setShowToast1(true)

                                                            })
                                                        }
                                                    }).catch(() => {
                                                        setColordata("danger");
                                                        setMessage("Mobile number is Invalid");
                                                        setShowToast1(true)
                                                    })
                                                }
                                            })
                                        }
                                        setTimeout(() => {
                                            setButtonActive(true);
                                          }, 2000);
                                    } }} >
                                Continue
                            </IonButton>
                        </IonCol>
                        {/* Privacy and Terms note */}
                        <IonCol size="12" className="form-footer-note">
                            <p>by continuing, <br />
                                you are agreeing to our <IonButton className="ion-text-right" fill="clear" onClick={() => openInNewTab('/privacy-terms')} >Privacy & Terms</IonButton></p>
                        </IonCol>
                        {/* Toast for showing error messages */}
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1500}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default RegistrationStep1;
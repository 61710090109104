// Import necessary modules and components
import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {IonContent,IonPage, IonButton,IonGrid, IonRow,IonCol,IonIcon, IonSegment, IonSegmentButton,IonSlides,IonSlide, IonImg, IonToast} from '@ionic/react';
import Header from "../components/Header/Header";
import StockInwardReport from "../components/StockInwardReport";
import StockOutwardReport from "../components/StockOutwardReport";
import axios from "axios";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import moment from 'moment'
import Loader from "../components/Loader/Loader";
import Logout from "../components/Logout";

// Define interfaces for type checking
interface LocationState {
    id: number;
    categoryId: number;
    categoryName: string;
    status: string;
    categoryURL: string;
    materialName: string;
    quantity: number;
    materialId: number;
    categoryTrace: object;
}
interface MaterialState {
    id: number;
    materialName: string;
    currentStock: number;
    materialUnitName: string;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}

// Define the StockReport component
const StockReport: React.FC = () => {
    let history = useHistory();
    const locations = useLocation();
    // Define states using useState hook
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [stockMaterial, setStockMaterial] = useState('');
    const [stockMaterialId, setStockMaterialId] = useState(0);
    const [StockQuantity, setStockQuantity] = useState(0);
    const [categoryUrl, setCategoryUrl] = useState('');
    const [load, setLoad] = useState(true);
    const [materialUnitName, setMaterialUnitName] = useState('');
    const [inwardTotal, setInwardTotal] = useState(0);
    const [outwardTotal, setOutwardTotal] = useState(0);
    const [outwardEnable, setOutwardEnable] = useState(false);
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    const user_roles = localStorage.getItem('work_roles');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [subCategory, setSubCategory] = useState({});
    const [isloading, setIsloading] = useState(true);    
    const [categoryTrace, setCategoryTrace] = useState({});
    const userId:any = localStorage.getItem('userid');
    const token:any = localStorage.getItem('token');
    const [buttonActive , setButtonActive] = useState(true)


    const defaultList = [
        {
            id: 1,
            inwardDate: "",
            inwardTime: "",
            inwardUser: "",
            inwardQuantity: 0,
            inwardBrand: "",
            inwardYear: ""
        },
    ];
    const OutwardDefaultList = [
        {
            id: 1,
            outwardDate: "",
            outwardTime: "",
            outwardUser: "",
            outwardBrand: "",
            outwardQuantity: 0,
            outwardYear: "",
        },
    ];
    const [inwardReportList, setInwardReportList] = useState(defaultList);
    const [outwardReportList, setoutwardReportList] = useState(OutwardDefaultList);
    const doNothing = () => {
    }
    sessionStorage.setItem("reloading", "false");  
    const backPage = () => {
        // history.go(-1)
        setLoad(false);
        var pushCategoryTrace = {} as pushState
        if (categoryTrace) {
            const categoryPush = categoryTrace as pushState
            pushCategoryTrace['categoryName'] = categoryPush['categoryName']
            pushCategoryTrace['categoryId'] = categoryPush['categoryId']
        }
        pushCategoryTrace["pushBack"] = true
        pushCategoryTrace["subCategory"] = subCategory;
        if (stockMaterialId == 0 || stockMaterialId == undefined) {
            history.push({
                pathname: '/stock-category',
                state: pushCategoryTrace
            });
        } else {
            history.push({
                pathname: "/material-stock",
                state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace }
            }
            );
        }      
    }
    const pieReport = () => {
        history.push({
            pathname: "/stock-used-report",
            state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace, "materialId": stockMaterialId, "materialName": stockMaterial, "categoryURL": categoryUrl }
        }
        );
    }

    const [flag , setFlag] = useState(0)
     // Effect hook to fetch data on component mount
    React.useEffect(() => {
        let location: any;        
        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (location) {
            setFlag(location.flag)            
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setCategoryTrace(location.categoryTrace);
            setStockMaterial(location.materialName);
            setStockMaterialId(location.materialId)
            setSubCategory(location.subCategory)
            let urlInwardLink: any;
            let urlOutwardLink: any;
           
            if (location.materialId && location.flag == 0) {
                urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + location.materialId;
            } else {
                urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId;
            }
            const fetchData = async () =>{
                try {
                    const response = await  axios({
                        url: urlInwardLink,
                        method: 'get',
                        headers: {
                            Authorization : HeaderAuthorization.Authorization,
                            PageLocation : "stockReport",
                            userId:userId,
                            token:token,
                        },
                    })
                        const entriesArr = [];
                        for (let [index, value] of response.data['data'].entries()) {                       
                           
                            let brandData = '';
                            if (value.attributes.material_brand.data != null) {
                                brandData = value.attributes.material_brand.data.attributes.material_brand_name;
                            }
                            let inwardDate = new Date(value.attributes.createdAt).toISOString();
                            inwardDate = format(parseISO(inwardDate), 'dd-MM-yyyy')

                           
                            let userdata = '';
                            if (value.attributes.inward_createdby.data != null) {
                                userdata = value.attributes.inward_createdby.data.attributes.firstName;
                            }
                            entriesArr.push({
                                id: ++index,
                                inwardDate: value.attributes.inward_date,
                                inwardYear: value.attributes.inward_date,
                                inwardTime: value.attributes.createdAt.split('.')[0],
                                inwardUser: userdata,
                                inwardBrand: brandData,
                                inwardQuantity: value.attributes.inward_quantity
                            })
                        
                        }                       
                        setInwardReportList(entriesArr)
                 
                } catch (error: any) {
                    console.log(error); 
                    if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
                        Logout(history)
                      }           
                } 
            }
            if (location.materialId && location.flag == 0) {
                urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + location.materialId;
            } else {
                urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId;
            }

            const myData = async () =>{
                try {
                    const response = await axios({
                    url: urlOutwardLink,
                    method: 'get',
                    headers: {
                        Authorization : HeaderAuthorization.Authorization,
                        PageLocation : "stockReport",
                        userId:userId,
                        token:token,
                    },
                })
                    const outentriesArr = [];
                    for (let [index, value] of response.data['data'].entries()) {
                        let brandData = '';
                        if (value.attributes.material_brand != null) {
                            brandData = value.attributes.material_brand.data.attributes.material_brand_name;
                        }                    
                        let outwardDate = new Date(value.attributes.createdAt).toISOString();
                        outwardDate = format(parseISO(outwardDate), 'dd-MM-yyyy')
                        let userdata = '';
                        if (value.attributes.outward_createdby.data != null) {
                            userdata = value.attributes.outward_createdby.data.attributes.firstName;
                        }
                        outentriesArr.push({
                            id: ++index,
                            outwardDate: value.attributes.outward_date,
                            outwardYear: value.attributes.outward_date,
                            outwardTime: value.attributes.createdAt.split('.')[0],
                            outwardUser: userdata,
                            outwardBrand: brandData,
                            outwardQuantity: value.attributes.outward_quantity
                        })
                    }
                    setoutwardReportList(outentriesArr)
             
                } catch (error: any) {
                    console.log(error); 
                    if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
                        Logout(history)
                      }           
                } 
            }
          
            let urlInward:string;
            if (location.materialId && location.flag == 0) {
                urlInward = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + location.materialId;
            } else {
                urlInward = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId;
            }
            let urlOutward: any;
            if (location.materialId && location.flag == 0) {
                urlOutward = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + location.materialId;
            } else {
                urlOutward = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId;
            }

            const inwardData = async () =>{
                try {
                    const response = await axios({
                        url: urlInward,
                        method: 'get',
                        headers: {
                            Authorization : HeaderAuthorization.Authorization,
                            PageLocation : "stockReport",
                            userId:userId,
                            token:token,
                        },
                    })
                    
                        setMaterialUnitName(response.data['data'][0]?.attributes.material_unit.data.attributes.material_unit_name);
                        const entries: MaterialState[] = [];
                        let totQuantity = 0;
                        let totInward = 0;
                        let totOutward = 0;
                        response.data['data'].forEach((list: any) => {
                            if(list?.attributes?.material_item?.data?.id){
                                if(list?.attributes?.material_item?.data?.id === location?.materialId){
                                    totInward += list.attributes.inward_quantity;
                                }
                                
                            }else{
                                totInward += list.attributes.inward_quantity;
                            }
                        })
                        try {
                            const res = await axios({
                                url: urlOutward,
                                method: 'get',
                                headers: {
                                    Authorization : HeaderAuthorization.Authorization,
                                    PageLocation : "stockReport",
                                    userId:userId,
                                    token:token,
                                },
                            })
                                if (res.data['data'].length > 0) {
                                    setOutwardEnable(true);
                                    res.data['data'].forEach((list1: any) => {
                                        if(list1?.attributes?.material_item?.data?.id){
                                            if(list1?.attributes?.material_item?.data?.id === location?.materialId){
                                                totOutward += list1.attributes.outward_quantity;
                                            }
                                            
                                        }else{
                                            totOutward += list1.attributes.outward_quantity;
                                        }
                                        
                                    })
                                }
                                totQuantity = (totInward) - (totOutward);
                                setInwardTotal(totInward);
                                setOutwardTotal(totOutward);
                                // console.log("totQuantity", totQuantity);
                                
                                setStockQuantity(totQuantity)
                                setIsloading(false)
                           
                        } catch (error: any) {
                            console.log(error); 
                            if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
                                Logout(history)
                              }           
                        }                      
                    
                } catch (error: any) {
                    console.log(error); 
                    if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
                        Logout(history)
                      }           
                } 
            }
           fetchData();
           myData();
           inwardData(); 
        }
    }, [])

    const data = { "categoryId": categoryId, "materialId": stockMaterialId, "load": load, "quantity": StockQuantity , "materialName": stockMaterial };

    const slider = useRef<any>(null);
    const [value, setValue] = useState("0");
    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        loop: false,
        pagination: {
            el: null
        },
    };
      // Function to handle segment change
    const handleSegmentChange = async (e: any) => {
        await slider.current.lockSwipes(false);
        setValue(e.detail.value);
        slider.current!.slideTo(e.detail.value);
        await slider.current.lockSwipes(true);
    };
      // Function to handle slide change
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        index = await event.target.getActiveIndex();
        setValue('' + index)
    }
    // Function to save data as CSV
    const SavedcsvData = async () => {
        if(buttonActive){
            setButtonActive(false)

        const getTime = (myvalue: any) => {
            var time = moment(myvalue).format('YYYY-MM-DD[T]HH:mm:ss[z]');
            var timestrap = new Date(time).toLocaleTimeString(undefined, { timeZone: 'Asia/Calcutta', hour12: true, hour: '2-digit', minute: 'numeric' })
            return timestrap
        }
        let xy: any;
        if (stockMaterial == undefined || stockMaterial == '') {
            xy =
                `Title:${categoryName}`
        } else {
            xy =
                `Title:${stockMaterial}`
        }
        xy = xy + "\n" + `S.no,Date,Year,Time ,User,Particular,Quantity`
        if (value == "0") {
            inwardReportList.map(item => {
                var year = new Date(item.inwardDate)
                let date = String(new Date(item.inwardDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long' })).split(" ").reverse().join(" ")
                var time = getTime(item.inwardTime)
                xy = xy + "\n" + [item.id, date, year.getFullYear(), time, item.inwardUser, item.inwardBrand, item.inwardQuantity]
                xy = xy
            })
        }
        else {
            outwardReportList.map(item => {
                var year = new Date(item.outwardDate)
                let date = String(new Date(item.outwardDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long' })).split(" ").reverse().join(" ")
                var time = getTime(item.outwardTime)
                xy = xy + "\n" + [item.id, date, year.getFullYear(), time, item.outwardUser, item.outwardBrand, item.outwardQuantity]
                xy = xy
            })
        }
        const blob = new Blob([xy], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (link.download != undefined) { // feature detection
            link.setAttribute('href', url);
            link.setAttribute('download', categoryName + '.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        try {
            await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/otps/createotp',
                data: {
                    "data": {
                        status: "csv",
                        name: categoryName,
                        email: localStorage.getItem('email'),
                        Genrate_csv: (xy),
                    }
                }, 
                headers: {
                    Authorization : HeaderAuthorization.Authorization,
                    userId:userId,
                    token:token,
                },             })
                setColordata("success")
                setMessage("Report sent to your email.Please check");
                setShowToast1(true)
           
        } catch (error: any) {
            console.log(error); 
            if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
                Logout(history)
              }           
        }

        setTimeout(() => {
            setButtonActive(true);
          }, 5000);
    }     
    }



    const [ownerStatus, setOwnerStatus] = useState([
        { id: null, attributes: { "owner": false } }
    ]);
    // Fetch owner status when the component mounts
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await checkOwnerStatus();
                if (data['data'].length != 0) {
                    setOwnerStatus(data['data']);
                }
                setIsloading(false);
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        fetchData();
    
    }, []);
    // Function to check owner status
    const checkOwnerStatus = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/user-workspaces?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[user_id][id][$eq]=' + userId + '&filters[owner][$eq]=true',
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            return response.data;
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    
    
    };

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Stock</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <div  className="stock-report-container">
                            <h5 className="ion-no-margin categoryName">{categoryName} </h5>
                            {(stockMaterial != undefined) ? (
                                <><h5 className="ion-no-margin categoryName">/</h5>
                                <h5 className="ion-no-margin stockMaterial">{stockMaterial} </h5></>) : ""}
                            </div>
                            <IonRow className="ion-align-items-center stock-report-title">
                                <IonCol size="12" sizeMd="6" sizeLg="6" class="current-stock-container">
                                <h4 className="font20">Balance:</h4>
                                    <div className="current-stock">
                                        <h3 className="font20"><b>{StockQuantity.toFixed(2)}</b></h3>
                                        <p className="font20">{materialUnitName}</p>
                                    </div>
                                </IonCol>
                                <IonCol size="12" sizeMd="6" sizeLg="6">
                                    <div className="stock-buttons buttons-wrap">
                                        {(outwardEnable == true) ? (
                                            <IonButton fill="clear" onClick={pieReport}>
                                                <IonIcon icon="/assets/images/pie-chart-icon.svg" ></IonIcon>
                                            </IonButton>) : ""}
                                        {(user_roles == "null" || user_roles == "manager" || user_roles == "site" || user_roles == "builder" || ownerStatus[0].attributes.owner == true ) ? (
                                            <IonButton fill="clear" className="export-button" onClick={SavedcsvData} disabled={!buttonActive} > 
                                                <IonIcon icon="/assets/images/export-icon.svg" ></IonIcon>
                                            </IonButton>
                                        ) : ""}
                                    </div>
                                </IonCol>
                            </IonRow>
                            <div className="stock-report-tabs">
                                <IonSegment scrollable mode="md" value={value} onIonChange={(e) => handleSegmentChange(e)} >
                                    <IonSegmentButton value="0">
                                        Inward  {inwardTotal.toFixed(2)}
                                    </IonSegmentButton>
                                    <IonSegmentButton value="1">
                                        Outward {outwardTotal.toFixed(2)}
                                    </IonSegmentButton>
                                </IonSegment>
                                <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)} ref={slider}>
                                    <IonSlide>
                                        <StockInwardReport children={(data)} flag={{flag}} />
                                    </IonSlide>                                    
                                    <IonSlide>
                                        <StockOutwardReport children={(data)} />
                                    </IonSlide>
                                </IonSlides>
                            </div>
                        </IonCol>
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1500}
                            position="middle"
                            color={colordata}  />
                    </IonRow>
                </IonGrid>
                {(isloading && <Loader />)}
            </IonContent>
        </IonPage>
    );
};

export default StockReport;
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonToast, } from "@ionic/react";
import { personCircleSharp, phonePortraitSharp, mailSharp, } from "ionicons/icons";
import Header from "../components/Header/Header";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "../assets/css/Custom.css";
import "../assets/css/Responsive.css";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";

const MyProfile: React.FC = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });
  const userId: any = localStorage.getItem('userid');
  const token: any = localStorage.getItem('token');
  //API calling
  useEffect(() => {

    const fetchData = async () => {
      try {
        let userid = localStorage.getItem("userid");
        // let token = localStorage.getItem("token");
        var url = ENV.ENDPOINT + "/users/" + userid!.toString();
        const response = await fetch(url, {
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userId,
            token: token,
          },
        });
        const responseData = await response.json();

        setOldNumber(responseData.mobileNumber)
        setData({
          firstName: responseData.firstName.toString(),
          lastName: responseData.lastName.toString(),
          mobileNumber: responseData.mobileNumber.toString(),
          email: responseData.email.toString(),
        });
        setOldMobileNumber(responseData.mobileNumber)
        return responseData
      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
    fetchData();


  }, []);
  const methods = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = methods;

  let history = useHistory();
  //back page function
  const pageBack = () => {
    // history.go(-1);
    history.push('/my-account');
  };
  const [message, setMessage] = useState('');
  const [colordata, setColordata] = useState('');
  const [showToast1, setShowToast1] = useState(false);
  const [oldMobileNumber, setOldMobileNumber] = useState("")
  const [numberChange, setNumberChange] = useState(false);
  const [oldNumber, setOldNumber] = useState("")

  //Function that handles form submit 
  const onSubmit = async (fildes: any) => {
    let userid = localStorage.getItem("userid");
    if (oldMobileNumber != fildes.telephone) {
      try {
        const res = await axios({
          method: 'POST',
          url: ENV.ENDPOINT + '/otps/createotp',
          data: {
            "data": {
              status: "verifyotp",
              Mobile_Number: '91' + getValues('telephone'),
              Genrate_OTP: getValues('otp'),
            }
          },
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userId,
            token: token,
          },
        })
        if (res.data.data.otp_status != "approved") {
          setMessage("Wrong OTP please check your OTP");
          setShowToast1(true)
        }
        else {
          try {
            const response = await axios({
              method: 'get',
              url: ENV.ENDPOINT + '/users?filters[$or][0][email][$eq]=' + fildes.email + '&filters[$or][1][mobileNumber][$eq]=' + fildes.telephone + '&filters[id][$not]=' + userid,
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userId,
                token: token,
              },
            })
            if (response.data.length == 0) {
              try {
                await axios({
                  method: 'PUT',
                  url: ENV.ENDPOINT + '/users/' + userid?.toString(),
                  data: {
                    id: userid,
                    firstName: fildes.firstName,
                    lastName: fildes.lastName,
                    mobileNumber: fildes.telephone,
                    email: fildes.email
                  },
                  headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                  },
                })
                setColordata("success")
                setMessage("Profile has been updated successfully");
                setShowToast1(true)
                history.go(0);

              } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                  Logout(history)
                }
              }

            } else {
              if (response.data[0].email == fildes.email) {
                setColordata("danger")
                setMessage("Email Id already exist");
                setShowToast1(true)
              }
              else if (response.data[0].mobileNumber == fildes.telephone) {
                setColordata("danger")
                setMessage("Mobile Number already exist");
                setShowToast1(true)
              }
              else {
                setColordata("danger")
                setMessage("Email Id or mobile number already exist");
                setShowToast1(true)
              }
            }

          } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
              Logout(history)
            }
          }

        }

      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
    else {
      try {
        const response = await axios({
          method: 'get',
          url: ENV.ENDPOINT + '/users?filters[$or][0][email][$eq]=' + fildes.email + '&filters[$or][1][mobileNumber][$eq]=' + fildes.telephone + '&filters[id][$not]=' + userid,
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userId,
            token: token,
          },
        })
        if (response.data.length == 0) {
          try {
            await axios({
              method: 'PUT',
              url: ENV.ENDPOINT + '/users/' + userid?.toString(),
              data: {
                id: userid,
                firstName: fildes.firstName,
                lastName: fildes.lastName,
                mobileNumber: fildes.telephone,
                email: fildes.email
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userId,
                token: token,
              },
            })
            setColordata("success")
            setMessage("Profile has been updated successfully");
            setShowToast1(true)
            history.go(0)

          } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
              Logout(history)
            }
          }

        } else {
          if (response.data[0].email == fildes.email) {
            setColordata("danger")
            setMessage("Email Id already exist");
            setShowToast1(true)
          }
          else if (response.data[0].mobileNumber == fildes.telephone) {
            setColordata("danger")
            setMessage("Mobile Number already exist");
            setShowToast1(true)
          }
          else {
            setColordata("danger")
            setMessage("Email Id or mobile number already exist");
            setShowToast1(true)
          }
        }

      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
  };

  //Function that handles when mobile number is changed
  const changeNumber = () => {
    setTimeout(() => {
      let currentMobileNumber = getValues('telephone')
      if (oldNumber != currentMobileNumber) {
        setNumberChange(true);
      } else {
        setNumberChange(false);
      }
    }, 1000);
  }
  //Function that handles when mobile number is changed and send OTP
  const sendotp = async () => {
    let userid = localStorage.getItem("userid");
    if (errors.telephone) return
    else {
      try {
        const response = await axios({
          method: 'get',
          url: ENV.ENDPOINT + '/users?filters[mobileNumber][$eq]=' + getValues('telephone') + '&filters[id][$not]=' + userid,
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userId,
            token: token,
          },
        })
        if (response.data.length == 0) {
          try {
            await axios({
              method: 'POST',
              url: ENV.ENDPOINT + '/otps/createotp',
              data: {
                "data": {
                  genrate_otp: "otp",
                  Mobile_Number: '91' + getValues('telephone'),
                }
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userId,
                token: token,
              },
            })
            setColordata("success")
            setMessage("OTP send successfully");
            setShowToast1(true)

          } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
              Logout(history)
            }
          }

        } else {
          setColordata("danger")
          setMessage("Mobile Number already exist");
          setShowToast1(true)
        }

      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
  }
  // Back button function
  const doNothing = () => {
    history.goBack();
  }

  // Render JSX component
  return (
    <IonPage>
      <Header class="with-back-arrow" onBack={doNothing} />
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="login-form-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="back-arrow">
                <IonButton
                  className="ion-text-right"
                  fill="clear"
                  onClick={pageBack} >
                  <IonIcon icon="/assets/images/arrow-left-icon.svg"></IonIcon>
                </IonButton>
                <h3>My Profile</h3>
              </div>
              <FormProvider {...methods}>
                <form
                  className="login-form"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off" >
                  <IonRow>
                    <IonCol size="12" className="email-field">
                      <IonLabel className="form-lable">First Name*</IonLabel>
                      <div className="input-with-icon">
                        <IonIcon icon={personCircleSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              type="text"
                              onBlur={onBlur}
                              value={value == null ? data.firstName : value}
                              onIonChange={onChange}
                              className={`form-control ${errors.firstName ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md" />
                          )}
                          control={control}
                          name="firstName"
                          rules={{
                            required: "First Name is required",
                            minLength: {
                              value: 3,
                              message: "First Name should have minimum 3 character."
                            },
                            maxLength: {
                              value: 25,
                              message: "First Name should not exceed 25 character."
                            },
                            pattern: {
                              value: /^[a-zA-Z ]*$/i,
                              message: "Enter a valid name",

                            }
                          }} />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        as={
                          <div
                            className="error-message"
                            style={{ color: "red" }} />
                        } />
                    </IonCol>
                    <IonCol size="12" className="password-field">
                      <IonLabel className="form-lable">Last Name*</IonLabel>
                      <div className="input-with-icon">
                        <IonIcon icon={personCircleSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              type="text"
                              onIonChange={onChange}
                              onBlur={onBlur}
                              value={value == null ? data.lastName : value}
                              className={`form-control ${errors.lastName ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md" />
                          )}
                          control={control}
                          name="lastName"
                          rules={{
                            required: "Last Name is required",
                            minLength: {
                              value: 3,
                              message: "Last Name should have minimum 3 character."
                            },
                            maxLength: {
                              value: 25,
                              message: "Last Name should not exceed 25 character."
                            },
                            pattern: {
                              value: /^[a-zA-Z ]*$/i,
                              message: "Enter a valid name",
                            }
                          }} />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        as={
                          <div
                            className="error-message"
                            style={{ color: "red" }} />
                        } />
                    </IonCol>
                    <IonCol size="12" className="forget-password">
                      <IonLabel className="form-lable">Mobile No.*</IonLabel>
                      <div className="input-with-icon">
                        <IonIcon icon={phonePortraitSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              type="tel"
                              onIonChange={onChange}
                              onBlur={onBlur}
                              value={value == null ? data.mobileNumber : value}
                              onKeyUp={(e) => { changeNumber() }}
                              className={`form-control ${errors.telephone ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md" />
                          )}
                          control={control}
                          name="telephone"
                          rules={{
                            required: "Telephone is required",
                            minLength: {
                              value: 10,
                              message: "Please enter a valid phone number",
                            },
                            maxLength: {
                              value: 10,
                              message: "Please enter a valid phone number",
                            },
                          }} />
                      </div>
                      <ErrorMessage errors={errors} name="telephone"
                        as={<div className="error-message" style={{ color: "red" }} />} />
                    </IonCol>
                    {(numberChange) ? (
                      <IonRow>
                        <IonCol size="6">
                          <IonButton onClick={sendotp} fill="solid" className="button-small small-button send-otp-btn" >Send OTP</IonButton>
                        </IonCol>
                        <IonCol size="12" className="">
                          <IonLabel className="form-lable"></IonLabel>
                          <div className="input-with-icon">
                            <IonIcon icon={phonePortraitSharp} />
                            <Controller
                              render={({ field: { onChange, onBlur, value } }) => (
                                <IonInput
                                  onIonChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  className={`form-control ${errors.mobileNumber ? "is-invalid" : ""
                                    }`}
                                  placeholder="verify otp"
                                  mode="md" />
                              )}
                              control={control}
                              name="otp"
                              rules={{
                                required: "Verify otp filed is required ",
                              }} />
                          </div>
                          {errors.otp && errors.otp.type == "validate" && (
                            <div className="error-message">Invalid OTP</div>
                          )
                          }
                          <ErrorMessage errors={errors} name="otp"
                            as={<div className="error-message" style={{ color: "red" }} />} />
                        </IonCol></IonRow>) : ""}
                    <IonCol size="12" className="email-field">
                      <IonLabel className="form-lable">Email*</IonLabel>
                      <div className="input-with-icon">
                        <IonIcon icon={mailSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              type="email"
                              onIonChange={onChange}
                              onBlur={onBlur}
                              value={value == null ? data.email : value}
                              className={`form-control ${errors.email ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md" />
                          )}
                          control={control}
                          name="email"
                          rules={{
                            required: "Email required field",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          }} />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        as={
                          <div
                            className="error-message"
                            style={{ color: "red" }} />
                        } />
                    </IonCol>
                    <IonCol size="6" className="forget-password">
                      <IonButton
                        routerLink='/change-password'
                        fill="clear">
                        Change Password
                      </IonButton>
                    </IonCol>
                    <IonCol
                      size="8"
                      className="ion-align-self-center ion-margin-auto ion-text-center ion-margin-top"  >
                      <IonButton type="submit" fill="solid" expand="block">
                        Update
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </FormProvider>
            </IonCol>
          </IonRow>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message={message}
            duration={2000}
            position="middle"
            color={colordata} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyProfile;

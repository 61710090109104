// Importing createSlice from the Redux Toolkit library
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the reducer
const initialState = {
  projectName: '',   // String to hold the project name
  projectId: '',     // String to hold the project ID
  projectDate: '',   // String to hold the project date
}

// Creating a slice of the Redux store using createSlice
export const projectSlice = createSlice({
  name: "project",    // Name of the slice, which can be used to access the state in the Redux store
  initialState,       // Initial state of the slice
  reducers: {
    // Reducer function to set the projectName state with the provided payload
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    // Reducer function to set the projectId state with the provided payload
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    // Reducer function to set the projectDate state with the provided payload
    setProjectDate: (state, action) => {
      state.projectDate = action.payload;
    },
  },
});

// Exporting the action creators generated by createSlice
export const { setProjectName, setProjectId, setProjectDate } = projectSlice.actions;

// Exporting the reducer generated by createSlice
export default projectSlice.reducer;

// Importing necessary dependencies and styles
import {IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow,IonCol,IonToast} from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { useState } from "react";
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { useHistory } from "react-router-dom";
import IonImgButton from '../components/Button/IonImgButton';
import Logout from '../components/Logout';

// Defining the ChangePassword functional component
const ChangePassword: React.FC = () => {
      // Using the useHistory hook from react-router-dom to manage navigation

   let history = useHistory();
      // Using react-hook-form useForm to manage form state and validation

   const methods = useForm();
   const {  handleSubmit, getValues, setValue, control, formState: { errors } } = methods;
      // State variables for managing toast messages

   const [showToast1, setShowToast1] = useState(false);
   const [message, setMessage] = useState("");
   const [colordata, setColordata] = useState('');
      // Retrieving user information from local storage

   let userid:any = localStorage.getItem("userid");
   let token:any = localStorage.getItem("token");
   let username = localStorage.getItem('email');
   // Function to log out the user and clear local storage
   const logOut = async () => {
      let sessionId:any = localStorage.getItem("token")
        let userid:any = localStorage.getItem("userid");


        try {
         const sessionExists= await axios({
            method: 'get',
            url: ENV.ENDPOINT + `/sessions/${sessionId}`,
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userid,
          },            })
          if(sessionExists){
             await axios({
              method: 'delete',
              url: ENV.ENDPOINT + `/sessions/${sessionId}`,
              headers: {
                Authorization : HeaderAuthorization.Authorization,
                userId:userid,
            },            })
  
          }
         localStorage.clear();
         history.push('/index')
         window.location.reload();
        } catch (error) {
         console.log(error);
         localStorage.clear();
         history.push('/index')
         window.location.reload();
        }

        
   }
      // Function to handle form submission

      const onSubmit = async (data: any) => {
         try {
            const response = await axios({
               method: 'post',
               url: ENV.ENDPOINT + '/auth/local',
               data: {
                  identifier: username,
                  password: data.oldPassword
               },
               headers: {
                  Authorization: HeaderAuthorization.Authorization,
                  userId: userid,
                  token: token,
               },
            });
            if (response.data) {
               try {
                  // Check if old password and new password are different
                  if (data.oldPassword === data.password) {
                     setColordata("danger");
                     setMessage("New password cannot be the same as the old password");
                     setShowToast1(true);
                  } else {
                     const userResponse = await axios({
                        method: "PUT",
                        url: ENV.ENDPOINT + '/users/' + userid,
                        data: {
                           password: data.password,
                        },
                        headers: {
                           Authorization: HeaderAuthorization.Authorization,
                           userId: userid,
                           token: token,
                        },
                     });
                     if (userResponse) {
                        try {
                           const optResponse = await axios({
                              method: "post",
                              url: ENV.ENDPOINT + '/otps/createotp',
                              data: {
                                 "data": {
                                    email: username,
                                    status: "changepwd"
                                 }
                              },
                              headers: {
                                 Authorization: HeaderAuthorization.Authorization,
                                 userId: userid,
                                 token: token,
                              },
                           });
                           if (optResponse) {
                              setColordata("success");
                              setValue('oldPassword', '');
                              setValue('password', '');
                              setValue('password2', '');
                              setMessage("Password Change Successfully");
                              setShowToast1(true);
                              // Redirect to /index after successful password change
                              history.push('/index');
                           }
                        } catch (error: any) {
                           console.log(error);
                           if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                              Logout(history);
                           }
                        }
                     }
                  }
               } catch (error: any) {
                  console.log(error);
                  if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                     Logout(history);
                  }
               }
            } else {
               setColordata("danger");
               setMessage("Enter the old password correctly");
               setShowToast1(true);
            }
         } catch (error: any) {
            console.log(error);
            setColordata("danger");
            setMessage("Enter the old password correctly");
            setShowToast1(true);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
               Logout(history);
            }
         }
      };
      

      // JSX content of the ChangePassword component

   return (
      <IonPage>
         <IonContent fullscreen>
            <IonGrid className="full-height-div">
               <IonRow className="login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="main-logo">
                           <IonImgButton  routerLink="/" className="logo" src="assets/images/csm-logo.png" />
                           </div>
                           <h2 className='ion-text-center'>Change Password</h2>
                           <IonLabel className="form-lable ion-margin-top" >Old Password:*</IonLabel>
                           <Controller
                              render={({ field: { onBlur, value } }) => (
                                 <IonInput
                                    type="password"
                                    onIonChange={(e: any) => {
                                       setValue('oldPassword', e.detail.value)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                                    placeholder=""
                                    mode="md" />
                              )}
                              control={control}
                              name="oldPassword"
                              rules={{
                                 required: "Password is required",
                                 validate: {
                                    noMatch: (value: string) => {
                                       return value != getValues("oldPassword")
                                          ? "Passwords do not match"
                                          : undefined;
                                    },
                                 },                                 
                              }} />
                           <ErrorMessage
                              errors={errors}
                              name="oldPassword"
                              as={<div className="error-message" style={{ color: 'red' }} />} />
                           {
                              errors.oldPassword && errors.oldPassword.type == "validate" &&
                              (
                                 <div className="error-message">Invalid Old password</div>
                              )
                           }
                           <IonCol size="12" className="ion-margin-top">
                              <IonLabel className="form-lable">Enter new password:*</IonLabel>
                              <Controller
                                 render={({ field: { onBlur, value } }) => (
                                    <IonInput
                                       type="password"
                                       onIonChange={(e: any) => {
                                          setValue('password', e.detail.value)
                                       }}
                                       onBlur={onBlur}
                                       value={value}
                                       className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                       placeholder=""
                                       mode="md" />
                                 )}
                                 control={control}
                                 name="password"
                                 rules={{
                                    required:'Password must contain at least 1 number and 1 uppercase and lowercase letter, and at 8 to 12 characters long',
                                    pattern: {
                                       value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/i,
                                       message: 'Password must contain at least 1 number and 1 uppercase and lowercase letter, and at 8 to 12 characters long'
                                    }
                                 }}  />
                              <ErrorMessage
                                 errors={errors}
                                 name="password"
                                 as={<div className="error-message" style={{ color: 'red' }} />} />
                           </IonCol>
                           <IonCol size="12" className="ion-margin-top">
                              <IonLabel className="form-lable">Confirm new password:*</IonLabel>
                              <Controller
                                 render={({ field: { onBlur, value } }) => (
                                    <IonInput
                                       type="password"
                                       onIonChange={(e: any) => {
                                          setValue('password2', e.detail.value)
                                       }}
                                       onBlur={onBlur}
                                       value={value}
                                       className={`form-control ${errors.password2 ? 'is-invalid' : ''}`}
                                       placeholder=""
                                       mode="md" />
                                 )}
                                 control={control}
                                 name="password2"
                                 rules={{
                                    validate: {
                                       noMatch: (value: string) => {
                                          return value != getValues("password")
                                             ? "Passwords do not match"
                                             : undefined;
                                       },
                                    },
                                 }}  />
                              <ErrorMessage
                                 errors={errors}
                                 name="password2"
                                 as={<div className="error-message" style={{ color: 'red' }} />}  />
                           </IonCol>
                           <IonButton type="submit" className="secondary-button ion-margin-top" expand="block" shape="round" fill="solid" >
                              Submit
                           </IonButton>
                           <IonToast
                              isOpen={showToast1}
                              onDidDismiss={() => setShowToast1(false)}
                              message={message}
                              duration={2000}
                              position="middle"
                              color={colordata} />
                           <div className="back-to-login ion-text-right">                             
                              <IonButton onClick={logOut} fill="clear">
                                 Back to Login page
                              </IonButton>
                           </div>
                        </form>
                     </FormProvider>
                  </IonCol>
               </IonRow>
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};

// Exporting the ChangePassword component as the default export
export default ChangePassword;
// Importing createSlice from the Redux Toolkit library
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the reducer
const initialState = {
  outwardData: [],           // Array to hold outward data
  outwardHasMoreData: true,  // Flag to indicate if there is more outward data available
}

// Creating a slice of the Redux store using createSlice
export const outwardSlice = createSlice({
  name: "stock",    // Name of the slice, which can be used to access the state in Redux store
  initialState,     // Initial state of the slice
  reducers: {
    // Reducer function to set the outwardData state with the provided payload
    setOutwardData: (state, action) => {
      state.outwardData = action.payload;
    },
    // Reducer function to set the outwardHasMoreData state with the provided payload
    setOutwardHasMoreData: (state, action) => {
      state.outwardHasMoreData = action.payload;
    },
  },
});

// Exporting the action creators generated by createSlice
export const { setOutwardData, setOutwardHasMoreData } = outwardSlice.actions;

// Exporting the reducer generated by createSlice
export default outwardSlice.reducer;

// Import the React library from "react"
import React from "react";
// Import specific components (IonItem, IonLabel, IonRadio) from the '@ionic/react' package
import { IonItem, IonLabel, IonRadio } from '@ionic/react';

// Create a functional component called CustomItem using React.FC<any>
// The component takes 'props' as a parameter, which can be of any type (denoted by 'any')
const CustomItem: React.FC<any> = (props) => {
    return (
        // Return an IonItem component with a custom CSS class 'user-select-item'
        <IonItem className="user-select-item">
            {/* Display the label passed as a prop */}

            <IonLabel>{props.Label}</IonLabel>
            {/* Display an IonRadio component with the given 'props.value' */}
            {/* 'slot="start"' specifies the position of the radio button relative to the label */}
            {/* 'mode="md"' sets the mode (appearance) of the radio button to "md" (material design) */}
            {/* 'value={props.value}' sets the value of the radio button based on the prop passed */}
            <IonRadio slot="start" mode="md" value={props.value} />
        </IonItem>
    );
};
// Export the CustomItem component to be used in other parts of the application

export default CustomItem;
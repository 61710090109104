// Import required modules from external libraries and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonButton, IonGrid, IonRow, IonCol, IonModal, IonIcon, IonImg, useIonAlert, IonToast } from '@ionic/react';
import { close } from "ionicons/icons";
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import Logout from "./Logout";

// Define the StockInwardReport functional component
const StockInwardReport: React.FC<any> = ({ children: id, flag }) => {
    const jsonObj = (JSON.parse(JSON.stringify(id)))
    let history = useHistory();
    // Extract user roles, workspaceId, projectId, userId, and token from localStorage

    const user_roles = localStorage.getItem('work_roles');
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const userId: any = localStorage.getItem('userid')
    const token: any = localStorage.getItem('token');

    // State variables to manage the component's data and display

    const [loaded, setLoaded] = useState(false);
    const [present] = useIonAlert();
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [imageurl1, setImageurl1] = useState('');
    const [imageurl2, setImageurl2] = useState('');
    const defaultList = [
        {
            id: "",
            inwardDate: "",
            inwardTime: "",
            inwardUser: "",
            inwardBrand: "",
            inwardPhoto1: "",
            inwardPhoto2: "",
            inwardPhoto1Name: "",
            inwardPhoto2Name: "",
            inwardQuantity: 0,
            inwardYear: "",
            inwardMaterialId: "",
        },
    ];
    const [inwardReportList, setInwardReportList] = useState(defaultList);
    // get and check edit the inward permission or not 

    const edit_permisionRaw = localStorage.getItem("edit_permision");
    if (edit_permisionRaw != null) {
        var edit_permision = JSON.parse(edit_permisionRaw);
    } else {
        edit_permision = false
    }

    // Function to fetch inward entries list
    const getInwardList = async () => {
        let urlLink;
        if (flag.flag == 1) {
            urlLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + jsonObj.categoryId + '&filters[project][id][$eq]=' + projectId
        }
        else {


            if (jsonObj.materialId && jsonObj.materialId != undefined) {
                urlLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + jsonObj.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + jsonObj.materialId
            }
            else {
                urlLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + jsonObj.categoryId + '&filters[project][id][$eq]=' + projectId
            }
        }
        try {
            const response = await axios({
                url: urlLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    PageLocation: "stockReport",
                    userId: userId,
                    token: token,
                },
            })

            const entriesArr = [];
            for (const [index, value] of response.data['data'].entries()) {
                let brandData = '';
                if (value.attributes.material_brand.data != null) {
                    brandData = value.attributes.material_brand.data.attributes.material_brand_name;
                }
                let inwardDate = new Date(value.attributes.createdAt).toISOString();
                inwardDate = format(parseISO(inwardDate), 'dd-MM-yyyy')
                let userdata = '';
                if (value.attributes.inward_createdby.data != null) {
                    userdata = value.attributes.inward_createdby.data.attributes.firstName;
                }
                var photo1
                if (value.attributes.inward_capture1 != undefined || value.attributes.inward_capture1 != null) {
                    photo1 = value.attributes.inward_capture1.toString().split('( ')[1]


                    if (photo1 == undefined) {
                        photo1 = "Photo 1"
                    }
                    else {
                        photo1 = photo1.toString().split(' )')[0]
                        photo1 = moment(photo1).format('DD/MM/YY-HH:mm')
                    }
                }
                else {
                    photo1 = ""
                }
                var photo2
                if (value.attributes.inward_capture2 != undefined || value.attributes.inward_capture2 != null) {
                    photo2 = value.attributes.inward_capture2.toString().split('( ')[1]

                    if (photo2 == undefined) {
                        photo2 = "Photo 2"
                    }
                    else {
                        photo2 = photo2.toString().split(' )')[0]
                        photo2 = moment(photo2).format('DD/MM/YY-HH:mm')
                    }
                }
                else {
                    photo2 = ""
                }
                let materialItem1 = '';
                if (value.attributes.material_item.data != undefined || value.attributes.material_item.data != null) {
                    materialItem1 = value.attributes.material_item.data.id
                }
                entriesArr.push({
                    id: value.id,
                    inwardDate: value.attributes.inward_date,
                    inwardYear: value.attributes.inward_date,
                    inwardBrand: brandData,
                    inwardTime: value.attributes.createdAt,
                    inwardUser: userdata,
                    inwardPhoto1: value.attributes.inward_capture1,
                    inwardPhoto2: value.attributes.inward_capture2,
                    inwardPhoto1Name: photo1,
                    inwardPhoto2Name: photo2,
                    inwardQuantity: value.attributes.inward_quantity,
                    inwardMaterialId: materialItem1,
                })
            }
            const newentries = entriesArr.sort((a: any, b: any) => a.inwardDate < b.inwardDate ? 1 : -1);
            setInwardReportList(newentries)
            sessionStorage.removeItem("reloading");
            setLoaded(true);

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    }
    let reloading = sessionStorage.getItem("reloading");
    if (jsonObj.categoryId && reloading == "false") {
        getInwardList();
    }

    // Function to edit inward entries list
    const editInwardEntry = async (data: any) => {
        let categoryURL = '';
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?filters[id][$eq]=' + jsonObj.categoryId,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    PageLocation: "stockReport",
                    userId: userId,
                    token: token,
                },
            })


            if (response.data['data'][0].attributes.inward_screen_type == 'cement') {
                categoryURL = '/edit-inward-entry'
            } else if (response.data['data'][0].attributes.inward_screen_type == 'sand') {
                categoryURL = "/edit-inward-entry-sand"
            } else if (response.data['data'][0].attributes.inward_screen_type == 'brick') {
                categoryURL = "/edit-inward-entry-brick"
            } else if (response.data['data'][0].attributes.inward_screen_type == 'accblock') {
                categoryURL = "/edit-inward-entry-rcc"
            } else {
                categoryURL = '/edit-inward-entry'
            }
            if (jsonObj.materialName && jsonObj.quantity) {
                history.push({
                    pathname: categoryURL,
                    state: { "backpath": "stockreport", "categoryName": response.data['data'][0].attributes.material_category_name, "categoryId": response.data['data'][0].id, "status": "category", "categoryUrl": categoryURL, "editStatus": true, "inwardId": data.id, "materialId": data.inwardMaterialId, "materialName": jsonObj.materialName, "quantity": jsonObj.quantity }
                }
                );
            } else {
                history.push({
                    pathname: categoryURL,
                    state: { "backpath": "stockreport", "categoryName": response.data['data'][0].attributes.material_category_name, "categoryId": response.data['data'][0].id, "status": "category", "categoryUrl": categoryURL, "editStatus": true, "inwardId": data.id, "materialId": data.inwardMaterialId }
                }
                );
            }
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    }
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    // handle click event of the Remove item
    const handleRemoveClick = async (index: any, data: any) => {
        const list = [...inwardReportList];
        let quantityInwardValue = 0;
        let quantityOutwardValue = 0;
        let totInwardValue = 0;
        let urlInwardLink: any;
        let urlOutwardLink: any;
        const response = await axios({
            url: ENV.ENDPOINT + '/inward-entries?populate=*&filters[id][$eq]=' + data,
            method: 'get',
            headers: {
                Authorization: HeaderAuthorization.Authorization,
                PageLocation: "stockReport",
                userId: userId,
                token: token,
            },
        })

        urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity,inward_capture1,inward_capture2,createdAt,inward_date&pagination[limit]=-1&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + jsonObj.categoryId + '&filters[project][id][$eq]=' + projectId;
        urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&pagination[limit]=-1&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + jsonObj.categoryId + '&filters[project][id][$eq]=' + projectId;
        if (response.data['data'][0].attributes.material_item.data != null) {
            urlInwardLink = urlInwardLink + '&filters[material_item][id][$eq]=' + response.data['data'][0].attributes.material_item.data.id
            urlOutwardLink = urlOutwardLink + '&filters[material_item][id][$eq]=' + response.data['data'][0].attributes.material_item.data.id
        }
        if (response.data['data'][0].attributes.material_brand.data != null) {
            urlInwardLink = urlInwardLink + '&filters[material_brand][id][$eq]=' + response.data['data'][0].attributes.material_brand.data.id
            urlOutwardLink = urlOutwardLink + '&filters[material_brand][id][$eq]=' + response.data['data'][0].attributes.material_brand.data.id
        }
        const res = await axios({
            url: urlInwardLink,
            method: 'get',
            headers: {
                Authorization: HeaderAuthorization.Authorization,
                PageLocation: "stockReport",
                userId: userId,
                token: token,
            },
        })
        if (res.data['data'].length != 0) {
            res.data['data'].forEach((list: any) => {
                quantityInwardValue = quantityInwardValue + list.attributes.inward_quantity;
            })
            totInwardValue = quantityInwardValue - parseInt(response.data['data'][0].attributes.inward_quantity);
            const outwardResponse = await axios({
                url: urlOutwardLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            if (outwardResponse.data['data'].length != 0) {
                outwardResponse.data['data'].forEach((list: any) => {
                    quantityOutwardValue = quantityOutwardValue + list.attributes.outward_quantity;
                })
            }
            if ((totInwardValue - quantityOutwardValue >= 0)) {
                try {
                    await axios({
                        method: "DELETE",
                        url: ENV.ENDPOINT + "/inward-entries/" + data,
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },

                    })
                    list.splice(index, 1);
                    setInwardReportList(list);
                    setColordata("success")
                    setMessage("Inward Entry Deleted Successfully");
                    setShowToast1(true)
                    history.go(0)
                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }
            else {
                setColordata("danger")
                setMessage("Inward Entry Delete will affect outward Entry : Deletion unsuccessful");
                setShowToast1(true)
            }

        }


    };


    const [ownerStatus, setOwnerStatus] = useState([
        { id: null, attributes: { "owner": false } }
    ]);
    // Fetch owner status when the component mounts
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await checkOwnerStatus();
                if (data['data'].length != 0) {
                    setOwnerStatus(data['data']);
                }
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        fetchData();
    
    }, []);
    // Function to check owner status
    const checkOwnerStatus = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/user-workspaces?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[user_id][id][$eq]=' + userId + '&filters[owner][$eq]=true',
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            return response.data;
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    
    
    };

    //render and display particular inward entry for stock
    const renderCategoryList = () => {

        return inwardReportList.map((x, i) => {
            return (
                <IonRow key={i} className="table-row table-header">
                    <IonCol size="3" sizeMd="3" sizeLg="3" className="">
                        {(x.inwardDate) ? (
                            <p className=""><b>{String(new Date(x.inwardDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long' })).split(" ").reverse().join(" ")}</b></p>) : ""}
                        {(x.inwardYear) ? (<p className="">{x.inwardYear.split('-')[0]}</p>) : ''}
                        {(x.inwardTime) ? (<p className="">{new Date(x.inwardTime).toLocaleTimeString('en-IN', { timeZone: 'IST', hour12: true, hour: '2-digit', minute: 'numeric' })}</p>) : ""}
                        <p className="person-name">{x.inwardUser}</p>
                    </IonCol>
                    <IonCol size="5" sizeMd="5" sizeLg="5" className="">
                        <p className=""></p>
                        <p className="">{x.inwardBrand}</p>
                        <div className="buttons-wrap photo-buttons">
                            <IonButton fill="clear" className={`clear-button-with-border ${(x.inwardPhoto1 == "") || (x.inwardPhoto1 == null) ? 'ion-hide' : ''}`} onClick={() => { setShowModal1(true); setImageurl1(x.inwardPhoto1) }}>
                                {x.inwardPhoto1Name}
                            </IonButton>
                            <IonButton fill="clear" className={`clear-button-with-border ${(x.inwardPhoto2 == "") || (x.inwardPhoto2 == null) ? 'ion-hide' : ''}`} onClick={() => { setShowModal2(true); setImageurl2(x.inwardPhoto2) }}>
                                {x.inwardPhoto2Name}
                            </IonButton>
                        </div>
                    </IonCol>
                    <IonCol size="4" sizeMd="4" sizeLg="4" className="ion-text-end quantity-col">
                        <p><b>{x.inwardQuantity}</b></p>
                        <div className="buttons-wrap">
                            {(user_roles == "null" || user_roles == "manager" || user_roles == "builder" || edit_permision || ownerStatus[0].attributes.owner == true) ? (
                                <IonButton fill="clear" className="secondary-button clear-button-with-border" onClick={() => editInwardEntry(x)}>
                                    Edit
                                </IonButton>) : ""}
                            {(user_roles == "null" || user_roles == "manager" || user_roles == "builder" || ownerStatus[0].attributes.owner == true) ? (
                                <IonButton id={x.id} fill="clear" className="secondary-button clear-button-with-border" onClick={(x) => {
                                    present({
                                        cssClass: 'red-alert',
                                        header: 'Remove Inward',
                                        message: '<p>Are you sure you want to remove this Item?</p>',
                                        buttons: [
                                            'Cancel',
                                            {
                                                text: 'Ok',
                                                handler: () => {
                                                    handleRemoveClick(i, (x.target as HTMLElement).id);

                                                }
                                            },
                                        ],
                                        onDidDismiss: (e) => { },
                                    })
                                }
                                }>
                                    Delete
                                </IonButton>) : ""}
                        </div>
                    </IonCol>
                    <IonModal isOpen={showModal1} className="photo-popup ">
                        <IonButton className="close-popup-button " fill="clear" onClick={() => setShowModal1(false)}>
                            <IonIcon icon={close} />
                        </IonButton>
                        <IonImg src={imageurl1} className="photo-modal" />
                    </IonModal>
                    <IonModal isOpen={showModal2} className="photo-popup ">
                        <IonButton className="close-popup-button" fill="clear" onClick={() => setShowModal2(false)}>
                            <IonIcon icon={close} />
                        </IonButton>
                        <IonImg src={imageurl2} className="photo-modal" />
                    </IonModal>
                </IonRow>
            );
        })
    }
    // The main JSX content of the component

    return (
        <IonGrid className="table report-table">
            <IonRow className="table-row table-header">
                <IonCol size="3" sizeMd="3" sizeLg="3" className="">
                    <h6 className="ion-no-margin">DATE</h6>
                </IonCol>
                <IonCol size="5" sizeMd="5" sizeLg="5" className="stock-inward-particular">
                    <h6 className="ion-no-margin">PARTICULAR</h6>
                </IonCol>
                <IonCol size="4" sizeMd="4" sizeLg="4" className="ion-text-end">
                    <h6 className="ion-no-margin">QUANTITY</h6>
                </IonCol>
            </IonRow>
            {renderCategoryList()}
            {/* Toast for showing error messages */}

            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message={message}
                duration={3000}
                position="middle"
                color={colordata} />
        </IonGrid>
    );
};

export default StockInwardReport;
import React, { useEffect, useRef, useState } from 'react';
import {IonContent, IonPage,IonGrid,IonRow,IonCol,IonSlides,IonSlide, IonToast} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Header from '../components/Header/Header';
import RegistrationStep1 from '../components/RegistrationStep1';
import RegistrationStep2 from '../components/RegistrationStep2';
import RegistrationStep3 from '../components/RegistrationStep3';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/ionic-swiper.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import Loader from "../components/Loader/Loader";
import axios from 'axios';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
const slideOpts = {
   initialSlide: 0,
   speed: 400
};

const Registration: React.FC = () => {
   let history = useHistory();
   const [flag, setFlag] = useState(false)
   const [showToast1, setShowToast1] = useState(false);
   const [message, setMessage] = useState('');
   const [colordata, setColordata] = useState('');
   const [mobileNumber , setMobileNumber] = useState('')
   const [verifyotp , setVerifyOTP] = useState(false)
   // const [resend , setResend] = useState(true)
   const [time, setTime] = useState([0, 10, 0]);
   const [count, setCount] = useState(0);
   const [isloading, setIsloading] = useState(false);
   const [fired, setFired] = useState(false);
   const pageBack = () => {
      history.goBack();
   };
   const mySlides = useRef<any>(null);
   const contentRef = useRef<HTMLIonContentElement | null>(null);
   const methods = useForm();
   const { getValues,trigger, handleSubmit,  formState: { errors } } = methods;
   
   useEffect(() => {
      mySlides.current.lockSwipes(true);
      setMobileNumber(getValues('telephone'))
   });

   useEffect(() => {
      setVerifyOTP(false);
   } , [mobileNumber])


   const fetchData = async () => {
      try {
          await axios({
              method: 'post',
              url: ENV.ENDPOINT + '/otps/createotp',
              data: {
                  "data": {
                      Mobile_Number: '91' + getValues('telephone'),
                      genrate_otp: "otp",
                  }
              },
              headers: {
                  Authorization: HeaderAuthorization.Authorization,
                  PageLocation: "registration",
              },
          })
      } catch (error) {
          console.log(error);

      }
  }

   const next = async (fields: any) => {

      const result = await trigger(fields);
      if (!result) return false;
      await mySlides.current.lockSwipes(false);
      await mySlides.current.slideNext();
      
      if(getValues('telephone') === mobileNumber && verifyotp){
      await mySlides.current.slideNext();
      }
      else if(getValues('telephone') !== mobileNumber && verifyotp){
         setVerifyOTP(false)
      }
      if(mobileNumber != undefined){
      if(getValues('telephone') != mobileNumber && getValues('telephone') != undefined){
         setFired(true)
         setCount(0)// Reset the count
         setTime([0, 10, 0])// Reset the timer to 10 minutes
         fetchData()
         }
      }
      await mySlides.current.lockSwipes(true);
      contentRef.current && contentRef.current.scrollToTop(500);
   };


   const prev = async () => {
      await mySlides.current.lockSwipes(false);
      await mySlides.current.slidePrev(); // Go to Step 2
      await mySlides.current.slidePrev(); 
      await mySlides.current.lockSwipes(true);
      
      contentRef.current && contentRef.current.scrollToTop(500);
   };   
   const onSubmit = async (data: any) => {
      setIsloading(true);
         try {
         const response = await axios({
            method: 'POST',
            url: ENV.ENDPOINT + '/auth/local/register',
            data: {
               username: data.firstName + data.lastName,
               firstName: data.firstName,
               lastName: data.lastName,
               mobileNumber: data.telephone,
               email: data.email,
               password: data.password,
               user_roles: data.radioGrp,
               verifyText: data.verifyText
            },
            headers: {
               Authorization : HeaderAuthorization.Authorization,
               PageLocation : "registration",
           },
            
         })
            // localStorage.setItem('isLoggedIn', "true")
            // localStorage.setItem('userid', response.data.user.id);
            setColordata("success")
            setMessage("Registration successfully");
            setShowToast1(true)       
            setIsloading(false);
            // localStorage.setItem('user_roles', response.data.user.user_roles);
            setTimeout(() => {
               if (response.data.user.user_roles === "builder" || response.data.user.user_roles === "manager" || response.data.user.user_roles === "site") {
                  history.push('/index');
               } else {
                  history.push('/index');
               }
            }, 2000); 
      
      } catch (error) {
         console.log(error);
         setIsloading(false);         
      }
     
   };
   return (
      <IonPage>
         <Header class="with-back-arrow with-step-arrow" onBack={() => pageBack()} />
         <IonContent ref={contentRef} scrollEvents={true} fullscreen>
         {isloading && <Loader />}
            <IonGrid>
               <IonRow className="signup-form login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <IonSlides pager={true} options={slideOpts} ref={mySlides}>
                              <IonSlide>
                                 <RegistrationStep1 next={next} flag={setFlag}  />
                              </IonSlide>
                              <IonSlide>
                                 <RegistrationStep2 next={next} prev={prev} flag={flag} verify={verifyotp} verifyotp={setVerifyOTP} mobileNumber={getValues('telephone')} time={time} Count={count} fired={fired} setFired={setFired} />
                              </IonSlide>
                              <IonSlide>
                                 <RegistrationStep3 prev={prev} />
                              </IonSlide>
                           </IonSlides>
                        </form>
                     </FormProvider>
                  </IonCol>
                  <IonToast
                     isOpen={showToast1}
                     onDidDismiss={() => setShowToast1(false)}
                     message={message}
                     duration={1500}
                     position="middle"
                     color={colordata}  />
               </IonRow>
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};
export default Registration;
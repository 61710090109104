// Importing createSlice from the Redux Toolkit library
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the reducer
const initialState = {
    inwardData: [],           // Array to hold inward data
    inwardHasMoreData: true,  // Flag to indicate if there is more inward data available
}

// Creating a slice of the Redux store using createSlice
export const inwardSlice = createSlice({
    name: "stock",    // Name of the slice
    initialState,     // Initial state of the slice
    reducers: {
        // Reducer function to set the inwardData state with the provided payload
        setInwardData: (state, action) => {
            state.inwardData = action.payload;
        },
        // Reducer function to set the inwardHasMoreData state with the provided payload
        setInwardHasMoreData: (state, action) => {
            state.inwardHasMoreData = action.payload;
        },
    },
});

// Exporting the action creators generated by createSlice
export const { setInwardData, setInwardHasMoreData } = inwardSlice.actions;

// Exporting the reducer generated by createSlice
export default inwardSlice.reducer;

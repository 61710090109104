import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonSelect, IonSelectOption, IonItem, IonCheckbox, IonToast, } from "@ionic/react";
import { phonePortraitSharp, mailSharp, informationCircle, } from "ionicons/icons";
import Header from "../components/Header/Header";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { Tooltip } from 'antd';
import { ErrorMessage } from "@hookform/error-message";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "../assets/css/Custom.css";
import "../assets/css/Responsive.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";

// Define interfaces for type checking
interface LocationState {
  id: number;
  invitedBy: boolean;
  loggedStatus: boolean;

}
const EditUser: React.FC = () => {
  const [userWorkId, setUserWorkId] = useState(0);
  const [isChecked, setIsChecked] = useState(false)
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    role: "",
    owner: "",
    username: "",
  });
  const locations = useLocation();
  const ownerId = localStorage.getItem("ownerId")
  const ownerStatus = localStorage.getItem("ownerStatus")
  const [editRoles, setEditRole] = useState("")
  const [userStatus, setUserStatus] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [message, setMessage] = useState('');
  const [colordata, setColordata] = useState('');
  const [inputDisable, setInputDisable] = useState(true);
  const [editUserId, seEditUserId] = useState('');
  let userid: any = localStorage.getItem("userid");
  let token: any = localStorage.getItem("token");
  let workspaceId: any = localStorage.getItem("workspaceId");

  //API calling
  React.useEffect(() => {
    let location: any;
    if (locations.state) {
      location = locations.state as LocationState;
      localStorage.setItem('routeState', JSON.stringify(location))
    } else {
      location = localStorage.getItem('routeState')
      if (location) location = JSON.parse(location)
    }
    if (location) {
      setUserWorkId(location.id);
      if (location.loggedStatus == false && location.invitedBy == true) {
        setInputDisable(false)
      } else {
        setInputDisable(true)
      }
      const fetchData = async () => {
        try {
          var url = ENV.ENDPOINT + '/user-workspaces?populate=*&filters[id][$eq]=' + location.id;
          const response = await fetch(url, {
            headers: {
              Authorization: HeaderAuthorization.Authorization,
              userId: userid,
              token: token,
            },
          });
          const data = await response.json();
          if (data.data[0].attributes.user_role == null) {
            setEditRole("builder");
          }
          else {
            setEditRole(data.data[0].attributes.user_role);
          }
          seEditUserId(data.data[0].attributes.user_id.data.id);
          setData({
            firstName: data.data[0].attributes.user_id.data.attributes.firstName,
            lastName: data.data[0].attributes.user_id.data.attributes.lastName,
            mobileNumber: data.data[0].attributes.user_id.data.attributes.mobileNumber,
            email: data.data[0].attributes.user_id.data.attributes.email,
            role: data.data[0].attributes.user_role,
            owner: data.data[0].attributes.owner,
            username: data.data[0].attributes.user_id.data.attributes.username
          });
          if (data.data[0].attributes.user_role == null) {
            setValue("role", "builder")
          }
          else {
            setValue("role", data.data[0].attributes.user_role)
          }
          if (data.data[0].attributes.owner == true) {
            setIsChecked(true)
          }
          if (data.data[0].attributes.user_status == "Active") {
            setUserStatus(true)
          }
          return data
        } catch (error: any) {
          console.log(error);
          if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
            Logout(history)
          }
        }

      }
      fetchData();

    }
  }, [locations]);

  //Check the condition
  useEffect(() => {
    const checkFunc = (event: any) => {
      if (event.detail.value) {
        setIsChecked(true)
      }
      else {
        setIsChecked(false)
      }

    }

  }, [isChecked])

  //Function to generate random passwords
  function pwdGenerator(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
  const methods = useForm();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = methods;
  let history = useHistory();
  //Function for page back
  const pageBack = () => {
    // history.go(-1)
    history.push('/users');
  };



  //Function that handles the form submit 
  const onSubmit = async (data: any) => {
    if (data.owner == "on") {
      data.owner = true
    } else {
      data.owner = false
    }
    if (inputDisable == false) {


      try {
        const response = await axios({
          method: 'get',
          url: ENV.ENDPOINT + '/users?fields=id,email,firstName,lastName,mobileNumber&filters[$or][0][email][$eq]=' + data.email + '&filters[$or][1][mobileNumber][$eq]=' + data.telephone + '&filters[id][$not]=' + editUserId,
        })
        if (response.data.length == 0) {

          var pass = pwdGenerator(8);
          try {
            await axios({
              method: 'PUT',
              url: ENV.ENDPOINT + '/users/' + editUserId?.toString(),
              data: {
                "mobileNumber": data.telephone,
                "email": data.email,
                'password': pass,
                "user_roles": data.role
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userid,
                token: token,
              },
            })
            setColordata("success");
            setMessage("User profile has been updated successfully");
            setShowToast1(true)
            try {
              await axios({
                method: "PUT",
                url: ENV.ENDPOINT + '/user-workspaces/' + userWorkId,
                data: {
                  "data": {
                    user_role: data.role,
                    owner: data.owner
                  }
                },
                headers: {
                  Authorization: HeaderAuthorization.Authorization,
                  userId: userid,
                  token: token,
                },
              })
              try {
                await axios({
                  method: 'POST',
                  url: ENV.ENDPOINT + '/otps/createotp',
                  data: {
                    "data": {
                      Mobile_Number: '91' + data.telephone,
                      email: data.email,
                      Genrate_Message: (`Following are the link for invite user:${ENV.WebsiteURL}/index`),
                      pwd: pass,
                    }
                  },
                  headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                  },
                })
              } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                  Logout(history)
                }
              }
              if (data.owner == true) {
                try {
                  await axios({
                    method: "PUT",
                    url: ENV.ENDPOINT + '/user-workspaces/' + ownerId,
                    data: {
                      "data": {
                        owner: false
                      }
                    },
                    headers: {
                      Authorization: HeaderAuthorization.Authorization,
                      userId: userid,
                      token: token,
                    },
                  })

                  await axios({
                    method: "PUT",
                    url: ENV.ENDPOINT + '/workspaces/' + workspaceId,
                    data: {
                      "data": {
                        owner_id: editUserId
                      }
                    },
                    headers: {
                      Authorization: HeaderAuthorization.Authorization,
                      userId: userid,
                      token: token,
                    },
                  })


                  const sessionExists = await axios({
                    method: 'get',
                    url: ENV.ENDPOINT + `/sessions?populate=*&filters[users_permissions_user][id][$eq]=${editUserId?.toString()}`,
                    headers: {
                      Authorization: HeaderAuthorization.Authorization,
                      userId: userid,
                    },
                  })


                  if (sessionExists.data.data.length > 0) {


                    var session = await axios({
                      method: 'put',
                      url: ENV.ENDPOINT + `/sessions/${sessionExists.data.data[0].id}`,
                      data: {
                        "data": {
                          token: ""
                        }
                      },
                      headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userid,
                      },
                    })

                  }


                  history.go(-1)
                  history.push('/users')

                } catch (error: any) {
                  console.log(error);
                  if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                  }
                }

              } else {

                const sessionExists = await axios({
                  method: 'get',
                  url: ENV.ENDPOINT + `/sessions?populate=*&filters[users_permissions_user][id][$eq]=${editUserId?.toString()}`,
                  headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                  },
                })


                if (sessionExists.data.data.length > 0) {


                  var session = await axios({
                    method: 'put',
                    url: ENV.ENDPOINT + `/sessions/${sessionExists.data.data[0].id}`,
                    data: {
                      "data": {
                        token: ""
                      }
                    },
                    headers: {
                      Authorization: HeaderAuthorization.Authorization,
                      userId: userid,
                    },
                  })

                }
                history.go(-1)
                history.push('/users')
              }

            } catch (error: any) {
              console.log(error);
              if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
              }
            }



          } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
              Logout(history)
            }
          }

        } else {


          if (response.data[0].email == data.email) {
            setColordata("danger")
            setMessage("Email Id already exist");
            setShowToast1(true)
          }
          else if (response.data[0].mobileNumber == data.telephone) {
            setColordata("danger")
            setMessage("Mobile Number already exist");
            setShowToast1(true)
          }
          else {
            setColordata("danger")
            setMessage("Email Id or mobile number already exist");
            setShowToast1(true)
          }
        }


      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
    else {

      await axios({
        method: 'PUT',
        url: ENV.ENDPOINT + '/users/' + editUserId?.toString(),
        data: {
          "user_roles": data.role
        },
        headers: {
          Authorization: HeaderAuthorization.Authorization,
          userId: userid,
          token: token,
        },
      })
      setColordata("success");
      setMessage("User profile has been updated successfully");
      setShowToast1(true)
      try {
        await axios({
          method: "PUT",
          url: ENV.ENDPOINT + '/user-workspaces/' + userWorkId,
          data: {
            "data": {
              user_role: data.role,
              owner: data.owner
            }
          },
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userid,
            token: token,
          },
        })
        if (data.owner == true) {
          try {
            await axios({
              method: "PUT",
              url: ENV.ENDPOINT + '/user-workspaces/' + ownerId,
              data: {
                "data": {
                  owner: false
                }
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userid,
                token: token,
              },
            })

            await axios({
              method: "PUT",
              url: ENV.ENDPOINT + '/workspaces/' + workspaceId,
              data: {
                "data": {
                  owner_id: editUserId
                }
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userid,
                token: token,
              },
            })


            const sessionExists = await axios({
              method: 'get',
              url: ENV.ENDPOINT + `/sessions?populate=*&filters[users_permissions_user][id][$eq]=${editUserId?.toString()}`,
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userid,
              },
            })


            if (sessionExists.data.data.length > 0) {

              var session = await axios({
                method: 'put',
                url: ENV.ENDPOINT + `/sessions/${sessionExists.data.data[0].id}`,
                data: {
                  "data": {
                    token: ""
                  }
                },
                headers: {
                  Authorization: HeaderAuthorization.Authorization,
                  userId: userid,
                },
              })

            }
            history.go(-1)
            history.push('/users')

          } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
              Logout(history)
            }
          }

        } else {
          const sessionExists = await axios({
            method: 'get',
            url: ENV.ENDPOINT + `/sessions?populate=*&filters[users_permissions_user][id][$eq]=${editUserId?.toString()}`,
            headers: {
              Authorization: HeaderAuthorization.Authorization,
              userId: userid,
            },
          })


          if (sessionExists.data.data.length > 0) {

            var session = await axios({
              method: 'put',
              url: ENV.ENDPOINT + `/sessions/${sessionExists.data.data[0].id}`,
              data: {
                "data": {
                  token: ""
                }
              },
              headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userid,
              },
            })

          }

          history.go(-1)
          history.push('/users')
        }

      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
  };
  //Function for back button
  const doNothing = () => {
    history.goBack();
  }
  const [permission, setPermission] = useState('');
  const userRole = localStorage.getItem('work_roles');
  let permissions = ""
  //check the permissions accordinf to roles 
  useEffect(() => {

    switch (userRole) {
      case null:
      case "null":
      case "builder":
        permissions = "0";
        break;
      case "manager":
        permissions = "1";
        break;
      case "site":
        permissions = "2";
        break;
      case "supervisor":
        permissions = "3";
        break;
      case "stock":
        permissions = "4";
        break;
      default:
        // Handle any other user roles here, if needed
        permissions = "0";
        break;
    }
    setPermission(permissions);
  }, [permission])
  //Render JSX component

  return (
    <IonPage>
      <Header class="with-back-arrow" onBack={doNothing} />
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="login-form-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="back-arrow">
                <IonButton
                  className="ion-text-right"
                  fill="clear"
                  onClick={pageBack} >
                  <IonIcon icon="/assets/images/arrow-left-icon.svg"></IonIcon>
                </IonButton>
                <h3>Edit User</h3>
              </div>
              <div className="user-name-display">

                <h3 className="first-name">Name:- {data.firstName}</h3>
                <h3 className="last-name">{data.lastName}</h3>
              </div>
              <div className="user-name-display">
                <h3 className="first-name">User Name:- {data.username}</h3>
              </div>
              <FormProvider {...methods}>
                <form
                  className="login-form"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off" >
                  <IonRow>
                    <IonCol size="12" className="forget-password">
                      <IonLabel className="form-lable">Mobile No.*</IonLabel>
                      <div className="input-with-icon read-only-input">
                        <IonIcon icon={phonePortraitSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              type="number"
                              onIonChange={onChange}
                              onBlur={onBlur}
                              value={value == null ? data.mobileNumber : value}
                              className={`form-control ${errors.telephone ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md"
                              disabled={inputDisable} />
                          )}
                          control={control}
                          name="telephone"
                          rules={{
                            required: "Telephone is required",
                            minLength: {
                              value: 10,
                              message: "Please enter a valid phone number",
                            },
                            maxLength: {
                              value: 10,
                              message: "Please enter a valid phone number",
                            },
                          }} />
                      </div>
                    </IonCol>
                    <IonCol size="12" className="email-field">
                      <IonLabel className="form-lable">Email*</IonLabel>
                      <div className="input-with-icon read-only-input">
                        <IonIcon icon={mailSharp} />
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <IonInput
                              onIonChange={onChange}
                              onBlur={onBlur}
                              value={value == null ? data.email : value}
                              className={`form-control ${errors.email ? "is-invalid" : ""
                                }`}
                              placeholder=""
                              mode="md"
                              disabled={inputDisable} />
                          )}
                          control={control}
                          name="email"
                          rules={{
                            required: "Email required field",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          }} />
                      </div>
                    </IonCol>
                    <IonCol size="12" className="">
                      <div className="tooltip-container">
                        <IonLabel className="form-lable note-role">Role:*
                        </IonLabel>
                        {(permission == "0" || permission == "1" || ownerStatus == "true") ?
                          <Tooltip
                            placement="top"
                            title="The Plus roles have their respective role access. With that they also have permission to edit inward and outward entries">
                            <IonIcon
                              icon={informationCircle} />
                          </Tooltip>
                          : null}
                      </div>

                      <Controller
                        render={({ field }) => (
                          <IonSelect
                            placeholder="Select One"
                            value={field.value}
                            className={`form-control ${errors.role ? "is-invalid" : ""
                              }`}
                            onIonChange={(e) =>
                              setValue("role", e.detail.value)
                            } >
                            {(permission == "0" || ownerStatus == "true") ? (
                              <IonSelectOption value="builder">
                                Builder
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                              <IonSelectOption value="manager">
                                Project Manager
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || permission == "2" || ownerStatus == "true") ? (
                              <IonSelectOption value="site">
                                Site Incharge
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                              <IonSelectOption value="sitePlus">
                                Site Incharge Plus
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || permission == "2" || permission == "3" || ownerStatus == "true") ? (
                              <IonSelectOption value="supervisor">
                                Supervisor/Engineer
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                              <IonSelectOption value="supervisorPlus">
                                Supervisor/Engineer Plus
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || permission == "2" || permission == "3" || permission == "4" || ownerStatus == "true") ? (
                              <IonSelectOption value="stock">
                                Stock Manager
                              </IonSelectOption>) : null}
                            {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                              <IonSelectOption value="stockPlus">
                                Stock Manager Plus
                              </IonSelectOption>) : null}
                          </IonSelect>
                        )}
                        control={control}
                        name="role"
                        rules={{ required: "Please select role" }} />
                      <ErrorMessage
                        errors={errors}
                        name="role"
                        as={
                          <div
                            className="error-message"
                            style={{ color: "red" }} />
                        } />
                    </IonCol>
                    {(ownerStatus == "true") && (userStatus == true) && (editRoles == "builder" || editRoles == "site" || editRoles == "manager") ? (
                      <IonCol size="12" className="">
                        <IonItem className="checkbox-wrap">
                          <IonLabel className="form-lable">
                            Workspace Owner
                          </IonLabel>
                          <IonCheckbox mode="md" slot="start" name="owner" checked={isChecked} onIonChange={(e) => {
                            setValue("owner", e.detail.value)
                          }
                          }></IonCheckbox>
                        </IonItem>
                      </IonCol>
                    ) : ""}
                    <IonCol
                      size="8"
                      className="ion-align-self-center ion-margin-auto ion-text-center ion-margin-top" >
                      <IonButton type="submit" fill="solid" expand="block">
                        Update
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </FormProvider>
            </IonCol>
          </IonRow>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message={message}
            duration={1500}
            position="middle"
            color={colordata} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default EditUser;
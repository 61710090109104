import React, { useRef, useState } from "react";
import { IonContent, IonPage, IonIcon, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonList, IonItem } from '@ionic/react';
import { arrowForwardCircleSharp } from 'ionicons/icons';
import { RouteComponentProps, useHistory } from "react-router-dom";
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import Header from '../components/Header/Header';
import axios from 'axios';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Loader from "../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { setProjectName, setProjectId, setProjectDate } from "../features/project/projectSlice";
import Logout from "../components/Logout";

// Define the types for selectProjectPageProps
interface selectProjectPageProps
  extends RouteComponentProps<{
    id: string;
  }> { }

const SelectProject: React.FC<selectProjectPageProps> = ({ match }) => {
  // Get the history object to handle navigation
  let history = useHistory();
  // Get the redux dispatch function
  const dispatch = useDispatch();
  // Function to handle log out
  const logOut = async () => {
    let sessionId: any = localStorage.getItem("token")
    let userid: any = localStorage.getItem("userid");


    try {
      const sessionExists = await axios({
        method: 'get',
        url: ENV.ENDPOINT + `/sessions/${sessionId}`,
        headers: {
          Authorization: HeaderAuthorization.Authorization,
          userId: userid,
        },
      })
      if (sessionExists) {
        await axios({
          method: 'delete',
          url: ENV.ENDPOINT + `/sessions/${sessionId}`,
          headers: {
            Authorization: HeaderAuthorization.Authorization,
            userId: userid,
          },
        })

      }
      localStorage.clear();
      history.push('/index')
      window.location.reload();
    } catch (error) {
      console.log(error);
      localStorage.clear();
      history.push('/index')
      window.location.reload();
    }
  }



  const [ownerStatus, setOwnerStatus] = useState([
    { id: null, attributes: { "owner": false } }
]);
// Fetch owner status when the component mounts
React.useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await checkOwnerStatus();
            if (data['data'].length != 0) {
                setOwnerStatus(data['data']);
            }
            setIsloading(false);
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    }
    fetchData();

}, []);
// Function to check owner status
const checkOwnerStatus = async () => {
    try {
        const response = await axios({
            url: ENV.ENDPOINT + '/user-workspaces?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[user_id][id][$eq]=' + userId + '&filters[owner][$eq]=true',
            method: 'get',
            headers: {
                Authorization: HeaderAuthorization.Authorization,
                userId: userId,
                token: token,
            },
        })
        return response.data;
    } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
            Logout(history)
        }
    }


};
   // State to manage loading of data
   const [isloading, setIsloading] = useState(true);

   // Get user roles and workspace ID from local storage
  const user_roles = localStorage.getItem('work_roles');
  const workspaceId = useSelector((state: any) => state.workspace.workspaceId).toString();
  const workspceSliceName = useSelector((state: any) => state.workspace.workspaceName);

// Function to navigate back to the previous page
  const backPage = () => {
    history.push({ pathname: "/select-workspace" });
  };
    // Function to do nothing (go back)
  const doNothing = () => {
    history.goBack();
  }
    // State to store the project list data
  const [projectListData, setProjectListData] = useState([
    { id: 1, attributes: { "project_name": "" } },
    { id: 2, attributes: { "project_name": "" } }
  ]);
   // Reference to the loader div for infinite scrolling
  const loaderRef = useRef(null);
    // State to manage infinite scrolling
  const [endReach, setEndReach] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // Get user ID and token from local storage
  const userId: any = localStorage.getItem('userid');
  const token: any = localStorage.getItem('token');

  React.useEffect(() => {

    const fetchData = async () => {
      try {
        getProjectList(1)

      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }
    }

    fetchData();
  }, []);

  if (window.localStorage.getItem('refresh') != null) {
    window.localStorage.removeItem("refresh")
  }
  // Function to fetch project list data

  const getProjectList = async (page: number) => {
    try {
      const response = await axios({
        url: ENV.ENDPOINT + '/projects?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[project_status][$eq]=Active&pagination[page]=' + page,
        method: 'get',
        headers: {
          Authorization: HeaderAuthorization.Authorization,
          userId: userId,
          token: token,
        },
      })
      const data = response.data;
      if (data.data.length === 0) {
        setIsloading(false);
        setHasMoreData(false);
        setEndReach(true)
      }

      else {
        setProjectListData((prevData: any) => [...prevData, ...data.data]);
        setCurrentPage(page);
      }
      setIsloading(false);
      return data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
        Logout(history)
      }
    }
  };
  // Function to select a project and update redux state and local storage

  const selectProject = (data: any) => {
    dispatch(setProjectDate(data.attributes.project_start_date))
    dispatch(setProjectName(data.attributes.project_name));
    dispatch(setProjectId(data.id));
    localStorage.setItem('projectId', data.id)
    localStorage.setItem('projectName', data.attributes.project_name);
  }
    // Function to navigate to the edit workspace page
  const editWorkspace = () => {
    history.push({
      pathname: '/edit-workspace',
      state: { "id": workspaceId, "name": workspceSliceName, "status": "editWorkspace" }
    }
    );
  };
    // Function to navigate to the create project page
  const createProject = () => {
    history.push({
      pathname: '/create-project',
      state: { "status": "createProject", "workID": workspaceId, "workName": workspceSliceName }
    }
    );
  };
  // Function to handle infinite scrolling
  const handleObserver = (entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && !isloading) {
      const nextPage = currentPage + 1;
      getProjectList(nextPage);
    }
  };

  React.useEffect(() => {
    if (!endReach) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(handleObserver, options);

      if (loaderRef.current) {

        observer.observe(loaderRef.current);
      }
      return () => {
        if (loaderRef.current) {
          observer.unobserve(loaderRef.current);
        }
      };
    }
  }, [currentPage, isloading, hasMoreData, endReach]);

  //Render JSX component
  return (
    <IonPage>
      <Header class="" onBack={doNothing} />
      <IonContent fullscreen>
        <IonGrid className="full-height-div">
          <IonRow className="main-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="select-project-header">
                <IonButton className="ion-text-right" fill="clear" onClick={backPage}>
                  <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                </IonButton>
                <div className="project-title-wrap">
                  <h6>Select Project of</h6>
                  <h3><b>{workspceSliceName}</b></h3>
                </div>
                <IonButton className="edit-project-button ion-text-right" fill="clear" onClick={editWorkspace}>
                  {(user_roles == "null" || user_roles == "manager" || user_roles == "site" || user_roles == "builder" || ownerStatus[0].attributes.owner == true || user_roles == "sitePlus") ? (
                    <IonIcon icon="/assets/images/edit-icon.svg" ></IonIcon>
                  ) : ""}
                </IonButton>
                <div style={{ paddingLeft: "15px" }}> <IonButton className="ion-text-right" fill="clear" onClick={logOut}>
                  <IonIcon slot="icon-only" src="assets/images/logout-icon.svg" ></IonIcon>
                </IonButton>
                </div>
              </div>
              <IonCard>
                <IonCardContent>
                  <IonList mode="md" className="" >
                    {projectListData && projectListData.map((item, index) => (

                      <div key={index}>
                        {
                          item.attributes.project_name != "" && <IonItem key={item.id} className="position-relative">
                            <IonLabel mode='md'>{item.attributes.project_name}</IonLabel>
                            <IonIcon icon={arrowForwardCircleSharp} slot="end" />
                            <IonButton className="abosolute-button-with-link" expand="full" fill="clear" slot="end" routerLink={"/dashboard/" + item.id} onClick={() => { selectProject(item) }}>
                            </IonButton>
                          </IonItem>

                        }
                        <div ref={loaderRef}> {(isloading && <Loader />)}</div>

                      </div>

                    ))}
                  </IonList>
                  {(isloading && <Loader />)}
                </IonCardContent>
              </IonCard>
              {(user_roles == "null" || user_roles == "manager" || user_roles == "builder" || user_roles == "site" || user_roles == "sitePlus" || ownerStatus[0].attributes.owner == true ) ? (
                <IonCard className="light-pink-card">
                  <h5>Want to create another Project?</h5>
                  <IonButton className="secondary-button" fill="solid" onClick={createProject}>
                    Create Project
                  </IonButton>
                </IonCard>
              ) : ""}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SelectProject;

// Importing necessary modules from the React and Ionic libraries
import React from "react";
import { IonHeader } from '@ionic/react';

// Importing CSS stylesheets for customization
import '../../assets/css/Custom.css';
import '../../assets/css/Responsive.css';

// Importing the HeaderLogo component from the same directory
import HeaderLogo from "./HeaderLogo";

// Defining a functional component called Header that accepts props with a specific shape
const Header: React.FC<{ class: string; onBack: () => void }> = () => {
    return (
        // Rendering an IonHeader component from Ionic framework
        // The header has the class "main-header" and is hidden for medium screen sizes and up (ion-hide-md-down)
        <IonHeader className="main-header ion-hide-md-down">
            {/* Rendering the HeaderLogo component */}
            <HeaderLogo />
            {/* Adding an empty div */}
            <div className="empty-div"></div>
        </IonHeader>
    );
};

// Exporting the Header component to make it available for use in other parts of the application
export default Header;

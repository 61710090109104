// Importing createSlice from the Redux Toolkit library
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the reducer
const initialState = {
  stockFinal: false,   // Boolean to indicate whether the stock data is finalized
  stockData: [],       // Array to hold the stock data
  stockHasMoreData: true,  // Flag to indicate if there is more stock data available
}

// Creating a slice of the Redux store using createSlice
export const stockSlice = createSlice({
  name: "stock",    // Name of the slice, which can be used to access the state in the Redux store
  initialState,     // Initial state of the slice
  reducers: {
    // Reducer function to set the stockFinal state with the provided payload
    setStockFinal: (state, action) => {
      state.stockFinal = action.payload;
    },
    // Reducer function to set the stockData state with the provided payload
    setStockData: (state, action) => {
      state.stockData = action.payload;
    },
    // Reducer function to set the stockHasMoreData state with the provided payload
    setStockHasMoreData: (state, action) => {
      state.stockHasMoreData = action.payload;
    },
  },
});

// Exporting the action creators generated by createSlice
export const { setStockFinal, setStockData, setStockHasMoreData } = stockSlice.actions;

// Exporting the reducer generated by createSlice
export default stockSlice.reducer;

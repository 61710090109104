import React, { useState } from "react";
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonImg, IonToast } from '@ionic/react';
import { isPlatform } from '@ionic/react';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {
  // Get the history object to handle navigation
  let history = useHistory();
  //intitlize message of toast 
  const [message, setMessage] = useState('');
  const [showToast1, setShowToast1] = useState(false);
  const [colordata, setColordata] = useState('');
  const loggedIn = localStorage.getItem("isLoggedIn")
  //check the platform
  if (isPlatform('desktop')) {
    window.addEventListener('load', function (e) {
      setTimeout(
        () => {
          if (navigator.onLine) {
            if (loggedIn) {
              setColordata("success")
              setMessage("Welcome to login");
              setShowToast1(true)
              history.push('/select-workspace');
            }
            else {
              history.push('/index');
            }
          } else {
            setColordata("danger")
            setMessage("Check your internet connection");
            setShowToast1(true)

          }
        },
        100
      );

    }, false);
    return (
      <>
      </>
    )
  }
  else {
    window.addEventListener('load', function (e) {
      setTimeout(
        () => {
          if (navigator.onLine) {
            if (loggedIn) {
              setColordata("success")
              setMessage("Welcome to login");
              setShowToast1(true)
              history.push('/select-workspace');
            }
            else {
              history.push('/index');
            }
          } else {
            setColordata("danger")
            setMessage("Check your internet connection");
            setShowToast1(true)

          }
        },
        1500
      );

    }, false);
    return (
      <IonPage>
        <IonContent className="splash-screen-content" fullscreen>
          <IonGrid className="full-height-div">
            <IonRow>
              <IonCol size="12" sizeMd="6" sizeLg="4">
                <div className="splash-screen-image">
                  <IonImg src="/assets/images/splash-screen.png" alt="Splash Screen" />
                </div>
                <div className="login-header splash-screen-header">
                  <IonImg src="/assets/images/csm-logo.png" alt="Logo" />
                  <h3>Construction Site Manager</h3>
                </div>
                <h3 className="app-name ion-text-center">Building Construction <br />Inventory Management</h3>
                <h6 className="app-version">Version 1.0</h6>
              </IonCol>
              <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message={message}
                duration={2000}
                position="middle"
                color={colordata} />
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    )
  }


}

export default Home;
// Importing configureStore from the Redux Toolkit library
import { configureStore } from '@reduxjs/toolkit';

// Importing the various Redux slices
import workspaceSlice from '../features/workspace/workspaceSlice';
import projectSlice from '../features/project/projectSlice';
import stockSlice from '../features/stockReport/stockSlice';
import inwardSlice from '../features/Inward/inwardSlice';
import outwardSlice from '../features/outward/outwardSlice';

// Configuring the Redux store with multiple reducers using configureStore
export const Store = configureStore({
    reducer: {
        workspace: workspaceSlice,   // Workspace-related reducer using workspaceSlice
        project: projectSlice,       // Project-related reducer using projectSlice
        stock: stockSlice,           // Stock-related reducer using stockSlice
        inward: inwardSlice,         // Inward-related reducer using inwardSlice
        outward: outwardSlice,       // Outward-related reducer using outwardSlice
    }
});

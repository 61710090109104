// Importing necessary dependencies and styles
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import IonIconButton from "../components/Button/IonIconButton";
import { useSelector } from 'react-redux';

// Interface to define the type of the dashboardPageProps
interface dashboardPageProps
    extends RouteComponentProps<{
        id: string;
    }> { }

// Defining the Dashboard functional component
const Dashboard: React.FC<dashboardPageProps> = ({ match }) => {
    // Getting user roles from local storage
    const user_roles = localStorage.getItem('work_roles');
    // Getting project and workspace details from redux state
    const projectId = localStorage.getItem('projectId');
    const history = useHistory();
    const projectName = useSelector((state: any) => state.project.projectName).toString();
    const projectDate = useSelector((state: any) => state.project.projectDate).toString();
    const workspaceId = useSelector((state: any) => state.workspace.workspaceId).toString();
    const workspaceName = useSelector((state: any) => state.workspace.workspaceName).toString();
    // Removing the 'refresh' item from local storage if it exists
    if (window.localStorage.getItem('refresh') != null) {
        window.localStorage.removeItem("refresh")
    }
    // Function to navigate back to the previous page
    const backPage = () => {
        // history.go(-1)
        history.push({ pathname: "/select-project/" + workspaceId });
    }
    // Function to handle navigation back
    const doNothing = () => {
        history.goBack();
    }
    // Function to navigate to the edit project page

    const editProject = () => {
        history.push({
            pathname: '/edit-project',
            state: { "id": projectId, "name": projectName, "status": "editProject", "workID": workspaceId, "workName": workspaceName }
        });
    };
    // JSX content of the Dashboard component

    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton className="ion-text-right" fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Dashboard</b></h3>
                                </div>
                            </div>
                            <IonRow className="main-title-with-icons-on-right ion-align-items-end">
                                <IonCol size="9" sizeMd="9" sizeLg="9" className="title-wrap ion-no-padding">
                                    <h4 className="ion-no-margin">Project</h4>
                                </IonCol>
                                <IonCol size="3" sizeMd="3" sizeLg="3" className="buttons-wrap ion-text-end ion-no-padding">
                                    <IonIconButton routerLink={"/select-project/" + workspaceId} icon="assets/images/arrow-down-icon.svg" />

                                    {(user_roles == "builder" || user_roles == "manager" || user_roles == "site") ? (
                                        <IonButton fill="clear" onClick={editProject} >
                                            <IonIcon icon="/assets/images/edit-icon.svg" ></IonIcon>
                                        </IonButton>
                                    ) : ""}
                                </IonCol>
                            </IonRow>
                            <div className="project-name">
                                <h3 className="ion-no-margin"><b>{projectName}</b></h3>
                                {/* Displaying project date if available */}

                                {(projectDate) ? (
                                    <h6 className="project-date">
                                        {String(new Date(projectDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long' })).split(" ").reverse().join(" ")}
                                        , {projectDate.split('-')[0]}</h6>) : ""}
                            </div>
                            {/* Buttons to navigate to different sections */}

                            <IonRow className="dashboar-buttons">
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" routerLink='/my-account'>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/my-account-icon.svg" />
                                            <h6 className="">My Account</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" routerLink='/stock-category'>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/stock-icon.svg" />
                                            <h6 className="">Stock</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" routerLink='/inward'>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/inward-icon.svg" />
                                            <h6 className="">Inward</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" routerLink='/outward'>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/outward-icon.svg" />
                                            <h6 className="">Outward</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

// Exporting the Dashboard component as the default export
export default Dashboard;
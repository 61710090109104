// Import necessary modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import Outward from "../views/outward";
import Logout from "../components/Logout";

// Define interfaces for type checking
interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    editStatus: boolean;
    outwardId: number;
    categoryTrace: object;
    materialItem: string;
    parentCatId: number;
    backpath: string;
}
interface MaterialListArr {
    id: number;
    material_item_name: string;
    material_unit: string;
    material_unitId: number;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}
interface BrandListArr {
    id: number;
    material_brand_name: string;
}
const OutwardEntry: React.FC = () => {
    const locations: any = useLocation();
    let history = useHistory();
    const [materialListData, setMaterialListData] = useState([
        { id: 1, "material_item_name": "", "material_unit": "unit1", material_unitId: 1 },
        { id: 2, "material_item_name": "", "material_unit": "unit2", material_unitId: 2 },
    ]);
    const [materialUnit, setMaterialUnit] = useState('');
    const [materialUnitId, setMaterialUnitId] = useState(0);
    const [brandListData, setBrandListData] = useState([
        { id: 1, "material_brand_name": "" },
        { id: 2, "material_brand_name": "" },
    ]);
    const [purposeListData, setPurposeListData] = useState([
        { id: 1, attributes: { "material_purpose_name": "", "material_purpose_enable": true } },
        { id: 2, attributes: { "material_purpose_name": "", "material_purpose_enable": true } },
    ]);
    const [buildingListData, setBuildingListData] = useState<any>([]);
    const [wingFlatListData, setwingFlatListData] = useState([
        { id: 1, attributes: { "project_building": "", "project_flatno": "", "project_wing": "" } },
        { id: 2, attributes: { "project_building": "", "project_flatno": "", "project_wing": "" } },
    ]);
    const [parentCatId, setParentCatId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    const [outwardId, setOutwardId] = useState(0);
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [categoryTrace, setCategoryTrace] = useState({});
    const [materialItem, setMaterialItem] = useState('');
    const [subCategory, setSubCategory] = useState({});
    const [wing, setWings] = useState(false)
    const [flat, setFlat] = useState(false)
    const [backData, setBackData] = useState('');
    const [materialItemId, SetMaterialItemId] = useState(0);
    const [outwardPurposeEnable, SetOutwardPurposeEnable] = useState(true);
    const [newWings, setnewWings] = useState<any>([]);
    const [flatNos, setFlatNos] = useState<any>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    //Function for back page
    const backPage = () => {
        if (backData == 'materialstock') {
            // history.go(-1)
            history.push({ pathname: '/material-stock', state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace, "parentCatId": parentCatId } });
        } else if (backData == 'stockreport') {
            // history.go(-1)
            history.push({ pathname: '/stock-report', state: { "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialId": materialItemId } });
        }
        else {
            var pushCategoryTrace = {} as pushState
            if (categoryTrace) {
                const categoryPush = categoryTrace as pushState
                pushCategoryTrace['categoryName'] = categoryPush['categoryName']
                pushCategoryTrace['categoryId'] = categoryPush['categoryId']
            }
            pushCategoryTrace["pushBack"] = true
            pushCategoryTrace["subCategory"] = subCategory;
            // history.go(-1)
            history.push({
                pathname: '/outward',
                state: pushCategoryTrace
            });
        }
    };
    const methods = useForm();
    const { handleSubmit, setValue, getValues, control, formState: { errors } } = methods;

    //Function that handles the form request while submiting the form
    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        let quantityInwardValue = 0;
        let quantityOutwardValue = 0;
        let totOutwardValue = 0;
        let materialItemData: any;
        let buildingData: any;
        if (data.material == '') {
            materialItemData = null;


        } else {
            materialItemData = data.material;
        }

        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/project-building-infos?populate=*&filters[project_id][id][$eq]=' + projectId + '&filters[project_building][$eq]=' + getValues('building') + '&filters[project_wing][$eq]=' + getValues('wing') + '&filters[project_flatno][$eq]=' + getValues('flat'),
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            buildingData = response.data['data'][0].id;

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }


        let urlInwardLink: any;
        let urlOutwardLink: any;
        if (data.material != '') {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material + '&filters[material_brand]][id][$eq]=' + data.brand;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material + '&filters[material_brand]][id][$eq]=' + data.brand;
        }
        else {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_brand]][id][$eq]=' + data.brand;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_brand]][id][$eq]=' + data.brand;
        }

        try {
            const response = await axios({
                url: urlInwardLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            if (response.data['data'].length == 0) {
                setMessage("No Inward Entry Found");
                setShowToast1(true)
            } else {
                response.data['data'].forEach((list: any) => {
                    quantityInwardValue = quantityInwardValue + list.attributes.inward_quantity;
                })
                try {
                    const outwardResponse = await axios({
                        url: urlOutwardLink,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    if (outwardResponse.data['data'].length != 0) {
                        outwardResponse.data['data'].forEach((list: any) => {
                            quantityOutwardValue = quantityOutwardValue + list.attributes.outward_quantity;
                        })
                    }
                    totOutwardValue = quantityOutwardValue + parseInt(data.quantity);
                    if (quantityInwardValue > 0) {
                        if ((quantityInwardValue - totOutwardValue >= 0)) {
                            let outwardDate = new Date().toISOString();
                            outwardDate = format(parseISO(outwardDate), 'yyyy-MM-dd')

                            const materialPurpose = data.purpose ? data.purpose : null;
                            if (buildingData) {
                                try {
                                    await axios({
                                        method: 'POST',
                                        url: ENV.ENDPOINT + '/outward-entries',
                                        data: {
                                            "data": {
                                                material_item: materialItemData,
                                                material_brand: data.brand,
                                                outward_quantity: data.quantity,
                                                outward_note: data.Notes,
                                                material_category: categoryId,
                                                workspace: workspaceId,
                                                project: projectId,
                                                outward_createdby: userId,
                                                outward_date: outwardDate,
                                                material_unit: materialUnitId,
                                                material_purpose: materialPurpose,
                                                project_building_info: buildingData
                                            }
                                        },
                                        headers: {
                                            Authorization: HeaderAuthorization.Authorization,
                                            userId: userId,
                                            token: token,
                                        },
                                    })
                                    setColordata("success")
                                    setMessage("Outward Entry Created successfully");
                                    setShowToast1(true)
                                    setValue('material', '');
                                    setValue('brand', '');
                                    setValue('quantity', '');
                                    setValue('Notes', '');
                                    setValue('purpose', '');
                                    setValue('building', '');
                                    setValue('wing', '');
                                    setValue('flat', '');

                                } catch (error: any) {
                                    console.log(error);
                                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                                        Logout(history)
                                    }
                                }

                            }
                        } else {
                            setColordata("danger")
                            setMessage("Total outward quantity more than total inward quantity" + quantityInwardValue);
                            setShowToast1(true)
                        }
                    }
                    else {
                        setColordata("danger")
                        setMessage("No Inward Available");
                        setShowToast1(true)
                    }

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
        setIsSubmitting(false);
    };
    //Function for back button
    const doNothing = () => {
        history.goBack();
    }


    // change the value for values and check the condition 
    React.useEffect(() => {
        let location: any;
        if (locations.state) {
            if (locations?.state?.subCategory?.length === 0) {
                locations.state.subCategory.push({});
            }

            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {

            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }

        if (location) {
            setCategoryId(location.categoryId);

            setParentCatId(location.parentCatId);
            setCategoryName(location.categoryName);
            setCategoryTrace(location.categoryTrace)
            setSubCategory(location.subCategory)
            setBackData(location.backpath)
            let parentId: string;
            if (location) {
                if (location.subCategory) {
                    if (location.subCategory[0].categoryId != undefined) {
                        parentId = location.subCategory[0].categoryId
                    } else if (location.subCategory.length > 1) {
                        parentId = location.subCategory[1].categoryId
                    }
                    else {
                        parentId = location.categoryId;
                    }
                } else {
                    parentId = location.categoryId;
                }
            }
            if (location.materialItem == null || location.materialItem == undefined || location.materialItem == "") {
                setMaterialItem("Material")
            } else {
                setMaterialItem(location.materialItem)
            }

            const BuildingData = async () => {
                try {
                    let page = 1;
                    let allData = [];

                    while (true) {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/project-building-infos?populate=*&filters[project_id][id][$eq]=' + projectId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userId,
                                token: token,
                            },
                        });

                        const responseData = response.data;

                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }

                        allData.push(...responseData.data);
                        page++;
                    }

                    // Combine and sort allData across all pages
                    const combinedData = allData.reduce((accumulator, data) => {
                        return accumulator.concat(data);
                    }, []);


                    combinedData.sort((a: any, b: any) => {
                        const buildingA = a.attributes.project_building.toLowerCase();
                        const buildingB = b.attributes.project_building.toLowerCase();

                        if (buildingA < buildingB) {
                            return -1;
                        } else if (buildingA > buildingB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    setBuildingListData(combinedData);



                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const ItemData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-items?populate=*&filters[material_category][id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const materialdata: MaterialListArr[] = [];
                    response.data['data'].forEach((list: any) => {
                        if (list.attributes.workspace.data == null) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                        else if (list.attributes.workspace.data.id == workspaceId) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                    })
                    if (materialdata.length == 1 && materialdata[0].material_item_name == location.categoryName) {
                        setMaterialUnitId(materialdata[0].material_unitId)
                        setMaterialUnit(materialdata[0].material_unit)
                    }
                    setMaterialListData(materialdata)

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const BrandData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-brands?populate=*&filters[material_category][id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const branddata: BrandListArr[] = [];
                    response.data['data'].forEach((list: any) => {
                        if (list.attributes.workspace.data == null) {
                            branddata.push({
                                "id": list.id, "material_brand_name": list.attributes.material_brand_name
                            })
                        }
                        else if (list.attributes.workspace.data.id == workspaceId) {
                            branddata.push({
                                "id": list.id, "material_brand_name": list.attributes.material_brand_name
                            })
                        }
                    })
                    setBrandListData(branddata);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const MaterialData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-purposes?populate=*&filters[material_category][material_parent_id][$eq]=' + parentId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    setPurposeListData(response.data['data']);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }
            setValue('material', '');
            setValue('brand', '');
            setValue('quantity', '');
            setValue('Notes', '');
            setValue('purpose', '');
            setValue('building', '');
            setValue('wing', '');
            setValue('flat', '');
            setCategoryUrl(location.categoryUrl);
            BuildingData();
            ItemData();
            BrandData();
            MaterialData();
        }
    }, [locations]);
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    //Function for changing the value of buliding and flat

    const onChange = async (data: any, data2: string) => {

        if (data2 == "material") {
            materialListData.filter(list => {
                if (list.id == data) {
                    setMaterialUnitId(list.material_unitId)
                    setMaterialUnit(list.material_unit);
                    return list
                }
            })
        }
        if (data2 == "building" || data2 == "wing") {
            const uniqueValuesOfWings = new Set();
            //For the unique values of wings
            buildingListData.forEach((item: any) => {
                if (getValues('building') == item.attributes.project_building) {
                    uniqueValuesOfWings.add(item.attributes.project_wing)
                    const myArray = Array.from(uniqueValuesOfWings);
                    setnewWings(myArray)
                }
            }

            )
            //For the floors

            if (getValues('building') && getValues('wing')) {
                const filteredFlatNos = buildingListData.filter((item: any) =>

                    getValues('building') == item.attributes.project_building && getValues('wing') == item.attributes.project_wing)
                filteredFlatNos.sort((a: any, b: any) => a.attributes.project_flatno.localeCompare(b.attributes.project_flatno))

                setFlatNos(filteredFlatNos);
            }
            try {
                const response = await axios({
                    url: ENV.ENDPOINT + '/project-building-infos?populate=*&filters[project_id][id][$eq]=' + projectId,
                    method: 'get',
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userId,
                        token: token,
                    },
                })

                const responseData = response.data;
                return responseData;

            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }

        }
        if (data2 == 'purpose') {
            purposeListData.map(list => {
                if (list.id == data) {
                    if (list.attributes.material_purpose_enable == false) {
                        SetOutwardPurposeEnable(false);
                    } else {
                        SetOutwardPurposeEnable(true);
                    }
                }
            })
        }
    }
    //passing the props

    const props = {
        doNothing: doNothing,
        backPage: backPage,
        projectName: projectName,
        categoryName: categoryName,
        methods: methods,
        handleSubmit: handleSubmit,
        onSubmit: onSubmit,
        materialListData: materialListData,
        materialItem: materialItem,
        errors: errors,
        onChange: onChange,
        setValue: setValue,
        control: control,
        materialUnit: materialUnit,
        showToast1: showToast1,
        setShowToast1: setShowToast1,
        colordata: colordata,
        message: message,
        brandListData: brandListData,
        purposeListData: purposeListData,
        outwardPurposeEnable: outwardPurposeEnable,
        setWings: setWings,
        buildingListData: buildingListData,
        wing: wing,
        setFlat: setFlat,
        newWings: newWings,
        flat: flat,
        flatNos: flatNos,
        isSubmitting:isSubmitting,
    };
    return (
        Outward(props)
    );
};


export default OutwardEntry;
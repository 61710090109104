// Importing necessary modules from the React and Ionic libraries
import React from "react";
import { IonImg, IonButton } from '@ionic/react';

// Defining a functional component called IonImgButton that accepts any props
const IonImgButton: React.FC<any> = (props) => {
    return (
        // Rendering an IonButton component from Ionic framework
        // The button's className, routerLink, and fill props are set based on the provided props
        // The button is set to have a clear fill style (transparent background)
        // The routerLink prop is used to specify the link the button should navigate to when clicked
        <IonButton className={props.buttonClassName} routerLink={props.routerLink} fill="clear">
            <IonImg className={props.className} src={props.src} />
        </IonButton>
    );
};
// Exporting the IonImgButton component to make it available for use in other parts of the application

export default IonImgButton;
// Importing necessary dependencies and styles
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonToast, } from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonImgButton from "../components/Button/IonImgButton";
import SubmitButton from "../components/Button/SubmitButton";
import Logout from "../components/Logout";
import Loader from "../components/Loader/Loader";
// Interface to define the type of the location state

interface LocationState {
   id: number;
   name: string;
}
// Defining the CreateNewWorkspace functional component

const CreateNewWorkspace: React.FC = () => {
   // State variables for managing toast messages

   const [showToast1, setShowToast1] = useState(false);
   const [message, setMessage] = useState('');
   const [colordata, setColordata] = useState('');
   const locations = useLocation();
   // Using react-hook-form useForm to manage form state and validation
   const methods = useForm();
   const { handleSubmit, setValue, control, formState: { errors } } = methods;
   const [isloading, setIsloading] = useState(false);
   // Using useHistory hook from react-router-dom to manage navigation
   let history = useHistory();
   // Function to navigate back to the previous page
   const pageBack = () => {
      // history.go(-1)
      history.push({ pathname: "/select-workspace" });

   };
   // useEffect hook to handle setting initial form values from the location state
   React.useEffect(() => {
      let location: any;
      if (locations.state) {
         location = locations.state as LocationState;
         localStorage.setItem('routeState', JSON.stringify(location))
      } else {
         location = localStorage.getItem('routeState')
         if (location) location = JSON.parse(location)
      }
      if (location) {
         if (location.status != "editWorkspace") {
            setValue('organizationName', '')
         }
      }
   }, [locations]);
   let userRoleName = "";
   switch (localStorage.getItem('user_roles')) {
      case "builder":
         userRoleName = "builder";
         break;
      case "manager":
         userRoleName = "manager";
         break;
      case "site":
         userRoleName = "site";
         break;
      case "supervisor":
         userRoleName = "supervisor";
         break;
      case "stock":
         userRoleName = "stock";
         break;

      default:
         userRoleName = "stock"
         break;
   }
   // Function to handle form submission

   const onSubmit = async (data: any) => {
      setIsloading(true);
      const userid: any = localStorage.getItem('userid');
      const token: any = localStorage.getItem('token');
      try {
         const responseWorkspace = await axios({
            method: 'POST',
            url: ENV.ENDPOINT + '/workspaces',
            data: {
               "data": {
                  workspace_name: data.organizationName,
                  owner_id: userid
               }
            },
            headers: {
               Authorization: HeaderAuthorization.Authorization,
               userId: userid,
               token: token,
            },
         })
         if (responseWorkspace) {
            // Show success toast and navigate to the create project page
            setIsloading(false);
            setColordata("success");
            setMessage("Workspace Created successfully");
            setShowToast1(true)
            const workspace_name = responseWorkspace.data.data.attributes.workspace_name;
            const workspace_id = responseWorkspace.data.data.id;
            try {
               const userWorkspaceResponse = await axios({
                  method: 'POST',
                  url: ENV.ENDPOINT + '/user-workspaces',
                  data: {
                     "data": {
                        workspace_id: responseWorkspace.data.data.id,
                        user_status: "Active",
                        user_id: userid,
                        owner: true,
                        user_role: userRoleName
                     }
                  },
                  headers: {
                     Authorization: HeaderAuthorization.Authorization,
                     userId: userid,
                     token: token,
                  },
               })
               if (userWorkspaceResponse) {
                  // Set workspaceId and user roles in local storage and navigate to the create project page

                  setColordata("success");
                  setMessage("Workspace Created successfully");
                  setShowToast1(true)
                  localStorage.setItem('workspaceId', workspace_id)


                  const roleFromBackend = userRoleName
                  if (roleFromBackend.includes('Plus')) {
                     const finalRole = roleFromBackend.replace("Plus", "");
                     localStorage.setItem('work_roles', finalRole);
                     localStorage.setItem('edit_permision', "true");
                  } else {
                     localStorage.setItem('work_roles', roleFromBackend);
                     localStorage.setItem('edit_permision', "false");
                  }
                  setTimeout(() => {
                     history.go(-1)
                     history.push({
                        pathname: '/create-project',
                        state: { "workID": workspace_id, "workName": workspace_name }
                     }
                     );
                  }, 1000);
               }
            } catch (error: any) {
               console.log(error);
               if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                  Logout(history)
               }
            }

         }
      } catch (error: any) {
         console.log(error);
         setIsloading(false);
         if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
            Logout(history)
         }
      }

   }
   // JSX content of the CreateNewWorkspace component

   return (
      <IonPage>
         <IonContent fullscreen>
         {isloading && <Loader />}
            <IonGrid className="full-height-div">
               <IonRow className="login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="back-arrow">
                              <IonButton className="ion-text-right" fill="clear" onClick={pageBack}   >
                                 <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                              </IonButton>
                           </div>
                           <div className="main-logo">
                              <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />

                           </div>
                           <h4 className='ion-text-center'>Create your Workspace</h4>
                           <IonRow>
                              <IonCol size="12" className="ion-margin-top">
                                 <IonLabel className="form-lable">Oraganization Name:*</IonLabel>
                                 <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                       <IonInput
                                          type="text"
                                          onIonChange={(e: any) => {
                                             setValue('organizationName', e.detail.value)
                                             onChange(e.detail.value)
                                          }}
                                          onBlur={onBlur}
                                          value={value}
                                          className={`form-control ${errors.organizationName ? 'is-invalid' : ''}`}
                                          placeholder="Add text"
                                          mode="md" />
                                    )}
                                    control={control}
                                    name="organizationName"
                                    rules={{
                                       required: "Please enter Oraganization Name.",
                                       maxLength: {
                                          value: 80,
                                          message: "Oraganization Name should not exceed 80 character"
                                       },
                                       validate: {
                                          validName: (value) => {
                                              if (!value.trim()) {
                                                  return "Enter a valid name";
                                              }
                                              if (!/^[a-zA-Z ]*$/.test(value)) {
                                                  return "Enter a valid name";
                                              }
                                              return true;
                                          }
                                      }

                                    }} />
                                 <ErrorMessage
                                    errors={errors}
                                    name="organizationName"
                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                              </IonCol>
                              <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Create Workspace" />
                           </IonRow>
                        </form>
                     </FormProvider>
                  </IonCol>
                  {/* Toast to display success or error message */}

                  <IonToast
                     isOpen={showToast1}
                     onDidDismiss={() => setShowToast1(false)}
                     message={message}
                     duration={1500}
                     position="middle"
                     color={colordata} />
               </IonRow>
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};

// Exporting the CreateNewWorkspace component as the default export
export default CreateNewWorkspace;
// Importing necessary dependencies and styles
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonSelect, IonSelectOption, IonAlert, IonToast, IonItem, IonCheckbox, useIonAlert, } from '@ionic/react';
import { locationSharp, calendar, removeCircleSharp, addCircleSharp } from 'ionicons/icons';
import Header from "../components/Header/Header";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import DatePicker from 'react-date-picker';
import SubmitButton from "../components/Button/SubmitButton";
import Logout from "../components/Logout";
import Loader from "../components/Loader/Loader";

// Interface to define the type of the LoactionState
interface LocationState {
    id: number;
    name: string;
    status: string;
    workID: number;
    workName: string

}
// Interface to define the type of the BuildingState

interface BuildingData {
    project_id: number;
    project_building: string;
    project_wing: string;
    project_flatno: string
}

// Defining the CreateProject functional component

const CreateProject: React.FC = () => {
    const locations = useLocation();
    const [present] = useIonAlert();
    const [showModal, setShowModal] = useState(false);
    const [showToast1, setShowToast1] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const methods = useForm();
    let newdate = new Date();
    const { handleSubmit, setValue, control, formState: { errors } } = methods;
    const [popoverDate2, setPopoverDate2] = useState('');
    const [workspaceId, setWorkspaceId] = useState(0);
    const [workspaceName, setWorkspaceName] = useState('');
    const [statesData, setStateData] = useState([
        { id: 1, attributes: { "state_name": "state1" } },
        { id: 2, attributes: { "state_name": "state1" } },
    ]);
    const [districtData, setDistrictData] = useState([
        { id: 1, attributes: { "district_name": "district1" } },
        { id: 2, attributes: { "district_name": "district2" } },
    ]);
    const [cityData, setCityData] = useState([
        { id: 1, attributes: { "city_name": "city1" } },
        { id: 2, attributes: { "city_name": "city2" } },
    ]);
    const [projectData, setProjectData] = useState(
        {
            id: 1, attributes: {
                "project_name": "", "project_start_date": "", "state_id": { "data": { "id": 1, "attributes": { "state_name": "" } } }, "district_id": { "data": { "id": 1, "attributes": { "district_name": "" } } }, "city_id": { "data": { "id": 1, "attributes": { "city_name": "" } } },
                "project_building_infos": {
                    "data": [
                        { "attributes": { "project_building": "", "project_wing": "", "project_flatno": "" } }
                    ]
                }
            }
        }
    );
    const [date, setDate] = useState('');
    const [isState, setIsState] = useState(false);
    const [isDist, setIsDist] = useState(false);
    const [isCity, setIsCity] = useState(false);
    const [districts, setDistricts] = useState(false);
    const [cities, setCities] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    let userid: any = localStorage.getItem("userid");
    let token: any = localStorage.getItem("token");

    const [updateArr, setUpdateArr] = useState<any>([])

    let list: any = [];
    React.useEffect(() => {
        let location: any;
        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (location) {
            const defaultList = [
                { building: "", wing: "", flatNumber: "", floor: "", regex: "" }
            ];
            setValue('projectName', '')
            setValue('state', '')
            setValue('district', '')
            setValue('city', '')
            setValue('building[0]', '')
            setValue('wing[0]', '')
            setValue('flat[0]', '')
            setPopoverDate2('')
            setValue('date', '');
            const list = [...defaultList];
            setInputList(list);
            setWorkspaceId(location.workID)
            setWorkspaceName(location.workName)
        }
    }, [locations]);
    var refresh = window.localStorage.getItem('refresh');
    if (refresh == null) {
        window.location.reload();
        window.localStorage.setItem('refresh', "1");
    }
    React.useEffect(() => {
        const getStates = async () => {
            try {
                const statesResponse = await axios({
                    url: ENV.ENDPOINT + '/states',
                    method: 'get',
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userid,
                        token: token,
                    },
                })
                if (statesResponse) {
                    setStateData(statesResponse.data['data']);
                    setIsState(true);
                }
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        };
        getStates();
    }, []);

    const formatDate = (value: string) => {
        var date1 = new Date(value),
            mnth = ("0" + (date1.getMonth() + 1)).slice(-2),
            day = ("0" + date1.getDate()).slice(-2);
        setDate([date1.getFullYear(), mnth, day].join("-"))
        return [day, mnth, date1.getFullYear()].join("-");
    };
    const editWorkspace = () => {
        history.push({
            pathname: '/edit-workspace',
            state: { "id": workspaceId, "name": workspaceName, "status": "editWorkspace" }
        }
        );
    };
    let history = useHistory();
    const pageBack = () => {
        // history.go(-1)
        history.push({ pathname: "/select-project/" + workspaceId });
    };
    const onSubmit = async (data: any) => {
      setIsloading(true);
        const buildingInfo: BuildingData[] = []
        try {
            const projectResponse = await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/projects',
                data: {
                    "data": {
                        project_name: data.projectName,
                        state_id: data.state,
                        district_id: data.district,
                        city_id: data.city,
                        project_start_date: date,
                        workspace_id: workspaceId,
                        project_status: "Active"
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            if (projectResponse) {
                fixcomponent.map((data: any) => {
                    buildingInfo.push({ project_id: projectResponse.data.data.id, project_building: data.building, project_wing: data.wing, project_flatno: data.flatNumber })
                })
                try {
                    await axios({
                        method: 'POST',
                        url: ENV.ENDPOINT + '/project-building-infos/create-many',
                        data: { "data": buildingInfo },
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    });
                } catch (error: any) {
                    console.log(error);
                    setIsloading(false);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
                setIsloading(false);
                setColordata("success");
                setMessage("Project Created Successfully");
                setShowToast1(true)
                setTimeout(() => {
                    history.go(-1)
                    history.push({ pathname: "/select-project/" + workspaceId });
                }, 1000);
            }
        } catch (error: any) {
            console.log(error);
            setIsloading(false);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    };
    const onChange = async (data: any, data2: string) => {
        try {
            switch (data2) {
                case "state":
                    if (data != '' && data != null) {
                        setDistricts(true)
                    }
                    setCities(false)
                    setValue('district', '')
                    setValue('city', '')
                    const stateId = data;

                    const districtResponse = await axios({
                        url: ENV.ENDPOINT + '/districts?populate=[states]&filters[state_name][id][$eq]=' + stateId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    if (districtResponse) {
                        setDistrictData(districtResponse.data['data']);
                        return districtResponse.data;
                    }
                    break;
                case "district":
                    if (data != '' && data != null) {
                        setCities(true)
                    }
                    setValue('city', '')
                    const districtId = data;
                    const cityResponse = await axios({
                        url: ENV.ENDPOINT + '/cities?populate=[districts]&filters[district_name][id][$eq]=' + districtId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    if (cityResponse) {
                        setCityData(cityResponse.data['data']);
                        return cityResponse.data;
                    }
                    break;
                case "checkdata":
                    if (isChecked == true) {
                        setIsChecked(false);
                    } else {
                        setIsChecked(true);
                    }
                    break;

                default:
                    break;
            }
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440) {
                Logout(history)
            }
        }
    }
    const doNothing = () => {
        history.goBack();
    }
    const defaultList = [
        { building: "", wing: "", flatNumber: "", floor: "", regex: "" }
    ];
    const [building, setBuilding] = useState<any>([]);
    const [wing, setWing] = useState<any>([]);
    const [floorNo, setFloorNo] = useState<any>(null);
    const [aptRegPattern, setAptRegPattern] = useState<any>(null);
    const [isInputVisible, setIsInputVisible] = useState(true);
    const [inputList, setInputList] = useState(defaultList);
    const updateRemoveClick = (index: any) => {
        const filteredData = updateArr.filter((item: any) =>
            item !== index
        )
        setUpdateArr(filteredData)
    }
    const newhandleRemoveClick = (index: any) => {
        const filteredData = fixcomponent.filter((item: any) =>
            item !== index
        )
        setfixcomponent(filteredData)
    }
    const handleRemoveClick = (index: any, value: any) => {
        if (value === "edit") {
            setInputList([])
        }
        else {
            setInputList([{ building: "", wing: "", flatNumber: "", floor: "", regex: "" }]);
        }
    };
    const handleAddClick = () => {
        setIsInputVisible(true)
        setValue('building[0]', '');
        setValue('wing[0]', '');
        setValue('floor', '')
        setValue('regex', '')
        setInputList([...inputList, { building: "", wing: "", flatNumber: "", floor: "", regex: "" }]);
    };
    const newhandleAddClick = () => {
        setIsInputVisible(true)
        setValue('building[0]', '');
        setValue('wing[0]', '');
        setValue('floor', '')
        setValue('regex', '')
        setInputList([...inputList, { building: "", wing: "", flatNumber: "", floor: "", regex: "" }]);
    }
    let list_1: any = []
    const [fixcomponent, setfixcomponent] = useState<any>([])
    const generateOutput = () => {
        const buildingDataArr = building;
        const wingDataArr = wing;
        const floorNumber = floorNo;
        const apartmentNumbers = []
        const apartmentPattern = aptRegPattern;
        const [start, end] = apartmentPattern.split('-').map(String);

        if (!building.length || !wing.length || !floorNo.length || !aptRegPattern.length) {
            // You can display an error message or take any other action here
            
            return; // Exit the function early
        }
    
        for (let floor = 1; floor <= floorNumber; floor++) {
            for (let apt = start; apt <= end; apt++) {
                const apartment = floor.toString() + apt.toString().substring(1);
                apartmentNumbers.push(apartment)

                const newEntry:any = { "building": buildingDataArr[0], "wing": wingDataArr[0], "flatNumber": apartment };

                // Check if the new entry already exists in fixcomponent

                const entryExists:boolean = fixcomponent.some((item:any) =>
                    item.building === newEntry.building &&
                    item.wing === newEntry.wing &&
                    item.flatNumber === newEntry.flatNumber
                );

                // If the entry doesn't exist, push it to list_1
                
                if (!entryExists) {
                    
                    list_1.push(newEntry);
                }
         
            }
        }
        if (fixcomponent.length > 0 && fixcomponent) {
            setfixcomponent([...fixcomponent, ...list_1])
            setInputList([])
        }
        else {
            setfixcomponent([...list_1])
            setInputList([])
        }
    };
    const renderList = () => {
        return inputList.map((x, i) => {
            return (
                <div key={i} className="building-info">
                    <IonRow>
                        <IonCol size="4">
                            <IonLabel className="form-lable">Building*</IonLabel>
                            <Controller
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <IonInput
                                        type="text"
                                        onIonChange={(e: any) => {
                                            setBuilding(e.detail.value)
                                            onChange(e.detail.value)
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        className={`form-control ${errors.building ? 'is-invalid' : ''}`}
                                        placeholder=""
                                        mode="md" 
                                        maxlength={1} />
                                )}
                                control={control}
                                name={'building[' + i + ']'}
                                rules={{
                                    required: "Please enter building number.", 
                                    validate: {
                                        validName: (value) => {
                                            
                                            if (!value.trim()) {
                                                return "Enter a valid building info";
                                            }                                     
                                            return true;
                                        }
                                    }
                                }} />
                            <ErrorMessage
                                errors={errors}
                                name={'building[' + i + ']'}
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="4">
                            <IonLabel className="form-lable">Wing*</IonLabel>
                            <Controller
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <IonInput
                                        type="text"
                                        onIonChange={(e: any) => {
                                            setWing(e.detail.value)
                                            onChange(e.detail.value)
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        className={`form-control ${errors.wing ? 'is-invalid' : ''}`}
                                        placeholder=""
                                        mode="md" 
                                        maxlength={1} />
                                )}
                                control={control}
                                name={'wing[' + i + ']'}
                                rules={{
                                    required: "Please enter wing number.",
                                    validate: {
                                        validName: (value) => {
                                            if (!value.trim()) {
                                                return "Enter a valid building info";
                                            }                                     
                                            return true;
                                        }
                                    }
                                }} />
                            <ErrorMessage
                                errors={errors}
                                name={'wing[' + i + ']'}
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        {
                            !isInputVisible && <>
                                <IonCol size="3">
                                    <IonLabel className="form-lable">Flat No*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                type="text"
                                                onIonChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={`form-control ${errors.flat ? 'is-invalid' : ''}`}
                                                placeholder=""
                                                mode="md" 
                                                />
                                        )}
                                        control={control}
                                        name={'flat[' + i + ']'}
                                        rules={{
                                            required: "Please enter flat number."
                                        }} />
                                    <ErrorMessage
                                        errors={errors}
                                        name={'flat[' + i + ']'}
                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                </IonCol>
                            </>
                        }
                        {
                            isInputVisible &&
                            <>
                                <IonCol size="3">
                                    <IonLabel className="form-lable">Floor No*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                onKeyDown={ (evt) => (evt.key === 'e' && evt.preventDefault()) || (evt.key === '.' && evt.preventDefault()) }
                                                type="number"
                                                placeholder="Floors No"
                                                onIonChange={(e: any) => {
                                                    setFloorNo(e.detail.value)
                                                    onChange(e.detail.value)
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                className={`form-control ${errors.floor ? 'is-invalid' : ''}`}

                                                mode="md" />
                                        )}
                                        control={control}
                                        name={'floor'}
                                        rules={{
                                            required: "Please enter floor number."
                                        }} />
                                    <ErrorMessage
                                        errors={errors}
                                        name={'floor'}
                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                </IonCol>
                                <IonCol size="4">
                                    <IonLabel className="form-lable">Range Patterns*</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <IonInput
                                                type="text"
                                                onIonChange={(e: any) => {
                                                    setAptRegPattern(e.detail.value)
                                                    onChange(e.detail.value)

                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                className={`form-control ${errors.regex ? 'is-invalid' : ''}`}
                                                placeholder="Ex.101-103"
                                                mode="md" />
                                        )}
                                        control={control}
                                        name={'regex'}
                                        rules={{
                                            required: "Please enter range number.",
                                            pattern: {

                                                value: /^\d{3}-\d{3}$/,
                                                message: "Invalid range pattern"
                                            }
                                        }} />
                                    <ErrorMessage
                                        errors={errors}
                                        name={'regex'}
                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                </IonCol>
                                <IonCol size="4">
                                    <IonButton className="generate-btn" onClick={generateOutput}>Generate</IonButton>
                                </IonCol>

                                <IonCol size="1">
                                    <IonButton fill="clear" onClick={(x) => {
                                        present({
                                            cssClass: 'red-alert',
                                            header: 'Remove Building Info',
                                            message: '<p>Are you sure you want to remove this building info?</p>',
                                            buttons: [
                                                'Cancel',
                                                {
                                                    text: 'Ok',
                                                    handler: () => {
                                                        handleRemoveClick(i, "create");

                                                    }
                                                },
                                            ],
                                            onDidDismiss: (e) => console.log(''),
                                        })
                                    }
                                    }>
                                        <IonIcon icon={removeCircleSharp}></IonIcon>
                                    </IonButton>
                                </IonCol>
                            </>
                        }
                    </IonRow>
                </div>
            );
        })
    }
    const editData = (data: any, index: number, data2: string) => {
        const latestUpdate = updateArr
        if (data2) {
            latestUpdate[index][`${data2}`] = data
            setUpdateArr(latestUpdate)
        }
    }
    useEffect(() => {
        updateArr.sort((a: any, b: any) => {
            const result = a.building.localeCompare(b.building);
            if (result !== 0) {
                return result;
            }
            const newResult = a.wing.localeCompare(b.wing)
            if (newResult !== 0) {
                return newResult
            }
            return a.flatNumber - b.flatNumber;
        });
    }, [updateArr])

    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
            {isloading && <Loader />}
                <IonGrid className="full-height-div">
                    <IonRow className="login-form-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton className="ion-text-right" fill="clear" onClick={pageBack}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h6>Select Project of</h6>
                                    <h3><b>{workspaceName}</b></h3>
                                </div>
                                <IonButton className="edit-project-button ion-text-right" fill="clear" onClick={editWorkspace}>
                                    <IonIcon icon="/assets/images/edit-icon.svg" ></IonIcon>
                                </IonButton>
                            </div>
                            {isState && isDist && isCity && locations ? (
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <IonRow>
                                            {(projectData.attributes.project_name && projectData.attributes.project_name != "project1") ? (
                                                <IonCol size="12" className="ion-margin-top">
                                                    <IonLabel className="form-lable" ><b>Project Name*</b></IonLabel>
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e: any) => {
                                                                    setValue('projectName', e.detail.value)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value == null ? projectData.attributes.project_name : value}
                                                                className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                                                placeholder="Add text"
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="projectName"
                                                        rules={{
                                                            required: "Please enter Project Name",
                                                            maxLength: {
                                                                value: 80,
                                                                message: "Project name should not exceed 80 character."
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-Z ]*$/i,
                                                                message: "Enter a valid name",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="projectName"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>) : (<IonCol size="12" className="ion-margin-top">
                                                    <IonLabel className="form-lable"><b>Project Name*</b></IonLabel>
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e: any) => {
                                                                    setValue('projectName', e.detail.value)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                                                placeholder="Add text"
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="projectName"
                                                        rules={{
                                                            required: "Please enter Project Name",
                                                            maxLength: {
                                                                value: 80,
                                                                message: "Project name should not exceed 80 character."
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-Z ]*$/i,
                                                                message: "Enter a valid name",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="projectName"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>)}
                                            <IonCol size="12" className="ion-margin-top">
                                                <h3 className="form-sub-title">
                                                    Location
                                                    <IonIcon icon={locationSharp} ></IonIcon>
                                                </h3>
                                                <IonLabel className="form-lable">State*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            placeholder="Select One"
                                                            value={field.value == null ? projectData.attributes.state_id.data.id : field.value}
                                                            className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setValue('state', e.detail.value)
                                                                onChange(e.detail.value, "state")
                                                            }} >
                                                            {statesData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.state_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable">District*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            placeholder="Select One"
                                                            value={field.value == null ? projectData.attributes.district_id.data.id : field.value}
                                                            className={`form-control ${errors.district ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setValue('district', e.detail.value)
                                                                onChange(e.detail.value, "district")
                                                            }}  >
                                                            {districtData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.district_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="district"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="district"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable">City*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            placeholder="Select One"
                                                            value={field.value == null ? projectData.attributes.city_id.data.id : field.value}
                                                            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setValue('city', e.detail.value)
                                                            }}  >
                                                            {cityData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.city_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="city"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable"><h4><b>Start Date*</b></h4></IonLabel>
                                                <div className="date-picker">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonInput
                                                                id="date-input-2"
                                                                onIonChange={onChange}
                                                                onBlur={onBlur}
                                                                value={popoverDate2}
                                                                className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="date"
                                                        rules={{
                                                            required: "Please select date."
                                                        }} />
                                                    <IonButton fill="clear" id="open-modal" onClick={() => setShowModal(true)}>
                                                        <IonIcon icon={calendar} />
                                                    </IonButton>
                                                    <IonModal className="date-picker-popup" isOpen={showModal}>
                                                        <IonContent>
                                                            <DatePicker format="y-MM-dd" onChange={(e: any) => { setPopoverDate2(formatDate(e)); setShowModal(false) }} maxDate={newdate} calendarIcon={null} clearIcon={null} isOpen={true} autoFocus={true} />
                                                        </IonContent>
                                                    </IonModal>
                                                </div>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="date"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <h3 className="form-sub-title">
                                                    Building info
                                                    <IonIcon slot="icon-only" src="/assets/images/city.svg" ></IonIcon>
                                                </h3>
                                                {
                                                    updateArr.map((i: any, index: number) => {
                                                        return (
                                                            <div key={index} className="building-info">
                                                                <IonRow>
                                                                    <IonCol size="4">
                                                                        <IonLabel className="form-lable">Buliding</IonLabel>

                                                                        <IonInput
                                                                            type="text"
                                                                            value={i.building}
                                                                            onIonChange={(e) => {
                                                                                editData(e.detail.value, index, "building")
                                                                            }}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md"
                                                                            maxlength={1}  />
                                                                    </IonCol>
                                                                    <IonCol size="4">
                                                                        <IonLabel className="form-lable">Wing</IonLabel>
                                                                        <IonInput
                                                                            type="text"
                                                                            value={i?.wing}
                                                                            onIonChange={(e) => {
                                                                                editData(e.detail.value, index, "wing")
                                                                            }}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md"
                                                                            maxlength={1} />
                                                                    </IonCol>
                                                                    <IonCol size="3">
                                                                        <IonLabel className="form-lable">Flat No</IonLabel>
                                                                        <IonInput
                                                                            type="text"
                                                                            value={i?.flatNumber}
                                                                            onIonChange={(e) => {
                                                                                editData(e.detail.value, index, "flatNumber")
                                                                            }}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md" />
                                                                    </IonCol>
                                                                    <IonCol size="1">
                                                                        <IonButton key={index} fill="clear" onClick={() => {
                                                                            present({
                                                                                cssClass: 'red-alert',
                                                                                header: 'Remove Building Info',
                                                                                message: '<p>Are you sure you want to remove this building info?</p>',
                                                                                buttons: [
                                                                                    'Cancel',
                                                                                    {
                                                                                        text: 'Ok',
                                                                                        handler: () => {
                                                                                            updateRemoveClick(i);
                                                                                        }
                                                                                    },
                                                                                ],
                                                                                onDidDismiss: (e) => console.log(e),
                                                                            })
                                                                        }
                                                                        }>
                                                                            <IonIcon icon={removeCircleSharp}>
                                                                            </IonIcon>
                                                                        </IonButton>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="add-more-building-info ion-text-center">
                                                    <IonButton className="secondary-button" fill="solid" onClick={newhandleAddClick}>
                                                        <IonIcon icon={addCircleSharp} />
                                                        <span className="">Add More</span>
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                            <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Create Project" />
                                        </IonRow>
                                    </form>
                                </FormProvider>) : (
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <IonRow>
                                            {(projectData.attributes.project_name && projectData.attributes.project_name != "project1") ? (
                                                <IonCol size="12" className="ion-margin-top">
                                                    <IonLabel className="form-lable"><b>Project Name*</b></IonLabel>
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e: any) => {
                                                                    setValue('projectName', e.detail.value)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value == null ? projectData.attributes.project_name : value}
                                                                className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                                                placeholder="Add text"
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="projectName"
                                                        rules={{
                                                            required: "Please enter Project Name",
                                                            maxLength: {
                                                                value: 80,
                                                                message: "Project name should not exceed 80 character."
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-Z ]*$/i,
                                                                message: "Enter a valid name",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="projectName"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>) : (<IonCol size="12" className="ion-margin-top">
                                                    <IonLabel className="form-lable"><b>Project Name*</b></IonLabel>
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e: any) => {
                                                                    setValue('projectName', e.detail.value)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                                                placeholder="Add text"
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="projectName"
                                                        rules={{
                                                            required: "Please enter Project Name",
                                                            maxLength: {
                                                                value: 80,
                                                                message: "Project name should not exceed 80 character."
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-Z ]*$/i,
                                                                message: "Enter a valid name",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="projectName"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>)}
                                            <IonCol size="12" className="ion-margin-top">
                                                <h3 className="form-sub-title">
                                                    Location
                                                    <IonIcon icon={locationSharp} ></IonIcon>
                                                </h3>
                                                <IonLabel className="form-lable">State*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setValue('state', e.detail.value)
                                                                onChange(e.detail.value, "state")
                                                            }}  >
                                                            {statesData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.state_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable">District*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            disabled={districts ? false : true}
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.district ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {

                                                                setValue('district', e.detail.value)
                                                                onChange(e.detail.value, "district")
                                                            }} >
                                                            {districtData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.district_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="district"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="district"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable">City*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            disabled={cities ? false : true}
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setValue('city', e.detail.value)
                                                            }}  >
                                                            {cityData.map((item) => (
                                                                <IonSelectOption key={item.id} value={item.id}>
                                                                    {item.attributes.city_name}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name="city"
                                                    rules={{ required: 'This is a required field' }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="12" className="ion-margin-top">
                                                <IonLabel className="form-lable"><h4><b>Start Date*</b></h4></IonLabel>
                                                <div className="date-picker">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonInput
                                                                id="date-input-2"
                                                                onIonChange={onChange}
                                                                onBlur={onBlur}
                                                                value={popoverDate2}
                                                                className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="date"
                                                        rules={{
                                                            required: "Please select date."
                                                        }} />
                                                    <IonButton fill="clear" id="open-modal" onClick={() => setShowModal(true)}>
                                                        <IonIcon icon={calendar} />
                                                    </IonButton>
                                                    <IonModal className="date-picker-popup" isOpen={showModal}>
                                                        <IonContent>
                                                            <DatePicker format="y-MM-dd" onChange={(e: any) => { setPopoverDate2(formatDate(e)); setShowModal(false) }} maxDate={newdate} calendarIcon={null} clearIcon={null} isOpen={true} autoFocus={true} />
                                                        </IonContent>
                                                    </IonModal>
                                                </div>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="date"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>

                                            <IonCol size="12" className="ion-margin-top">
                                                <h3 className="form-sub-title">
                                                    Building info
                                                    <IonIcon slot="icon-only" src="/assets/images/city.svg" ></IonIcon>
                                                </h3>
                                                {
                                                    fixcomponent.length > 0 && fixcomponent.map((i: any, index: number) => {
                                                        return (
                                                            <div key={index} className="building-info">
                                                                <IonRow>
                                                                    <IonCol size="4">
                                                                        <IonLabel className="form-lable">Buliding</IonLabel>

                                                                        <IonInput
                                                                            type="text"
                                                                            value={i.building}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md" 
                                                                            maxlength={1} // Setting maximum length to 1 character
                                                                           />
                                                                    </IonCol>
                                                                    <IonCol size="4">
                                                                        <IonLabel className="form-lable">Wing</IonLabel>
                                                                        <IonInput
                                                                            type="text"
                                                                            value={i.wing}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md"
                                                                            maxlength={1}  />
                                                                    </IonCol>
                                                                    <IonCol size="3">
                                                                        <IonLabel className="form-lable">Flat No</IonLabel>
                                                                        <IonInput
                                                                            type="text"
                                                                            value={i.flatNumber}
                                                                            className={`form-control`}
                                                                            placeholder=""
                                                                            mode="md" />
                                                                    </IonCol>
                                                                    <IonCol size="1">
                                                                        <IonButton key={index} fill="clear" onClick={() => {
                                                                            present({
                                                                                cssClass: 'red-alert',
                                                                                header: 'Remove Building Info',
                                                                                message: '<p>Are you sure you want to remove this building info?</p>',
                                                                                buttons: [
                                                                                    'Cancel',
                                                                                    {
                                                                                        text: 'Ok',
                                                                                        handler: () => {
                                                                                            newhandleRemoveClick(i);
                                                                                        }
                                                                                    },
                                                                                ],
                                                                                onDidDismiss: (e) => console.log(e),
                                                                            })
                                                                        }
                                                                        }>
                                                                            <IonIcon icon={removeCircleSharp}>
                                                                            </IonIcon>
                                                                        </IonButton>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {renderList()}
                                                {/* {renderfourList()} */}
                                                <div className="add-more-building-info ion-text-center">
                                                    <IonButton className="secondary-button" fill="solid" onClick={handleAddClick}>
                                                        <IonIcon icon={addCircleSharp} />
                                                        <span className="">Add More</span>
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                            <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Create Project" />
                                        </IonRow>
                                    </form>
                                </FormProvider>
                            )}
                        </IonCol>
                                          {/* Toast to display success or error message */}
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1500}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

// Exporting the CreateProject component as the default export
export default CreateProject;
// Import required modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonToast } from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";
// Define the interface for the location state
interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    materialItem: string;
    backpath?: string;
    inwardId: number;
    materialName: string;
    materialId: number;
    quantity: number;
}
// Create the main component CreateTruck
const CreateTruck: React.FC = () => {
    const locations = useLocation();
    const [backPath, setBackPath] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [inwardId, setInwardId] = useState(0);
    let history = useHistory();

    let userid: any = localStorage.getItem("userid");
    let token: any = localStorage.getItem("token");
    // Function to navigate back to the previous page
    const backPage = () => {
        // history.go(-1)
        history.push({
            pathname: categoryUrl,
            state: { "categoryName": categoryName, "categoryId": categoryId, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backPath, "inwardId": inwardId, "materialId": materialId, "materialName": materialName, "quantity": quantity }
        }
        );
    }
    // Function to navigate back to the previous page
    const methods = useForm();
    const { handleSubmit, setValue, control, formState: { errors } } = methods;
    // Function to handle form submission
    const onSubmit = async (data: any) => {
        if (data.truckWidthInch == '') {
            data.truckWidthInch = 0;
        }
        if (data.truckLengthInch == '') {
            data.truckLengthInch = 0;
        }
        if (data.truckHeightInch == '') {
            data.truckHeightInch = 0;
        }
        try {
            await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/material-trucks',
                data: {
                    "data": {
                        truck_no: data.truckno,
                        truck_width: data.truckWidthFeet + '.' + data.truckWidthInch,
                        truck_length: data.truckLengthFeet + '.' + data.truckLengthInch,
                        truck_height: data.truckHeightFeet + '.' + data.truckHeightInch,
                        workspace: workspaceId
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            setValue('truckno', '')
            setValue('truckWidth', '')
            setValue('truckLength', '')
            setValue('truckHeight', '')
            setColordata("success");
            setMessage("Truck added Successfully");
            setShowToast1(true)
            setTimeout(() => {
                history.goBack();
            }, 1000);
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    };
    // Function to navigate back to the previous page without submitting the form
    const doNothing = () => {
        history.goBack();
    }
    // Fetching the location state and setting the state variables accordingly
    React.useEffect(() => {
        if (locations.state) {
            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setMaterialItem(location.materialItem)
            setCategoryUrl(location.categoryUrl);
            setInwardId(location.inwardId);
            setMaterialId(location.materialId);
            setMaterialName(location.materialName);
            setQuantity(location.quantity);
            if (location?.backpath) {

                setBackPath(location?.backpath);
            }
        }
    })
    // State variables for category details
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [materialItem, setMaterialItem] = useState('');

    const workspaceId = localStorage.getItem('workspaceId');
    const projectName = localStorage.getItem('projectName');
    // JSX for the component

    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Truck</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <FormProvider {...methods} >
                                <form className="material-add-form top-border-with-padding" onSubmit={handleSubmit(onSubmit)}>
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Truck no:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow className="material-select-row ion-align-items-center">
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type={"text"}
                                                                onIonChange={(e: any) => {
                                                                    setValue('truckno', e.detail.value)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckno ? 'is-invalid' : ''}`}
                                                                placeholder="Add text"
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckno"
                                                        rules={{ required: 'Please enter truck number' }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckno"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable two-line-label material-lable">Width:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckWidthFeet', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckWidthFeet ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckWidthFeet"
                                                        rules={{
                                                            required: "Please add truck width",
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }
                                                        } />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckWidthFeet"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Feet</p>
                                                </IonCol>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckWidthInch', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckWidthInch ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckWidthInch"
                                                        rules={{
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9 ]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckWidthInch"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Inch</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow><IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable two-line-label material-lable">Height:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckHeightFeet', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckHeightFeet ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckHeightFeet"
                                                        rules={{
                                                            required: "Please add  truck height",
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9 ]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckHeightFeet"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Feet</p>
                                                </IonCol>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckHeightInch', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckHeightInch ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckHeightInch"
                                                        rules={{
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9 ]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckHeightInch"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Inch</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow><IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable two-line-label material-lable">Length:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckLengthFeet', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckLengthFeet ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckLengthFeet"
                                                        rules={{
                                                            required: "Please add truck length",
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9 ]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckLengthFeet"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Feet</p>
                                                </IonCol>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => {
                                                                    setValue('truckLengthInch', e.detail.value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.truckLengthInch ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="truckLengthInch"
                                                        rules={{
                                                            maxLength: {
                                                                value: 2,
                                                                message: "Maximum length of number is 2"
                                                            },
                                                            pattern: {
                                                                value: /^[0-9 ]*$/i,
                                                                message: "Enter numbers",
                                                            }
                                                        }} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="truckLengthInch"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                                <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                    <p>Inch</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow className="">
                                        <IonCol size="3">
                                        </IonCol>
                                        <IonCol size="9" className="ion-text-end">
                                            <IonButton className="" type="submit" fill="solid" >
                                                Add
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </form>
                            </FormProvider>
                        </IonCol>
                        {/* Toast to display success or error message */}
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1500}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CreateTruck;
export const ENV = {
    // ENDPOINT : "http://143.244.141.29:1337/api",
    //  ENDPOINT : "http://127.0.0.1:1337/api",
    ENDPOINT : "https://stg-backend.constructionsitemanager.in/api",
    TOKEN : "Bearer 90c77a13ce146912217a9e074ee18de40295fd73760869b03512dd3ba6f14516d1b97e18959cf811598c50a9b082bd3d87649e2102c64181fd424a1ee739e94ecce0c00d3745a16535052a91fc3c08eb198ff653d879438d78e2eb15b7c799f209b42c47bbea3b181d53f6659180551b674c4d6a2f10f2e7d55d28528a791d0d",
    CURRENT_ENV : "dev",
    accessKeyId: 'DO00G82G6ZWM23H7HMKZ',
    secretAccessKey: 'PmR44Y7PeMZdTNUUvfvF79Sr26C1+oXZUwitugbeR7Y',
    WebsiteURL:"https://stg-frontend.constructionsitemanager.in"
}


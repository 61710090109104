// Import required modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonToast, } from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { toast } from '../toast';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonImgButton from "../components/Button/IonImgButton";
import SubmitButton from "../components/Button/SubmitButton";
import Logout from "../components/Logout";

// Define the interface for the location state
interface LocationState {
   id: number;
   name: string;
}

// Create the main component CreateNewWorkspace
const CreateNewWorkspace: React.FC = () => {
   // State variables and hooks
   const [showToast1, setShowToast1] = useState(false);
   const [message, setMessage] = useState('');
   const [colordata, setColordata] = useState('');
   const locations = useLocation();
   let userid: any = localStorage.getItem("userid");
   let token: any = localStorage.getItem("token");
   const methods = useForm();
   const { handleSubmit, setValue, control, formState: { errors } } = methods;
   let history = useHistory();
   const [workspaceId, setWorkspaceId] = useState(0);
   // Function to navigate back to the previous page

   const pageBack = () => {
      // history.go(-1)
      history.push({ pathname: "/select-workspace" });

   };
   // Fetching the location state and setting the state variables accordingly

   React.useEffect(() => {
      let location: any;
      if (locations.state) {
         location = locations.state as LocationState;
         localStorage.setItem('routeState', JSON.stringify(location))
      } else {
         location = localStorage.getItem('routeState')
         if (location) location = JSON.parse(location)
      }
      if (location) {
         setWorkspaceId(location.id);
         setValue('organizationName', location.name)
      }
   }, [locations]);
   // Getting the user role from local storage

   let userRoleName = "";
   switch (localStorage.getItem('user_roles')) {
      case "builder":
         userRoleName = "builder";
         break;
      case "manager":
         userRoleName = "manager";
         break;
      case "site":
         userRoleName = "site";
         break;
      case "supervisor":
         userRoleName = "supervisor";
         break;
      case "stock":
         userRoleName = "stock";
         break;

      default:
         userRoleName = "stock"
         break;
   }
   // Function to handle form submission

   const onSubmit = async (data: any) => {
      try {
         const workspaceResponse = await axios({
            method: 'PUT',
            url: ENV.ENDPOINT + '/workspaces/' + workspaceId,
            data: {
               "data": {
                  workspace_name: data.organizationName
               }
            },
            headers: {
               Authorization: HeaderAuthorization.Authorization,
               userId: userid,
               token: token,
            },
         })
         if (workspaceResponse) {
            const workspace_id = workspaceResponse.data.data.id;
            setColordata("success");
            setMessage("Workspace Updated successfully");
            setShowToast1(true)
            setTimeout(() => {
               setValue('organizationName', '')
               history.go(-1)
               history.push({ pathname: "/select-project/" + workspace_id });
            }, 1000);
         }
      }
      catch (error: any) {
         console.log(error);
         if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
            Logout(history)
         }
         toast("Workspace already exists.");
      }

   }

   //JSX Compomnent 
   return (
      <IonPage>
         <IonContent fullscreen>
            <IonGrid className="full-height-div">
               <IonRow className="login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="back-arrow">
                              <IonButton className="ion-text-right" fill="clear" onClick={pageBack}   >
                                 <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                              </IonButton>
                           </div>
                           <div className="main-logo">
                              <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />

                           </div>
                           <h4 className='ion-text-center'>Edit your Workspace</h4>
                           <IonRow>
                              <IonCol size="12" className="ion-margin-top">
                                 <IonLabel className="form-lable">Oraganization Name:*</IonLabel>
                                 <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                       <IonInput
                                          type="text"
                                          onIonChange={(e: any) => {
                                             setValue('organizationName', e.detail.value)
                                             onChange(e.detail.value)
                                          }}
                                          onBlur={onBlur}
                                          value={value}
                                          className={`form-control ${errors.organizationName ? 'is-invalid' : ''}`}
                                          placeholder="Add text"
                                          mode="md" />
                                    )}
                                    control={control}
                                    name="organizationName"
                                    rules={{
                                       required: "Please enter Oraganization Name.",
                                       maxLength: {
                                          value: 80,
                                          message: "Oraganization Name should not exceed 80 character"
                                       },
                                       pattern: {
                                          value: /^[a-zA-Z ]*$/i,
                                          message: "Enter a valid name",

                                       }
                                    }} />
                                 <ErrorMessage
                                    errors={errors}
                                    name="organizationName"
                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                              </IonCol>
                              <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Update Workspace" />
                           </IonRow>
                        </form>
                     </FormProvider>
                  </IonCol>
                  {/* Toast to display success or error message */}
                  <IonToast
                     isOpen={showToast1}
                     onDidDismiss={() => setShowToast1(false)}
                     message={message}
                     duration={1500}
                     position="middle"
                     color={colordata} />
               </IonRow>
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};

export default CreateNewWorkspace;
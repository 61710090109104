// Import necessary modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonList, IonItem, IonToast } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Loader from "../components/Loader/Loader";
import Logout from "../components/Logout";

// Define the interfaces for the data used in the component
interface LocationState {
    id: number;
    categoryId: number;
    categoryName: string;
    status: string;
    categoryURL: string;
    categoryTrace: object;
    parentCatId: number;
}
interface MaterialState {
    id: number;
    materialName: string;
    currentStock: number;
    materialUnitName: string;
    serialNo: number;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}

// Define the functional component for MaterialStock
const MaterialStock: React.FC = () => {
    // Get the user roles from local storage
    const user_roles = localStorage.getItem('work_roles');
    // Get the history object to navigate between routes
    let history = useHistory();
    // Get the current location using React Router's useLocation hook
    const locations = useLocation();
    // Define state variables for the component
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [checkMaterial, setCheckMaterial] = useState(false);
    const [materialStock, setMaterialStock] = useState([{
        id: 1, currentStock: 0, materialName: "", materialUnitName: "", serialNo: 0
    }]);
    const [categoryTrace, setCategoryTrace] = useState({});
    const [outwardEnable, setOutwardEnable] = useState(false);
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    const [parentCatId, setParentCatId] = useState(0);
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [subCategory, setSubCategory] = useState({});
    const [isloading, setIsloading] = useState(true);
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    const [buttonActive , setButtonActive] = useState(true)
    // Function to navigate back to the previous page
    const backPage = () => {
        // history.go(-1)
        var pushCategoryTrace = {} as pushState
        if (categoryTrace) {
            const categoryPush = categoryTrace as pushState
            pushCategoryTrace['categoryName'] = categoryPush['categoryName']
            pushCategoryTrace['categoryId'] = categoryPush['categoryId']
        }
        pushCategoryTrace["pushBack"] = true
        pushCategoryTrace["subCategory"] = subCategory;
        history.push({
            pathname: '/stock-category',
            state: pushCategoryTrace
        });
    }
    // Function to navigate to inward entry page
    const inwardEntryPage = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[id][$eq]=' + categoryId,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            let inwardScreen;
            const inwardScreenType = response.data['data'][0].attributes.inward_screen_type;
            switch (inwardScreenType) {
                case 'cement':
                    inwardScreen = '/inward-entry';
                    break;
                case 'sand':
                    inwardScreen = '/inward-entry-sand';
                    break;
                case 'brick':
                    inwardScreen = '/inward-entry-brick';
                    break;
                case 'accblock':
                    inwardScreen = '/inward-entry-rcc';
                    break;
                default:
                    inwardScreen = '/inward-entry';
                    break;
            }
            history.push({
                pathname: inwardScreen,
                state: { "backpath": "materialstock", "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialItem": response.data['data'][0].attributes.material_item, "parentCatId": parentCatId, "categoryUrl": inwardScreen }
            }
            );

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    }

    // Function to navigate to outward entry page
    const outwardEntryPage = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[id][$eq]=' + categoryId,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            history.push({
                pathname: '/outward-entry',
                state: { "backpath": "materialstock", "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialItem": response.data['data'][0]?.attributes?.material_item, "parentCatId": parentCatId }
            }
            );
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    }
    // Function that does nothing (placeholder for future use)
    const doNothing = () => {
    }
    // Fetch data from the API when the component mounts or the location changes
    React.useEffect(() => {
        let location: any;
        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (location) {
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setCategoryTrace(location.categoryTrace);
            setParentCatId(location.parentCatId);
            setSubCategory(location.subCategory)

            const InwardData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/inward-entries?populate=*&fields=inward_quantity&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const entries: MaterialState[] = [];
                    let serialCount = 0;
                    response.data['data'].forEach((list: any) => {
                        let index: any = ""

                        if (list?.attributes?.material_item.data != null) {

                            index = entries.findIndex(item => item.materialName == list.attributes.material_item.data.attributes.material_item_name)
                        }
                        else {
                            index = entries.findIndex(item => item.materialName)
                        }
                        if (index >= 0) {

                            entries[index].currentStock += list.attributes.inward_quantity
                        }
                        else {
                            if (list.attributes.material_item.data != null) {
                                entries.push({
                                    id: list.attributes.material_item.data.id, materialName: list.attributes.material_item.data.attributes.material_item_name,
                                    currentStock: list.attributes.inward_quantity, materialUnitName: list.attributes.material_unit.data.attributes.material_unit_name, serialNo: ++serialCount
                                })
                            }

                            else {
                                entries.push({
                                    id: list.id,
                                    materialName: list.attributes.material_category
                                        .data.attributes.material_category_name,
                                    currentStock: list.attributes.inward_quantity,
                                    materialUnitName: list.attributes.material_unit.data.attributes.material_unit_name, serialNo: ++serialCount
                                })
                            }

                        }
                    })
                    try {
                        const res = await axios({
                            url: ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userId,
                                token: token,
                            },
                        })
                        if (res.data['data'].length > 0) {
                            res.data['data'].forEach((list1: any) => {
                                let index = entries.findIndex(item => item.materialName == list1.attributes.material_item.data.attributes.material_item_name)
                                if (index >= 0) {
                                    entries[index].currentStock -= list1.attributes.outward_quantity
                                }
                                else {
                                    entries.push({
                                        id: list1.attributes.material_item.data.id, materialName: list1.attributes.material_item.data.attributes.material_item_name,
                                        currentStock: list1.attributes.outward_quantity, materialUnitName: list1.attributes.material_unit.data.attributes.material_unit_name, serialNo: ++serialCount
                                    })
                                }
                            })
                        }
                        const newentries = entries.sort((a: any, b: any) => a.serialNo > b.serialNo ? 1 : -1);
                        setCheckMaterial(true);
                        setMaterialStock(newentries)
                        setIsloading(false);

                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }


                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const CategoryData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];
                    while (true) {
                        const res = await axios({
                            url: ENV.ENDPOINT + '/material-categories?populate=*&filters[id][$eq]=' + location.categoryId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userId,
                                token: token,
                            },
                        })

                        const responseData = res.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }

                    if (allData[0].attributes.material_outward_enable == true) {
                        setOutwardEnable(true);
                    }
                    else {
                        setOutwardEnable(false);
                    }

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }
            InwardData();
            CategoryData();
        }
    }, [locations]);
    // Function to navigate to the stock report page for a specific material
    const stockReport = (data: any) => {
        let flag = 0
        if (data.materialName.includes("Sand")) {
            flag = 1
        }
        else {
            flag = 0
        }
        history.push({
            pathname: "/stock-report",
            state: {
                "categoryName": categoryName,
                "categoryId": categoryId,
                "status": "category",
                "materialName": data.materialName,
                "quantity": data.currentStock,
                "materialId": data.id,
                "categoryTrace": categoryTrace,
                "subCategory": subCategory,
                "flag": flag
            }
        }
        )
    }
    // Render the list of materials and the component JSX
    const renderCategoryList = () => {
        return materialStock.map((x, i) => {
            return (
                <IonItem key={i} className="category-button" onClick={() => stockReport(x)}>
                    <IonLabel>
                        {x.materialName}
                    </IonLabel>
                    <div className="current-stock">
                        <h5><b>{x.currentStock.toFixed(2)}</b></h5>
                        <p>{x.materialUnitName}</p>
                    </div>
                    <IonIcon icon="/assets/images/arrow-right-icon.svg" slot="end" />
                </IonItem>
            );
        })
    }
    // Function to generate and send CSV data
    const SavedcsvData = async () => {
        if(buttonActive){
            setButtonActive(false)

        let xy =
            `Title:${categoryName}`
        xy = xy + "\n" + `S.No,Stock, Quantity,Unit`
        materialStock.map(item => {
            xy = xy + "\n" + [item.serialNo, item.materialName, item.currentStock, item.materialUnitName]
            xy = xy
        })
        const blob = new Blob([xy], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (link.download != undefined) { // feature detection
            link.setAttribute('href', url);
            link.setAttribute('download', categoryName + '.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        try {
            await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/otps/createotp',
                data: {
                    "data": {
                        status: "csv",
                        name: categoryName,
                        email: localStorage.getItem('email'),
                        Genrate_csv: (xy),
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            setColordata("success")
            setMessage("Report sent to your email.Please check");
            setShowToast1(true)

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

        setTimeout(() => {
            setButtonActive(true);
          }, 5000);
    } 

    }

    //Render the JSX component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Stock</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <h2 className="category-name">{categoryName}</h2>
                            <div className="stock-buttons buttons-wrap">
                                <IonButton fill="clear" onClick={inwardEntryPage}>
                                    <IonIcon icon="/assets/images/inward-icon.svg" ></IonIcon>
                                </IonButton>
                                {(outwardEnable == true) ? (
                                    <IonButton fill="clear" onClick={outwardEntryPage}>
                                        <IonIcon icon="/assets/images/outward-icon.svg" >{outwardEnable}</IonIcon>
                                    </IonButton>) : ""}
                                {(user_roles != "stock") ? (
                                    <IonButton fill="clear" className="export-button" onClick={SavedcsvData} disabled={!buttonActive}>
                                        <IonIcon icon="/assets/images/export-icon.svg" ></IonIcon>
                                    </IonButton>) : ""}
                            </div>
                            {(checkMaterial) ? (
                                <IonList className="category-list">
                                    {renderCategoryList()}
                                </IonList>) : <IonList className="category-list"></IonList>}
                        </IonCol>
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1500}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
                {(isloading && <Loader />)}
            </IonContent>
        </IonPage>
    );
};

export default MaterialStock;
// Importing the IonButton component from the Ionic framework
import { IonButton } from "@ionic/react"

// Defining a functional component called LoadMoreButton that accepts any props
export const LoadMoreButton = (props: any) => {
    // Rendering an IonButton component from the Ionic framework
    // The button's size is set to "small"
    // The button's fill style is set to "outline"
    // The onClick prop is set to the provided props.nextPage function, which will be executed when the button is clicked
    // The className prop is conditionally set based on the value of props.isThereMoreData
    // If props.isThereMoreData is true, the "load-more-button" class will be applied; otherwise, the "load-more-button-disabled" class will be applied
    // The disabled prop is set to the negation of props.isThereMoreData, which disables the button if there is no more data available (props.isThereMoreData is false)
    return <IonButton size="small" fill="outline" onClick={props.nextPage} className={props.isThereMoreData ? "load-more-button" : "load-more-button-disabled"} disabled={!props.isThereMoreData}>
        {/* The content of the button is conditionally set based on the value of props.isThereMoreData */}
        {/* If props.isThereMoreData is true, the button text will be "Load More"; otherwise, it will be "No More Data Available" */}
        {props.isThereMoreData ? "Load More" : "No More Data Available"}</IonButton>
}
// Import necessary modules and components
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonButton, IonGrid, IonRow, IonCol, IonImg, IonIcon, IonList, IonItem, IonReorder, IonReorderGroup, IonInput } from '@ionic/react';
import Header from "../components/Header/Header";
import { ItemReorderEventDetail } from '@ionic/core';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { setOutwardHasMoreData } from "../features/outward/outwardSlice";
import { LoadMoreButton } from "../components/LoadMoreButton";
import Logout from "../components/Logout";
import { FormProvider, useForm } from "react-hook-form";
import SubmitButton from "../components/Button/SubmitButton";

// Define interfaces for type checking
interface CategoryData {
    id: number;
    categoryName: string;
    categoryURL: string;
    materialItem: string;
    flag?: boolean;
}
interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    pushBack: boolean;
    subCategory: any;
}
interface parentCategory {
    categoryName?: string;
    categoryId?: number;
}
const Outward: React.FC = () => {
    const hasMoreData = useSelector((state: any) => state.outward.outwardHasMoreData);
    const locations = useLocation();
    let history = useHistory();
    const [isThereMoreData, setIsThereMoreData] = useState(true);

    const pageBack = () => {
        history.goBack();
    };
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    //Function that handle add outward entry
    const addOutwardEntry = async (data: any) => {
        try {
            setPage(1);
            setSearchQuery('')
            setIsSearching(false)
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_outward_enable][$eq]=true&filters[material_parent_id][$eq]=' + data.id,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            if (response.data.meta.pagination.page === response.data.meta.pagination.pageCount) {
                setIsThereMoreData(false);
            }
            var parentCategoryId = {} as parentCategory;
            let parentCatId;
            if (subCategoryTrace.categoryTrace.length != 0) {
                parentCategoryId = subCategoryTrace.categoryTrace[0] as parentCategory

                parentCatId = parentCategoryId.categoryId;
            } else {
                parentCatId = data.id;
            }
            if (response.data.data.length == 0) {
                const subCategory = subCategoryTrace.categoryTrace;
                const subCategoryTraceValue = subCategoryTrace.categoryTrace
                const categoryTrace = subCategory[subCategory.length - 1]
                history.push({
                    pathname: data.categoryURL,
                    state: { "categoryName": data.categoryName, "categoryId": data.id, "status": "category", "categoryUrl": data.categoryURL, "subCategory": subCategoryTraceValue, "categoryTrace": categoryTrace, "materialItem": data.materialItem, "parentCatId": parentCatId }
                }
                );
            } else {
                dispatch(setOutwardHasMoreData(true))
                setPage(1)
                setAllData([])
                setIsThereMoreData(true);
                history.push({
                    pathname: '/outward',
                    state: { "categoryName": data.categoryName, "categoryId": data.id, "status": "category", "categoryUrl": data.categoryURL }
                }
                );
            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }


    }

    //Function for back button
    const doNothing = () => {
        history.goBack();
    }
    //Function for back page

    const backPage = () => {
        if (subCategoryTrace.categoryTrace.length == 0 || JSON.stringify(subCategoryTrace.categoryTrace) === JSON.stringify([{}])) {
            history.push({ pathname: '/dashboard/' + projectId })
        } else {
            setAllData([])
            setIsSearching(false);
            setSearchQuery('')
            setAllDataIn([])
            setFromBack(prev => !prev)
            var subCategoryTraceValue = subCategoryTrace.categoryTrace
            subCategoryTraceValue.splice(-1)
            setCategoryName('');
            var lastElement = subCategoryTraceValue.pop()

            setSubCategoryTrace({ categoryTrace: subCategoryTraceValue });
            setPage(1)
            setIsThereMoreData(true);
            dispatch(setOutwardHasMoreData(true))

            history.push({
                pathname: '/outward',
                state: lastElement
            });
        }
    };
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [isloading, setIsloading] = useState(true);
    const [categoryList, setCategoryList] = useState([
        { id: 1, categoryName: "", categoryURL: "" },
        { id: 2, categoryName: "", categoryURL: "" },
    ]);
    const [subCategoryTrace, setSubCategoryTrace] = useState({ categoryTrace: [{}] });
    const [categoryReorderList, setCategoryReorderList] = useState({ categoryReorder: [{}] })
    const [allData, setAllData] = useState<any>([]);
    const [allDataIn, setAllDataIn] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [fromBack, setFromBack] = useState(false);
    const dispatch = useDispatch()


    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchAgain, setSearchAgain] = useState(false)
    const methods = useForm();
    const { handleSubmit, setValue, control, formState: { errors } } = methods;

    //Function when search input change
    const handleSearchInputChange = async () => {
        if (searchQuery == "") {
            setIsSearching(false);
            setIsThereMoreData(true);
            setPage(1);
            setAllData([]);
            setAllDataIn([]);

        } else {
            setIsSearching(true);
        }
        setSearchAgain(prev => !prev)
    };

    //API calling 
    React.useEffect(() => {
        const getCategoryList = async () => {
            try {
                setIsloading(true);
                let newData: any = []

                if (isSearching) {

                    const response = await axios({
                        url: `${ENV.ENDPOINT}/material-categories?&filters[material_outward_enable][$eq]=true&filters[material_category_name][$containsi]=${searchQuery}`,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })



                    const responseData = response.data

                    newData = responseData.data
                    setAllData([])
                    setIsloading(false);

                    return newData;
                } else {


                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-categories?pagination[page]=' + page,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    if (response.data.meta.pagination.page === response.data.meta.pagination.pageCount) {
                        setIsThereMoreData(false);
                    }
                    const responseData = response.data

                    if (responseData.data.length < 1) {
                        if (hasMoreData === true) {
                            dispatch(setOutwardHasMoreData(false));

                        }

                        setIsThereMoreData(false);


                    }
                    else {
                        newData = [...responseData.data]
                    }
                    setIsloading(false);

                    return newData;
                }
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }


        };
        if (locations.state != undefined && Object.keys(locations.state as object).length != 0) {
            let location = locations.state as LocationState;
            if (location.categoryId != undefined) {
                setCategoryId(location.categoryId);
                setCategoryName(location.categoryName);
                let reorderData: any;
                let reorderArrCategory: any;
                let result: any[] = [];
                const fetchData = async () => {
                    try {
                        if (isSearching) {
                            var response = await axios({
                                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_outward_enable][$eq]=true&filters[material_parent_id][$eq]=' + location.categoryId + `&filters[material_category_name][$containsi]=${searchQuery}`,
                                method: 'get',
                                headers: {
                                    Authorization: HeaderAuthorization.Authorization,
                                    userId: userId,
                                    token: token,
                                },
                            })
                            setAllDataIn([]);
                        } else {


                            response = await axios({
                                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_outward_enable][$eq]=true&filters[material_parent_id][$eq]=' + location.categoryId + '&pagination[page]=' + page,
                                method: 'get',
                                headers: {
                                    Authorization: HeaderAuthorization.Authorization,
                                    userId: userId,
                                    token: token,
                                },
                            })

                            if (response.data.meta.pagination.page === response.data.meta.pagination.pageCount) {
                                setIsThereMoreData(false);
                            }
                        }
                        const responseData = response.data;
                        if (isSearching) {
                            var searchDataDisplay: any = responseData.data
                        }
                        else {
                            searchDataDisplay = [...allDataIn, ...responseData.data];
                        }

                        const notUniqueData: any = searchDataDisplay

                        const newData = notUniqueData.filter((obj: any, index: any, self: any) => {
                            return (obj.attributes.material_parent_id === location.categoryId.toString()) && (index === self.findIndex((o: any) => o.id === obj.id));
                        });
                        setAllDataIn(newData);
                        if (responseData.data.length < 1) {
                            if (hasMoreData === true) {
                                dispatch(setOutwardHasMoreData(false))

                            }
                            setIsThereMoreData(false);


                        }


                        setIsloading(false);

                        const categoryListArray: CategoryData[] = []
                        const categoryReorderArr: any[] = [];
                        newData.forEach((data: any) => {
                            categoryListArray.push({ id: data.id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry", materialItem: data.attributes.material_item })
                            categoryReorderArr.push(data.id);
                        })
                        if (location.pushBack != true) {
                            var isUnique = true
                            subCategoryTrace.categoryTrace.map((item: any) => {
                                if (item.categoryId) {
                                    if (item.categoryId === location.categoryId) {
                                        isUnique = false;
                                    }
                                }
                            })

                            if (isUnique) {
                                setSubCategoryTrace({ categoryTrace: [...subCategoryTrace['categoryTrace'], { "categoryName": location.categoryName, "categoryId": location.categoryId }] })
                            }
                            setCategoryReorderList({ categoryReorder: [...categoryReorderList['categoryReorder'], { "categoryParentId": location.categoryId, "categoryReorderArr": categoryReorderArr }] })
                        } else {
                            setSubCategoryTrace({ categoryTrace: location.subCategory });
                        }


                        reorderData = localStorage.getItem('reorderOutwardCategory')
                        if (reorderData) {
                            reorderData = JSON.parse(reorderData)
                            reorderData.forEach((reorderData: any) => {
                                if (reorderData.categoryParentId == location.categoryId) {
                                    reorderArrCategory = reorderData.categoryReorderArr;
                                }
                            })
                        }
                        if (reorderArrCategory) {
                            reorderArrCategory.forEach((reorderArrCategory: any) => {
                                result.push(categoryListArray.find(categoryListArray => categoryListArray.id == reorderArrCategory))
                            })
                            setCategoryList(result);
                            setIsloading(false);
                        }
                        else {
                            setCategoryList(categoryListArray);
                            setIsloading(false);
                        }
                        return newData;

                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }
                }
                fetchData();
            } else {
                setCategoryName("");
                let reorderData: any;
                let reorderArrCategory: any;
                let result: any[] = [];
                var subCategoryTraceValue = subCategoryTrace.categoryTrace
                subCategoryTraceValue.splice(-1)
                setSubCategoryTrace({ categoryTrace: subCategoryTraceValue });

                const fetchData = async () => {
                    try {
                        const newdata = await getCategoryList();
                        if (isSearching) {
                            var searchDataDisplay: any = newdata
                        }
                        else {
                            searchDataDisplay = [...allData, ...newdata];
                        }

                        const notUniqueData: any = searchDataDisplay
                        const data = notUniqueData.filter((obj: any, index: any, self: any) => {
                            return index === self.findIndex((o: any) => o.id === obj.id);
                        });
                        setAllData(data);
                        const categoryListArray: CategoryData[] = [];
                        data.forEach((data: any) => {
                            if (data.attributes.material_parent_id == null) {
                                if (data.attributes.material_outward_enable == false) {
                                    categoryListArray.push({ id: data.id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry", materialItem: data.attributes.material_item, flag: true })
                                } else {
                                    categoryListArray.push({ id: data.id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry", materialItem: data.attributes.material_item, })
                                }
                            }
                        }
                        )
                        reorderData = localStorage.getItem('reorderOutwardCategory')
                        if (reorderData) {
                            reorderData = JSON.parse(reorderData)
                            reorderData.forEach((reorderData: any) => {
                                if (reorderData.categoryParentId == 0) {
                                    reorderArrCategory = reorderData.categoryReorderArr;
                                }
                            })
                        }
                        if (reorderArrCategory) {
                            reorderArrCategory.forEach((reorderArrCategory: any) => {
                                result.push(categoryListArray.find(categoryListArray => categoryListArray.id == reorderArrCategory))
                            })
                            setCategoryList(result);
                            setIsloading(false);
                        }
                        else {
                            setCategoryList(categoryListArray);
                            setIsloading(false);
                        }
                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }
                }

                fetchData();
            }
        } else {
            setCategoryId(0);
            let reorderData: any;
            let reorderArrCategory: any;
            let result: any[] = [];

            const fetchData = async () => {
                try {
                    const newdata = await getCategoryList();
                    if (isSearching) {
                        var searchDataDisplay: any = newdata
                    }
                    else {
                        searchDataDisplay = [...allData, ...newdata];
                    }

                    const notUniqueData: any = searchDataDisplay
                    const data = notUniqueData.filter((obj: any, index: any, self: any) => {
                        // Check if the object's id is unique within the array
                        return index === self.findIndex((o: any) => o.id === obj.id);
                    });
                    setAllData(data);
                    const categoryListArray: CategoryData[] = [];
                    const categoryReorderArr: any[] = [];
                    data.forEach((data: any) => {
                        if (data.attributes.material_parent_id == null) {
                            if (data.attributes.material_outward_enable == false) {
                                categoryListArray.push({ id: data.id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry", materialItem: data.attributes.material_item, flag: true })
                                categoryReorderArr.push(data.id);

                            } else {
                                categoryListArray.push({ id: data.id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry", materialItem: data.attributes.material_item, })
                                categoryReorderArr.push(data.id);

                            }

                        }
                    }
                    )
                    setCategoryReorderList({ categoryReorder: [...categoryReorderList['categoryReorder'], { "categoryParentId": 0, "categoryReorderArr": categoryReorderArr }] })
                    reorderData = localStorage.getItem('reorderOutwardCategory')
                    if (reorderData) {
                        reorderData = JSON.parse(reorderData)
                        reorderData.forEach((reorderData: any) => {
                            if (reorderData.categoryParentId == 0) {
                                reorderArrCategory = reorderData.categoryReorderArr;
                            }
                        })
                    }
                    if (reorderArrCategory) {
                        reorderArrCategory.forEach((reorderArrCategory: any) => {
                            result.push(categoryListArray.find(categoryListArray => categoryListArray.id == reorderArrCategory))
                        })
                        // setCategoryList(result);
                        setCategoryList(categoryListArray);
                        setIsloading(false);
                    }
                    else {
                        setCategoryList(categoryListArray);
                        setIsloading(false);
                    }
                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            fetchData();

        }
    }, [locations, page, searchAgain]);

    //Render Material categrories
    const renderCategoryList = () => {

        if (categoryList.length === 0) {
            return <p>No results found.</p>;
        }
        return categoryList.map((x: any, i) => {
            if (x?.flag == true) {
                return
            } else {

                return (
                    <IonItem key={i} className="category-button" onClick={() => addOutwardEntry(x)}>
                        <IonReorder slot="start"></IonReorder>
                        <IonLabel>
                            {x.categoryName}
                        </IonLabel>
                        <IonIcon icon="/assets/images/arrow-right-icon.svg" slot="end" />
                    </IonItem>
                );
            }
        })
    }
    //Function to call nextpage query 
    const nextPage = () => {
        setPage(prevPage => prevPage + 1);
    }

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Outward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            {(() => {
                                if (categoryName == "") {
                                    return (
                                        <h2 className="category-name">Category</h2>
                                    )
                                } else {
                                    return (
                                        <h2 className="category-name">{categoryName}</h2>
                                    )
                                }
                            })()}
                            <div >
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(handleSearchInputChange)}>
                                        <IonRow className="search-row">
                                            <IonCol size="8">
                                                <IonInput
                                                    type="text"
                                                    onIonChange={(e: any) => {
                                                        setSearchQuery(e.detail.value)

                                                    }}
                                                    className={`form-control ${errors.organizationName ? 'is-invalid' : ''}`}
                                                    placeholder="Search For Material Category"
                                                    value={searchQuery}
                                                    mode="md" />
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonButton className="secondary-button" expand="block" shape="round" fill="solid" size="small" onClick={() => {
                                                    setSearchQuery('');
                                                    setIsSearching(false);
                                                    setIsThereMoreData(true);
                                                    setPage(1);
                                                    setAllData([]);
                                                    setAllDataIn([]);
                                                    setSearchAgain(prev => !prev);
                                                }}>
                                                    Clear
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Search" />

                                    </form>

                                </FormProvider>
                            </div>
                            <IonList className="category-list">
                                <IonReorderGroup disabled={true}>
                                    {renderCategoryList()}
                                </IonReorderGroup>
                                {isSearching ? null : <LoadMoreButton nextPage={nextPage} isThereMoreData={isThereMoreData} />}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {(isloading && <Loader />)}
            </IonContent>
        </IonPage>
    );
};

export default Outward;
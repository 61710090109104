// Import necessary dependencies and components
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon, useIonAlert, IonToast } from "@ionic/react";
import { personAddSharp, removeCircleSharp } from "ionicons/icons";
import Header from "../components/Header/Header";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "../assets/css/Custom.css";
import "../assets/css/Responsive.css";
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Loader from "../components/Loader/Loader";
import Logout from "../components/Logout";

// Define the Users functional component
const Users: React.FC = () => {
  // Get the history object from React Router
  let history = useHistory();
  // Function to navigate back to the previous page
  const backPage = () => {
    // history.go(-1);
    history.push('/my-account');
  }
  // Function to do nothing when the user clicks on a button (history.goBack())
  const doNothing = () => { };
  // Define default user list state
  const defaultList = [
    {
      id: "",
      workuserId: null,
      userName: "",
      userStatus: "",
      userNumber: "",
      userType: false,
      userRole: "",
      rolePerm: "",
      perm: "",
      invitedBy: false,
      loggedStatus: false
    },
  ];
  // Set the state for user list, permission, alert state, toast state, message, color data, and loading state
  const [inputList, setInputList] = useState(defaultList);
  const [permission, setPermission] = useState("");
  const [present] = useIonAlert();
  const [showToast1, setShowToast1] = useState(false);
  const [message, setMessage] = useState('');
  const [colordata, setColordata] = useState('');
  const [isloading, setIsloading] = useState(true);
  const userRole1 = localStorage.getItem('work_roles');
  const userIdData = localStorage.getItem('userid');
  const ownerStatus = localStorage.getItem("ownerStatus")
  let userRoleName = '';
  let role_permission = ''
  let perm = ''

  const userId: any = localStorage.getItem('userid');
  const token: any = localStorage.getItem('token');
  // useEffect to fetch user data when the component mounts
  React.useEffect(() => {
    const workspaceId = localStorage.getItem('workspaceId');

    const fetchData = async () => {
      try {
        let page = 1;
        const allData: any = [];
        while (true) {
          const result = await fetch(ENV.ENDPOINT + "/user-workspaces?populate=*&filters[workspace_id][id][$eq]=" + workspaceId + '&filters[$or][0][user_status][$eq]=Active&filters[$or][1][user_status][$eq]=Pending&pagination[page]=' + page, {
            headers: {
              Authorization: HeaderAuthorization.Authorization,
              userId: userId,
              token: token,
            },
          });
          const response = await result.json();
          allData.push(...response.data)
          if (response.data.length === 0) {
            break;
          }
          page++;

        }
        const userListArr: any = [];
        let invitedBy: any = false;
        for (const [index, value] of allData.entries()) {
          if (value.attributes.user_id.data != null) {
            if (value.attributes.user_id.data.id == userIdData) {
              localStorage.setItem("ownerStatus", value.attributes.owner)
            }
            switch (value.attributes.user_role) {
              case null:
              case "builder":
                userRoleName = "Builder";
                role_permission = "0";
                perm = "0";
                break;

              case "manager":
                userRoleName = "Project Manager";
                role_permission = "1";
                perm = "1";
                break;

              case "site":
                userRoleName = "Site Incharge";
                role_permission = "2";
                perm = "2";
                break;

              case "supervisor":
                userRoleName = "Supervisor / Engineer";
                role_permission = "3";
                perm = "3";
                break;

              case "stock":
                userRoleName = "Stock Manager";
                role_permission = "4";
                perm = "4";
                break;

              case "sitePlus":
                userRoleName = "Site Incharge Plus";
                role_permission = "2";
                perm = "2";
                break;

              case "supervisorPlus":
                userRoleName = "Supervisor / Engineer Plus";
                role_permission = "3";
                perm = "3";
                break;

              case "stockPlus":
                userRoleName = "Stock Manager Plus";
                role_permission = "4";
                perm = "4";
                break;

              default:
                if (value.attributes.owner == true) {
                  role_permission = "0";
                  perm = "0";
                }
                break;
            }
            if (value.attributes.invited_by.data != null) {
              if (value.attributes.invited_by.data.id == userIdData) {
                invitedBy = true
              }
            }
            userListArr.push({
              id: value.id,
              workuserId: value.attributes.user_id.data.id,
              userName: value.attributes.user_id.data.attributes.username,
              userNumber: value.attributes.user_id.data.attributes.mobileNumber,
              userStatus: value.attributes.user_status,
              userType: value.attributes.owner,
              userRole: userRoleName,
              rolePerm: role_permission,
              perm: perm,
              loggedStatus: value.attributes.user_id.data.attributes.logged_status,
              invitedBy: invitedBy
            })
          }
        }
        setInputList(userListArr);
        setIsloading(false);
      } catch (error: any) {
        console.log(error);
        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
          Logout(history)
        }
      }

    }
    fetchData();
  }, []);
  // useEffect to set permission based on user role and owner status
  useEffect(() => {
    let permissions = ""
    if (userRole1 == "null") {
      permissions = "0";
      setPermission(permissions);
    }
    else if (userRole1 == "builder" && ownerStatus == "true") {
      permissions = "0";
      setPermission(permissions);
    }
    else if (userRole1 == "builder") {
      permissions = "1";
      setPermission(permissions);
    }
    else if (userRole1 == "manager" && ownerStatus == "true") {
      permissions = "0";
      setPermission(permissions);
    }
    else if (userRole1 == "manager") {
      permissions = "1";
      setPermission(permissions);
    }
    else if (userRole1 == "site" && ownerStatus == "true") {
      permissions = "0";
      setPermission(permissions);
    }
    else if (userRole1 == "site") {
      permissions = "2";
      setPermission(permissions);
    }
    else if (userRole1 == "supervisor") {
      permissions = "3";
      setPermission(permissions);
    }
    else if (userRole1 == "stock") {
      permissions = "4";
      setPermission(permissions);
    }
  })
  // Function to handle removing a user
  const handleRemoveClick = async (index: any, data: any) => {
    const list = [...inputList];
    try {
      await axios({
        method: "PUT",
        url: ENV.ENDPOINT + "/user-workspaces/" + data,
        data: {
          "data": {
            user_status: "Inactive",
          }
        },


        headers: {
          Authorization: HeaderAuthorization.Authorization,
          userId: userId,
          token: token,
        },

      })

      list.splice(index, 1);
      setInputList(list);
      setColordata("success");
      setMessage("User Deleted successfully");
      setShowToast1(true)

    } catch (error: any) {
      console.log(error);
      if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
        Logout(history)
      }
    }
  };
  // Function to render the user list

  const renderList = () => {
    return inputList.map((x, i) => {
      return (
        <IonRow key={i} className="table-row table-header">
          <IonCol size="5" sizeMd="5" sizeLg="5" className="">
            <p className={`user-name ${x.userStatus == 'Active' ? 'active-user' : ''}`}>{x.userName}</p>
            <p className={`user-number ${x.userStatus == 'Active' ? 'active-user-number' : ''}`}>{x.userNumber}</p>
          </IonCol>
          <IonCol size="4" sizeMd="4" sizeLg="4" className="">
            <p className="user-designation">{x.userRole}</p>
            {(() => {
              if (x.userType == true) {
                return (
                  <p className="user-type">Owner</p>
                )
              } else {
                return (
                  <p className="user-type"></p>
                )
              }
            })()}
          </IonCol>
          {x.workuserId == userIdData ? localStorage.setItem("ownerId", "" + x.id) : ""}
          {(permission <= x.rolePerm && x.userType != true) ? (
            <IonCol
              size="3"
              sizeMd="3"
              sizeLg="3"
              className={`buttons-wrap ion-text-end  ${(x.workuserId == userIdData) ? "ion-hide" : ""
                }`} >
              <>
                <IonButton id={x.id} fill="clear" onClick={(x: any) => {
                  present({
                    cssClass: 'red-alert',
                    header: 'Remove User',
                    message: '<p>Are you sure you want to remove this user?</p>',
                    buttons: [
                      'Cancel',
                      {
                        text: 'Ok',
                        handler: () => {
                          handleRemoveClick(i, (x.target as HTMLElement).id);

                        }
                      },
                    ],
                    onDidDismiss: (e: any) => { }
                    ,
                  })
                }
                }>
                  <IonIcon icon={removeCircleSharp}></IonIcon>
                </IonButton>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    history.push({
                      pathname: '/edit-user',
                      state: { id: x.id, editrole: x.userRole, invitedBy: x.invitedBy, loggedStatus: x.loggedStatus },
                    });
                  }} >
                  <IonIcon icon="/assets/images/user-edit-icon.svg"></IonIcon>
                </IonButton></>
            </IonCol>) : ""}
        </IonRow>
      );
    });
  };
  //Render JSX Component
  return (
    <IonPage>
      <Header class="with-back-arrow" onBack={doNothing} />
      <IonContent fullscreen>
        <IonGrid className="full-height-div">
          <IonRow className="main-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="select-project-header">
                <IonButton fill="clear" onClick={backPage}>
                  <IonIcon icon="/assets/images/arrow-left-icon.svg"></IonIcon>
                </IonButton>
                <div className="project-title-wrap">
                  <h3>
                    <b>Users</b>
                  </h3>
                </div>
              </div>

              <div className="ion-text-right">
                {(userRole1 != "stock") ?
                  <IonButton
                    className="invite-user-button"
                    fill="clear"
                    routerLink='/invite-user' >
                    <IonIcon icon={personAddSharp}></IonIcon>
                    <h5 className="ion-no-margin">Invite User</h5>
                  </IonButton>
                  : ""}
              </div>
              <IonGrid className="table">
                <IonRow className="table-row table-header">
                  <IonCol size="5" sizeMd="5" sizeLg="5" className="">
                    <h6 className="ion-no-margin">USER</h6>
                  </IonCol>
                  <IonCol size="4" sizeMd="4" sizeLg="4" className="">
                    <h6 className="ion-no-margin">ROLE</h6>
                  </IonCol>
                  <IonCol
                    size="3"
                    sizeMd="3"
                    sizeLg="3"
                    className="ion-text-end">
                  </IonCol>
                </IonRow>
                {renderList()}
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message={message}
            duration={3000}
            position="middle"
            color={colordata} />
        </IonGrid>
        {(isloading && <Loader />)}
      </IonContent>
    </IonPage>
  );
};

export default Users;
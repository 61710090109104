// Import necessary modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonList, IonItem } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";

// Define the types for SubCategoryData and LocationState
interface SubCategoryData {
    id: number;
    parentCategoryId: number;
    categoryName: string;
    categoryURL: string
}
interface LocationState {
    subCategoryId: number;
    subCategoryName: string;
    categoryId: number;
    categoryName: string;
    status: string
}
const OutwardSubCategory: React.FC = () => {
    // Get the location object to access state from previous page
    const locations = useLocation();

    // Get the history object to handle navigation
    let history = useHistory();

    // Function to navigate back to the previous page

    const doNothing = () => {
        history.goBack();
    }
      // Get user ID and token from local storage
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
  // Function to add outward entry based on subcategory or category status
    const addOutwardEntry = async (data: any) => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_parent_id][$eq]=' + data.id,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            if (response.data.data.length == 0) {
                history.push({
                    pathname: data.categoryURL,
                    state: { "subCategoryId": data.id, "subCategoryName": data.categoryName, "categoryId": data.parentCategoryId, "status": "subCategory", "categoryUrl": data.categoryURL }
                }
                );
            } else {
                history.push({
                    pathname: '/outward-sub-category',
                    state: { "categoryName": data.categoryName, "categoryId": data.id, "status": "category", "categoryUrl": data.categoryURL }
                }
                );
            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }


    }
      // State to manage the subcategory list, category ID, and category name
    const [subCategoryList, setSubCategoryList] = useState([
        { id: 1, parentCategoryId: 1, categoryName: "material1", categoryURL: "" },
        { id: 2, parentCategoryId: 2, categoryName: "material2", categoryURL: "" },
    ]);

    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
      // Fetch subcategories when the component mounts or when location state changes
    React.useEffect(() => {
        if (locations.state) {
            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);

            const fetchData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_parent_id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const subCategoryListArray: SubCategoryData[] = []
                    response.data['data'].forEach((data: any) => {
                        subCategoryListArray.push({ id: data.id, parentCategoryId: data.attributes.material_parent_id, categoryName: data.attributes.material_category_name, categoryURL: "/outward-entry" })
                    })
                    setSubCategoryList(subCategoryListArray);
                    return response.data;

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            fetchData();
        }
    }, [locations]);
    // Get project name from local storage
  const projectName = localStorage.getItem('projectName');

  // Render the subcategory list
    const renderCategoryList = () => {
        return subCategoryList.map((x, i) => {
            return (
                <IonItem key={i} className="category-button" onClick={() => addOutwardEntry(x)}>
                    <IonLabel>
                        {x.categoryName}
                    </IonLabel>
                    <IonIcon icon="/assets/images/arrow-right-icon.svg" slot="end" />
                </IonItem>
            );
        })
    }

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" routerLink='/outward'>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Outward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <h2 className="category-name">{categoryName}</h2>
                            <IonList className="category-list">
                                {renderCategoryList()}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default OutwardSubCategory;
// Import required modules and components
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { IonContent, IonPage, IonInput, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonToast, } from '@ionic/react';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useState } from 'react';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonImgButton from "../components/Button/IonImgButton";
import Logout from "../components/Logout";
import { useHistory } from "react-router-dom";

// Create the main component ForgetPassword
const ForgetPassword: React.FC = () => {
   // State variables and hooks
   const methods = useForm();
   const { handleSubmit, control, formState: { errors } } = methods;
   const [message, setMessage] = useState('');
   const [showToast1, setShowToast1] = useState(false);
   const [colordata, setColordata] = useState('');
   let userid: any = localStorage.getItem("userid");
   let token: any = localStorage.getItem("token");
   let history = useHistory()
   const [buttonDisable, setButtonDisable] = useState(false)

   // Function to handle form submission
   const onSubmit = async (data: any) => {

      setButtonDisable(true)
      try {
         const user = await axios({
            method: 'get',
            url: ENV.ENDPOINT + '/users?filters[email][$eq]=' + data.email,
            headers: {
               Authorization: HeaderAuthorization.Authorization,
               userId: userid,
               token: token,
               PageLocation: "forgot",
            },
         })
         if (user.data.length != 0) {
            try {
               await axios({
                  method: "post",
                  url: ENV.ENDPOINT + '/otps/createotp',
                  data: {
                     "data": {
                        email: data.email,
                        status: "forgotpwd",
                        urldata: ENV.WebsiteURL + '/reset-password/' + user.data[0].id
                     }
                  },
                  headers: {
                    
                     userId: userid,
                     token: token,
                     PageLocation: "forgot",
                  },
               })
               setColordata("success");
               setMessage("Email sent successfully.Please check email to reset password");
               setShowToast1(true)

            } catch (error: any) {
               console.log(error);
               setButtonDisable(false)
               if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                  Logout(history)
               }
            }
         } else {
            setColordata("danger");
            setMessage("Email does not exist");
            setButtonDisable(false)
            setShowToast1(true)
         }

      } catch (error: any) {
         console.log(error);
         setButtonDisable(false)
         if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
            Logout(history)
         }
      }
   }

   //JSX Component return
   return (
      <IonPage>
         <IonContent fullscreen>
            <IonGrid className="full-height-div">
               <IonRow className="login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="main-logo">
                              <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />
                           </div>
                           <h2 className='ion-text-center'>Reset Password</h2>
                           <p>Enter the email assosicated with your account and we'll send you an email with instruction to reset your password.</p>
                           <IonLabel className="form-lable ion-margin-top" >Email Address:*</IonLabel>
                           <Controller
                              render={({ field: { onChange, onBlur, value } }) => (
                                 <IonInput
                                    type="email"
                                    onIonChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder=""
                                    mode="md" />
                              )}
                              control={control}
                              name="email"
                              rules={{
                                 required: "Email is required",
                                 pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                 }
                              }} />
                           {<ErrorMessage
                              error={errors}
                              name="email"
                              as={<div className="error-message" style={{ color: 'red' }} />} />}
                           <IonButton type="submit" className="secondary-button ion-margin-top" expand="block" shape="round" fill="solid" disabled={buttonDisable}>
                              Send
                           </IonButton>
                           <div className="back-to-login ion-text-right">
                              <IonButton routerLink="/index" fill="clear" >
                                 Back to Login
                              </IonButton>
                           </div>
                        </form>
                     </FormProvider>
                  </IonCol>
               </IonRow>
               {/* Toast notification */}
               <IonToast
                  isOpen={showToast1}
                  onDidDismiss={() => setShowToast1(false)}
                  message={message}
                  duration={2000}
                  position="top"
                  color={colordata} />
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};
export default ForgetPassword;
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IonButton, IonIcon, IonAlert } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useForm } from "react-hook-form";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import Modal from "react-modal";
import exifr from 'exifr';
import UploadImg from "../components/Image/UploadImg";
import Inward from "../views/Inward/Index";
import Logout from "../components/Logout";
const AWS= require('aws-sdk');
declare global {
    interface Window {
        Buffer: any;
    }
}
window.Buffer = window.Buffer || require("buffer").Buffer;
Modal.setAppElement("#root");
//interface to check types checking
interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    editStatus: boolean;
    inwardId: number;
    categoryTrace: object;
    materialItem: string;
    parentCatId: number;
}
interface MaterialListArr {
    id: number;
    material_item_name: string;
    material_unit: string;
    material_unitId: number;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}
const InwardEntrySand: React.FC = () => {
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image1Name, setImage1Name] = useState<any>();
    const [image2Name, setImage2Name] = useState<any>();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [parentCatId, setParentCatId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [quantity, setQuantity] = useState(0);
    const config = {
        bucketName: 'my-workspace-image',
        region: 'ap-south-1',
        accessKeyId: ENV.accessKeyId,
        secretAccessKey: ENV.secretAccessKey
    }
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal1() {
        setIsOpen1(!isOpen1);
    }
    const [categoryTrace, setCategoryTrace] = useState({});
    const locations = useLocation();
    const [materialListData, setMaterialListData] = useState([
        { id: 1, "material_item_name": "materialitem1", "material_unit": "unit1", material_unitId: 1 },
        { id: 2, "material_item_name": "materialitem2", "material_unit": "unit2", material_unitId: 2 },
    ]);
    const [materialUnit, setMaterialUnit] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState<any>();
    const [materialUnitId, setMaterialUnitId] = useState(0);
    const [truckListData, setTruckListData] = useState([
        { id: 1, attributes: { "truck_no": "truckno1", "truck_width": "1", "truck_height": "2", "truck_length": "3" } },
        { id: 2, attributes: { "truck_no": "truckno2", "truck_width": "1", "truck_height": "2", "truck_length": "3" } },
    ]);

    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [heightError, setHeightError] = useState(false);
    const [heightErrorMsg, setHeightErrorMsg] = useState("");
    const [inwardId, setInwardId] = useState(0);
    const [materialItem, setMaterialItem] = useState('');
    const [imgDelete, setImgDelete] = useState(false);
    const [imgDelete1, setImgDelete1] = useState(false);
    const [subCategory, setSubCategory] = useState({});
    const [colordata, setColordata] = useState('');
    const [backData, setBackData] = useState('');
    var min = 1;
    var max = 100000;
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    const userid: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    const [materialItemId, setMaterialItemId] = useState(0);
    const [isloading, setIsloading] = useState(false);
    let history = useHistory();
    //Function for back page
    const backPage = () => {
        if (backData == 'materialstock') {
            // history.go(-1);
            history.push({ pathname: '/material-stock', state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace, "parentCatId": parentCatId } });
        } else if (backData == 'stockreport') {
            // history.go(-1);
            history.push({ pathname: '/stock-report', state: { "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialId": materialItemId, "inwardId": inwardId, "materialName": materialName, "quantity": quantity } });
        }
        else {
            var pushCategoryTrace = {} as pushState
            if (categoryTrace) {
                const categoryPush = categoryTrace as pushState
                pushCategoryTrace['categoryName'] = categoryPush['categoryName']
                pushCategoryTrace['categoryId'] = categoryPush['categoryId']
            }
            pushCategoryTrace["pushBack"] = true
            pushCategoryTrace["subCategory"] = subCategory;
            // history.go(-1);
            history.push({
                pathname: '/inward',
                state: pushCategoryTrace
            });
        }
    };
    const methods = useForm();
    const { handleSubmit, setValue, getValues, control, formState: { errors } } = methods;
    //Function that handles the form request while submiting the form
    const onSubmit = async (data: any) => {
        var one: any;
        var two: any;
        if (image1 != undefined) {
            try {
                const location = await UploadImg(image1, image1Name);
                one = location;
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        if (image2 != undefined) {
            try {
                const location = await UploadImg(image2, image2Name);
                two = location;
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        let materialItemData: any;
        if (data.material == '' || data.material == undefined) {
            materialItemData = null;
        } else {
            materialItemData = data.material;
        }
        let inwardDate = new Date().toISOString();
        inwardDate = format(parseISO(inwardDate), 'yyyy-MM-dd')
        if (imgDelete) {
            if(!one){
                one = "";
            }
        }
        if (imgDelete1) {
            if(!two){
                two = "";

            }
        }
        let quantityInwardValue = 0;
        let quantityOutwardValue = 0;
        let totInwardValue = 0;
        let urlInwardLink: any;
        let urlOutwardLink: any;
        if (data.material == '' || data.material == undefined) {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId;
        }
        else {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material;
        }
        try {
            const response = await axios({
                url: urlInwardLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            if (response.data['data'].length != 0) {
                response.data['data'].forEach((list: any) => {
                    quantityInwardValue = quantityInwardValue + list.attributes.inward_quantity;
                })
                totInwardValue = quantityInwardValue + parseInt(data.quantity);
                try {
                    const outwardResponse = await axios({
                        url: urlOutwardLink,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    if (outwardResponse.data['data'].length != 0) {
                        outwardResponse.data['data'].forEach((list: any) => {
                            quantityOutwardValue = quantityOutwardValue + list.attributes.outward_quantity;
                        })
                    }
                    if ((totInwardValue - quantityOutwardValue >= 0)) {
                        try {
                            await axios({
                                method: 'PUT',
                                url: ENV.ENDPOINT + '/inward-entries/' + inwardId,
                                data: {
                                    "data": {
                                        material_item: materialItemData,
                                        inward_quantity: data.quantity,
                                        material_truck: data.truckno,
                                        inward_note: data.Notes,
                                        inward_capture1: one,
                                        inward_capture2: two,
                                        inward_material_height: data.materialHeightFeet + '.' + data.materialHeightInch
                                    }
                                },
                                headers: {
                                    Authorization: HeaderAuthorization.Authorization,
                                    userId: userid,
                                    token: token,
                                },
                            })
                            setColordata("success");
                            setMessage("Inward Entry Updated successfully");
                            setShowToast1(true)
                            setValue('material', '');
                            setValue('truckno', '');
                            setValue('quantity', '');
                            setValue('Notes', '');
                            setValue('postFile', '');
                            setValue('postFile1', '');
                            setValue('materialHeightInch', '');
                            setValue('materialHeightFeet', '');
                            setFileList(defaultList);
                            setFileList1(defaultList);
                            if (imgDelete) {
                                const ReactS3Client = new AWS.S3(config);                            
                                const params = {
                                    Bucket: 'cms-ionic-app',
                                    Key: data.postFile, // Specify the path to your image
                                };
                                // await ReactS3Client.deleteFile(data.postFile.split('amazonaws.com/')[1]);
                                await ReactS3Client.deleteObject(params).promise();
                            }
    
                            if (imgDelete1) {
                            const params = {
                                Bucket: 'cms-ionic-app',
                                Key: data.postFile1, // Specify the path to your image
                            };
                                const ReactS3Client = new AWS.S3(config);
                                await ReactS3Client.deleteObject(params).promise();
                            }

                        } catch (error: any) {
                            console.log(error);
                            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                                Logout(history)
                            }
                        }

                    } else {
                        setColordata("danger");
                        setMessage("Total Inward  quantity  should not less than total outward");
                        setShowToast1(true)
                    }

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            } else {
                try {
                    await axios({
                        method: 'PUT',
                        url: ENV.ENDPOINT + '/inward-entries/' + inwardId,
                        data: {
                            "data": {
                                material_item: materialItemData,
                                inward_quantity: data.quantity,
                                material_truck: data.truckno,
                                inward_note: data.Notes,
                                inward_capture1: one,
                                inward_capture2: two,
                                inward_material_height: data.materialHeightFeet + '.' + data.materialHeightInch
                            }
                        },
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    setColordata("success");
                    setMessage("Inward Entry Updated successfully");
                    setShowToast1(true)
                    setValue('material', '');
                    setValue('truckno', '');
                    setValue('quantity', '');
                    setValue('Notes', '');
                    setValue('postFile', '');
                    setValue('postFile1', '');
                    setValue('materialHeightInch', '');
                    setValue('materialHeightFeet', '');
                    setFileList(defaultList);
                    setFileList1(defaultList);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    };
    //Function for back button
    const doNothing = () => {
        history.goBack();
    }
    //API calling
    React.useEffect(() => {
        let location: any;
        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (location) {
            setParentCatId(location.parentCatId);
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setCategoryTrace(location.categoryTrace)
            setSubCategory(location.subCategory)
            setBackData(location.backpath)
            setMaterialId(location.materialId);
            setMaterialName(location.materialName);
            setQuantity(location.quantity);
            if (location.materialItem == null || location.materialItem == undefined || location.materialItem == "") {

                setMaterialItem("Material")
            } else {
                setMaterialItem(location.materialItem)
            }
            setCategoryUrl(location.categoryUrl);

            const fetchData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];

                    while (true) {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/material-items?populate=*&filters[material_category][id][$eq]=' + location.categoryId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })
                        const responseData = response.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }

                    const materialdata: MaterialListArr[] = [];
                    allData.forEach((list: any) => {
                        if (list.attributes.workspace.data == null) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                        else if (list.attributes.workspace.data.id == workspaceId) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                    })
                    if (materialdata.length == 1 && materialdata[0].material_item_name == location.categoryName) {
                        setMaterialUnitId(materialdata[0].material_unitId)
                        setMaterialUnit(materialdata[0].material_unit);
                    }
                    setMaterialListData(materialdata);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const responseData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];
                    while (true) {
                        const response = await axios({

                            url: ENV.ENDPOINT + '/material-trucks?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })
                        const responseData = response.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }
                    setTruckListData(allData);
                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            setIsloading(true);
            setTimeout(() => {
                setMaterialItemId(location.materialId)
                setInwardId(location.inwardId)
                const myData = async () => {
                    try {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/inward-entries?&populate=*&filters[id][$eq]=' + location.inwardId,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })

                        let materialHeight = response.data['data'][0].attributes.inward_material_height;
                        const truck_length_array = materialHeight.split('.');
                        if (response.data['data'][0].attributes.material_item.data != null) {
                            setValue('material', response.data['data'][0].attributes.material_item.data.id)
                        }
                        setValue('truckno', response.data['data'][0].attributes.material_truck.data.id);
                        setValue('quantity', response.data['data'][0].attributes.inward_quantity);
                        setValue('Notes', response.data['data'][0].attributes.inward_note);
                        setValue('postFile', response.data['data'][0].attributes.inward_capture1);
                        setValue('postFile1', response.data['data'][0].attributes.inward_capture2);
                        setValue('materialHeightInch', truck_length_array[1]);
                        setValue('materialHeightFeet', truck_length_array[0]);
                        if (response.data['data'][0].attributes.inward_capture1) {
                            setFileList({ id: "", fileName: response.data['data'][0].attributes.inward_capture1.toString().split('https://my-workspace-image.s3-ap-south-1.amazonaws.com/') });
                        }
                        if (response.data['data'][0].attributes.inward_capture2) {
                            setFileList1({ id: "", fileName: response.data['data'][0].attributes.inward_capture2.toString().split('https://my-workspace-image.s3-ap-south-1.amazonaws.com/') });
                        }

                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }
                }
                myData();
                setIsloading(false);
            }, 1000);
            setCategoryUrl(location.categoryUrl);

            fetchData();
            responseData();
        }
    }, [locations]);
    //Function to create new material
    const createNewMaterial = () => {
        history.push({
            pathname: '/create-material',
            state: { "categoryId": categoryId, "categoryName": categoryName, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backData, "inwardId": inwardId, "materialId": materialId, "materialName": materialName, "quantity": quantity }
        }
        );
    }
    //Function to create new truck
    const createNewTruck = () => {
        history.push({
            pathname: '/create-truck',
            state: { "categoryId": categoryId, "categoryName": categoryName, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backData, "inwardId": inwardId, "materialId": materialId, "materialName": materialName, "quantity": quantity }
        }
        );
    }
    // function that calls when  material unit values are changing
    const onChange = async (data: any, name: any) => {
        if (name == "quantity") {
        }
        if (name == "material") {
            const materialSetUnit = materialListData.filter(list => {
                if (list.id == data) {
                    setMaterialUnitId(list.material_unitId)
                    setMaterialUnit(list.material_unit);
                    return list
                }
            })
        }
    }
    //Function to calculate the quanity of sand 
    const calculateQuantitySand = () => {
        setTimeout(() => {
            const getTruckno = getValues('truckno');
            let truckLength = '';
            let truckWidth = '';
            let truckHeight = '';
            if (getTruckno != undefined || getTruckno == "") {
                const getTruckWidthLength = truckListData.filter(list => {
                    if (list.id == getTruckno) {
                        truckLength = list.attributes.truck_length;
                        truckWidth = list.attributes.truck_width;
                        truckHeight = list.attributes.truck_height;
                    }
                })
                let heightFeet: number = parseInt(getValues('materialHeightFeet'));
                let heightInch: number = parseInt(getValues('materialHeightInch'));
                let heightInchInFeet = 0;
                let lengthInchInFeet = 0;
                let widthInchInFeet = 0;
                let truckHeightInchInFeet = 0;
                const truck_height_array = truckHeight.split('.');
                let truckHeightFeet: number = parseInt(truck_height_array[0]);
                let truckHeightInch: number = parseInt(truck_height_array[1]);
                const truck_length_array = truckLength.split('.');
                const truck_width_array = truckWidth.split('.');
                let lengthFeet: number = parseInt(truck_length_array[0]);
                let lengthInch: number = parseInt(truck_length_array[1]);
                let widthFeet: number = parseInt(truck_width_array[0]);
                let widthInch: number = parseInt(truck_width_array[1]);
                if (heightInch != undefined || heightInch != '' || heightInch != 0) {
                    heightInchInFeet = heightInch / 12;
                    //convert the inches to feet
                }
                if (lengthInch != undefined || lengthInch != '' || lengthInch != 0) {
                    lengthInchInFeet = lengthInch / 12;
                }
                if (widthInch != undefined || widthInch != '' || widthInch != 0) {
                    widthInchInFeet = widthInch / 12;
                }
                if (truckHeightInch != undefined || truckHeightInch != '' || truckHeightInch != 0) {
                    truckHeightInchInFeet = truckHeightInch / 12;
                }
                let finalLengthInFeet: number = lengthFeet + lengthInchInFeet;
                let finalheightInFeet: number = heightFeet + heightInchInFeet;
                let finalwidthInFeet: number = widthFeet + widthInchInFeet;
                let finalTruckHeightInFeet: number = truckHeightFeet + truckHeightInchInFeet;
                if (finalheightInFeet > finalTruckHeightInFeet) {
                    setValue('quantity', '');
                    setHeightError(true);
                    setHeightErrorMsg("Material height should not be greater than truck height" + truckHeightFeet + "'" + truckHeightInch + "''");
                }
                else {
                    setHeightError(false);
                    setHeightErrorMsg("");
                    const materialVolume: number = finalLengthInFeet * finalheightInFeet * finalwidthInFeet;
                    let cubicFeetToBrass: number = materialVolume / 100;
                    let materialInBrassString = cubicFeetToBrass.toFixed(2)
                    setValue('quantity', materialInBrassString);
                }
            } else {
                setColordata("danger");
                setMessage("Please select truck no.");
                setShowToast1(true)
            }
        }, 1000);
    }
    const defaultList =
        { id: "", fileName: "" };
    const fileInput = useRef(null);
    const fileInput01 = useRef(null);
    const [removeFile, setRemoveFile] = useState(false);
    const [fileList, setFileList] = useState(defaultList);
    //handles the removal of image
    const handleRemoveClick = () => {
        setFileList(defaultList);
        setImgDelete(true);
        setValue('postFile', "");
    };
    var options = {
        enableHighAccuracy: true,
        setTimeout: 5000,
        maximumAge: 0,
    }
    var crd
    var lat1: any
    var lon1: any
    function success(pos: any) {
        crd = pos.coords;
        lat1 = crd.latitude
        lon1 = crd.longitude
    }
    function errors1(err: any) {
    }
    const [timer, setTimer] = useState<any>()
    const [toggle, setToggle] = useState(Boolean)
    const time = 10000
    //Get the locaiton of device

    const location = () => {
        if (Capacitor.isNativePlatform()) {
            const printCurrentPosition = async () => {
                const coordinates = await Geolocation.getCurrentPosition();
                crd = coordinates.coords;
                lat1 = coordinates.coords.latitude
                lon1 = coordinates.coords.longitude
            }
            printCurrentPosition()
        }
        else {
            const printCurrentPosition = async () => {
                const coordinates = await Geolocation.getCurrentPosition();
                crd = coordinates.coords;
                lat1 = coordinates.coords.latitude
                lon1 = coordinates.coords.longitude
            }
            printCurrentPosition()
        }
    }
    location()
    //Funtion that handles the image loads from images
    const loadImageFromDevice = async (event: any) => {
        await location()
        let dataa = await exifr.gps(event.target.files[0])
        function computeDistance([prevLat, prevLong]: any, [lat, long]: any) {
            const prevLatInRad = toRad(prevLat);
            const prevLongInRad = toRad(prevLong);
            const latInRad = toRad(lat);
            const longInRad = toRad(long);
            return (
                6377.830272 *
                Math.acos(
                    Math.sin(prevLatInRad) * Math.sin(latInRad) +
                    Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
                )
            );
        }
        function toRad(angle: any) {
            return (angle * Math.PI) / 180;
        }
        const diff_minutes = (timeStart: any, timeEnd: any) => {
            var diff = ((timeEnd - timeStart) / 1000)
            diff /= 60;
            return Math.abs(Math.round(diff))
        }
        const file = event.target.files[0];
        var imgTime = new Date(file.lastModifiedDate).getTime();
        var currTime = Date.now();
        if (diff_minutes(imgTime, currTime) > 5) {
            setColordata("danger");
            setMessage("Upload latest image")
            setShowToast1(true)
        }
        else {
            if (dataa == undefined) {
                setImage1(event.target.files[0])
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                    let filename: string = file.name + "  " + file.lastModifiedDate;
                    let nameOnly = [];
                    let name = file.name;
                    nameOnly = name.split(".");
                    let timestamp = file.lastModifiedDate;
                    timestamp = timestamp.toString().split('GMT')
                    var number = Math.floor(Math.random() * (max - min + 1)) + min;
                    setValue('postFile', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                    setImage1Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                    setFileList({ id: "", fileName: filename });
                    if (isOpen) {
                        toggleModal()
                    }
                };
                reader.onerror = (error) => {
                };

            }
            else {
                if (computeDistance([lat1, lon1], [dataa.latitude, dataa.longitude]) > 2) {
                    setColordata("danger");
                    setMessage("Out of range")
                    setShowToast1(true)
                }
                else {
                    setImage1(event.target.files[0])
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                        let blobURL: string = URL.createObjectURL(blob);
                        let filename: string = file.name + "  " + file.lastModifiedDate;
                        let nameOnly = [];
                        let name = file.name;
                        nameOnly = name.split(".");
                        let timestamp = file.lastModifiedDate;
                        timestamp = timestamp.toString().split('GMT')
                        var number = Math.floor(Math.random() * (max - min + 1)) + min;
                        setValue('postFile', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                        setImage1Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                        setFileList({ id: "", fileName: filename });
                        if (isOpen) {
                            toggleModal()
                        }
                    };
                    reader.onerror = (error) => {
                    };
                }
            }
        }
    };
    // capture images 
    const onImageClick = (event: any) => {
        event.target.value = ''
    }
    //render list for file of images
    const renderList = () => {
        return (
            <div className="uploaded-file">
                <p className="uploaded-file-name read-only" style={{ width: "100%" }}>{fileList.fileName}</p>
                <IonButton fill="clear" onClick={() => setRemoveFile(true)}>
                    <IonIcon icon={close} />
                </IonButton>
                <IonAlert
                    isOpen={removeFile}
                    onDidDismiss={() => setRemoveFile(false)}
                    cssClass='red-alert'
                    mode='md'
                    header={'Remove File'}
                    message={'<p>Are you sure you want to remove this file?</p>'}
                    buttons={[
                        {
                            text: 'Yes',
                            cssClass: 'btn-secondary',
                            handler: (e) => {
                                handleRemoveClick();

                            }
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'btn-outline',
                            handler: () => {
                            }
                        }
                    ]} />
            </div>
        );
    }
    const fileInput1 = useRef(null);
    const fileInput12 = useRef(null);
    const [removeFile1, setRemoveFile1] = useState(false);
    const [fileList1, setFileList1] = useState(defaultList);
    const handleRemoveClick1 = () => {
        setFileList1(defaultList);
        setImgDelete1(true)
        setValue('postFile1', "");
    };
    //Funtion that handles the image loads from images
    const loadImageFromDevice1 = async (event: any) => {
        await location()
        let dataa = await exifr.gps(event.target.files[0])
        function computeDistance([prevLat, prevLong]: any, [lat, long]: any) {
            const prevLatInRad = toRad(prevLat);
            const prevLongInRad = toRad(prevLong);
            const latInRad = toRad(lat);
            const longInRad = toRad(long);
            return (
                6377.830272 *
                Math.acos(
                    Math.sin(prevLatInRad) * Math.sin(latInRad) +
                    Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
                )
            );
        }
        function toRad(angle: any) {
            return (angle * Math.PI) / 180;
        }
        const diff_minutes = (timeStart: any, timeEnd: any) => {
            var diff = ((timeEnd - timeStart) / 1000)
            diff /= 60;
            return Math.abs(Math.round(diff))
        }
        const file = event.target.files[0];
        var imgTime = new Date(file.lastModifiedDate).getTime();
        var currTime = Date.now();
        if (diff_minutes(imgTime, currTime) > 5) {
            setColordata("danger");
            setMessage("Upload latest image")
            setShowToast1(true)
        }
        else {
            if (dataa == undefined) {
                setImage2(event.target.files[0])
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                    let filename: string = file.name + "  " + file.lastModifiedDate;
                    let nameOnly = [];
                    let name = file.name;
                    nameOnly = name.split(".");
                    let timestamp = file.lastModifiedDate;
                    timestamp = timestamp.toString().split('GMT')
                    var number = Math.floor(Math.random() * (max - min + 1)) + min;
                    setValue('postFile1', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                    setImage2Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                    setFileList1({ id: "", fileName: filename });
                    if (isOpen1) {
                        toggleModal1()
                    }
                };
                reader.onerror = (error) => {
                };
            }
            else {
                if (computeDistance([lat1, lon1], [dataa.latitude, dataa.longitude]) > 2) {
                    setColordata("danger");
                    setMessage("out of range")
                    setShowToast1(true)
                }
                else {
                    setImage2(event.target.files[0])
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                        let blobURL: string = URL.createObjectURL(blob);
                        let filename: string = file.name + "  " + file.lastModifiedDate;
                        let nameOnly = [];
                        let name = file.name;
                        nameOnly = name.split(".");
                        let timestamp = file.lastModifiedDate;
                        //timestamp=timestamp.toString().replace(/\s/g,"")
                        timestamp = timestamp.toString().split('GMT')
                        var number = Math.floor(Math.random() * (max - min + 1)) + min;
                        setValue('postFile1', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                        setImage2Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                        setFileList1({ id: "", fileName: filename });
                        if (isOpen1) {
                            toggleModal1()
                        }
                    };
                    reader.onerror = (error) => {
                    };
                }
            }
        }
    };
    // capture images 
    const onImageClick1 = (event: any) => {
        event.target.value = ''
    }
    //render list for file of images
    const renderList1 = () => {
        return (
            <div className="uploaded-file">
                <p className="uploaded-file-name read-only" style={{ width: "100%" }} >{fileList1.fileName}</p>
                <IonButton fill="clear" onClick={() => setRemoveFile1(true)}>
                    <IonIcon icon={close} />
                </IonButton>
                <IonAlert
                    isOpen={removeFile1}
                    onDidDismiss={() => setRemoveFile1(false)}
                    cssClass='red-alert'
                    mode='md'
                    header={'Remove File'}
                    message={'<p>Are you sure you want to remove this file?</p>'}
                    buttons={[
                        {
                            text: 'Yes',
                            cssClass: 'btn-secondary',
                            handler: () => {
                                handleRemoveClick1();

                            }
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'btn-outline',
                            handler: () => {
                            }
                        }
                    ]} />
            </div>
        );
    }
    //passing the props
    const props = {
        type: "sand",
        Isupdate: "true",
        doNothing: doNothing,
        isOpen: isOpen,
        toggleModal: toggleModal,
        toggleModal1: toggleModal1,
        fileInput: fileInput,
        fileInput01: fileInput01,
        fileInput1: fileInput1,
        fileInput2: fileInput12,
        loadImageFromDevice: loadImageFromDevice,
        loadImageFromDevice1: loadImageFromDevice1,
        onImageClick: onImageClick,
        onImageClick1: onImageClick1,
        isOpen1: isOpen1,
        backPage: backPage,
        projectName: projectName,
        categoryName: categoryName,
        methods: methods,
        handleSubmit: handleSubmit,
        onSubmit: onSubmit,
        materialListData: materialListData,
        materialItem: materialItem,
        errors: errors,
        onChange: onChange,
        setValue: setValue,
        control: control,
        createNewMaterial: createNewMaterial,
        truckListData: truckListData,
        createNewTruck: createNewTruck,
        materialUnit: materialUnit,
        fileList: fileList,
        fileList1: fileList1,
        renderList: renderList,
        renderList1: renderList1,
        showToast1: showToast1,
        setShowToast1: setShowToast1,
        colordata: colordata,
        isloading: isloading,
        message: message,
        setHeightErrorMsg: setHeightErrorMsg,
        calculateQuantitySand: calculateQuantitySand,
        heightError: heightError,
        heightErrorMsg: heightErrorMsg,
        setHeightError: setHeightError,

    };

    return (
        Inward(props)

    );
};

export default InwardEntrySand;
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonSelect, IonSelectOption, IonToast } from '@ionic/react';
import { personCircleSharp, phonePortraitSharp, mailSharp, informationCircle } from 'ionicons/icons';
import Header from "../components/Header/Header";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { Tooltip } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";

const InviteUser: React.FC = (flag) => {
    const [pass, setPass] = useState("")
    //Function to generate random passwords
    function pwdGenerator(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        setPass(result)
        return result;
    }
    const methods = useForm();
    const { handleSubmit, setValue, getValues, control, formState: { errors } } = methods;
    let history = useHistory();
    //Function for page back
    const pageBack = () => {
        // history.go(-1);
        history.push('/users');
    };
    const workspaceId = localStorage.getItem('workspaceId');
    const ownerStatus = localStorage.getItem("ownerStatus")
    const userRole = localStorage.getItem('work_roles');
    const userIdData = localStorage.getItem('userid');
    const [permission, setPermission] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    let permissions = ""
    let userid: any = localStorage.getItem("userid");
    let token: any = localStorage.getItem("token");


    const [buttonDisable, setButtonDisable] = useState(false)
    //Function that handles the form submit 
    const onSubmit = async (data: any) => {
        setButtonDisable(true)

        var registerRoles: string;
        switch (data.role) {
            case "null":
            case "builder":
                registerRoles = "builder";
                break;
            case "manager":
                registerRoles = "manager";
                break;
            case "site":
                registerRoles = "site";
                break;
            case "supervisor":
                registerRoles = "supervisor";
                break;
            case "stock":
                registerRoles = "stock";
                break;
            case "sitePlus":
                registerRoles = "sitePlus";
                break;
            case "supervisorPlus":
                registerRoles = "supervisorPlus";
                break;
            case "stockPlus":
                registerRoles = "stockPlus";
                break;
            default:
                registerRoles = "builder";
                break;
        }
        try {
            const user = await axios({
                method: 'get',
                url: ENV.ENDPOINT + '/users?filters[email][$eq]=' + data.email,
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            if (user.data.length == 0) {
                try {
                    const response = await axios({
                        method: 'get',
                        url: ENV.ENDPOINT + '/users?filters[mobileNumber][$eq]=' + data.telephone,
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    if (response.data.length != 0) {
                        setColordata("danger");
                        setButtonDisable(false)
                        setMessage("Mobile is already register with different user in database.For existing user,please enter email id and mobile number correctly");
                        setShowToast1(true)
                    }
                    else {
                        let username = data.firstName + data.lastName;
                        try {
                            const response = await axios({
                                method: 'get',
                                url: ENV.ENDPOINT + '/users?filters[username][$eq]=' + username,
                                headers: {
                                    Authorization: HeaderAuthorization.Authorization,
                                    userId: userid,
                                    token: token,
                                },
                            })
                            if (response.data.length != 0) {
                                setColordata("danger");
                                setButtonDisable(false)
                                setMessage("Username is already taken.For existing user,please enter email id and mobile number correctly");
                                setShowToast1(true)
                            }
                            else {
                                var passs = pwdGenerator(8);
                                try {
                                    const response = await axios({
                                        method: 'POST',
                                        url: ENV.ENDPOINT + '/auth/local/register',
                                        data: {
                                            'username': data.firstName + data.lastName,
                                            'firstName': data.firstName,
                                            'lastName': data.lastName,
                                            'mobileNumber': data.telephone,
                                            'email': data.email,
                                            'user_roles': registerRoles,
                                            'password': passs,
                                            'logged_status': false,
                                        },
                                        headers: {
                                            Authorization: HeaderAuthorization.Authorization,
                                            userId: userid,
                                            token: token,
                                        },
                                    })
                                    setColordata("success");
                                    setMessage("User invited successfully");
                                    setShowToast1(true)
                                    await axios({
                                        method: 'POST',
                                        url: ENV.ENDPOINT + '/user-workspaces',
                                        data: {
                                            "data": {
                                                workuserId: workspaceId,
                                                workspace_id: workspaceId,
                                                user_status: "Pending",
                                                user_id: response.data.user.id,
                                                owner: false,
                                                user_role: data.role,
                                                invited_by: userIdData,
                                            }
                                        },
                                        headers: {
                                            Authorization: HeaderAuthorization.Authorization,
                                            userId: userid,
                                            token: token,
                                        },
                                    })
                                    await axios({
                                        method: 'POST',
                                        url: ENV.ENDPOINT + '/otps/createotp',
                                        data: {
                                            "data": {
                                                Mobile_Number: '91' + getValues('telephone'),
                                                email: getValues('email'),
                                                Genrate_Message: (`Following are the link for invite user: ${ENV.WebsiteURL}/index`),
                                                pwd: passs,
                                            }
                                        },
                                        headers: {
                                            Authorization: HeaderAuthorization.Authorization,
                                            userId: userid,
                                            token: token,
                                        },
                                    })
                                    history.go(-1)
                                    history.push('/users');
                                }
                                catch (error: any) {
                                    setButtonDisable(false)
                                    console.log(error);
                                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                                        Logout(history)
                                    }

                                    setColordata("danger");
                                    setMessage("Error Occurred");
                                    setShowToast1(true)
                                }
                            }
                        } catch (error: any) {
                            setColordata("danger");
                            setMessage("User Name is Invalid");
                            setShowToast1(true)
                            setButtonDisable(false)

                            console.log(error);
                            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                                Logout(history)
                            }
                        }

                    }

                } catch (error: any) {
                    console.log(error);
                    setButtonDisable(false)
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }
            else {
                if (data.telephone != user.data[0].mobileNumber) {
                    setColordata("danger");
                    setMessage("For existing user.Please enter mobile number correctly");
                    setButtonDisable(false)
                    setShowToast1(true);
                }
                else {
                    try {
                        const resdata = await axios({
                            method: 'GET',
                            url: ENV.ENDPOINT + '/user-workspaces?populate=*&filters[user_id][id][$eq]=' + user.data[0].id + '&filters[workspace_id][id][$eq]=' + workspaceId + '&filters[$or][0][user_status][$eq]=Active&filters[$or][1][user_status][$eq]=Pending',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })
                        if (resdata.data.data.length == 0) {
                            try {
                                await axios({
                                    method: 'POST',
                                    url: ENV.ENDPOINT + '/user-workspaces',
                                    data: {
                                        "data": {
                                            workspace_id: workspaceId,
                                            user_status: "Pending",
                                            user_id: user.data[0].id,
                                            owner: false,
                                            user_role: data.role,
                                            invited_by: userIdData,
                                        }
                                    },
                                    headers: {
                                        Authorization: HeaderAuthorization.Authorization,
                                        userId: userid,
                                        token: token,
                                    },
                                })

                                setColordata("success");
                                setMessage("User invited successfully");
                                setShowToast1(true)

                                await axios({
                                    method: 'POST',
                                    url: ENV.ENDPOINT + '/otps/createotp',
                                    data: {
                                        "data": {
                                            Mobile_Number: '91' + getValues('telephone'),
                                            email: getValues('email'),
                                            Genrate_Message: (`Following are the link for invite user:${ENV.WebsiteURL}/index`),
                                        }
                                    },
                                    headers: {
                                        Authorization: HeaderAuthorization.Authorization,
                                        userId: userid,
                                        token: token,
                                    },
                                })
                                history.go(-1);
                                history.push('/users');
                            } catch (error: any) {
                                console.log(error);
                                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                                    Logout(history)
                                }
                            }

                        } else {
                            setColordata("danger");
                            setMessage("User already exist in same workspace");
                            setShowToast1(true);
                            setButtonDisable(false)
                        }

                    } catch (error: any) {
                        setButtonDisable(false)
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }

                }
            }

        } catch (error: any) {
            setButtonDisable(false)
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    };
    const doNothing = () => {
        history.goBack();
    }
    useEffect(() => {
        if (userRole == "null") {
            permissions = "0";
            setPermission(permissions);
        }
        else if (userRole == null) {
            permissions = "0";
            setPermission(permissions);
        }
        else if (userRole == "builder") {
            permissions = "0";
            setPermission(permissions);
        }
        else if (userRole == "manager") {
            permissions = "1";
            setPermission(permissions);
        }
        else if (userRole == "site") {
            permissions = "2";
            setPermission(permissions);
        }
        else if (userRole == "supervisor") {
            permissions = "3";
            setPermission(permissions);
        }
        else if (userRole == "stock") {
            permissions = "4";
            setPermission(permissions);
        }
    })

    //Render JSX component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="login-form-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="back-arrow">
                                <IonButton className="ion-text-right" fill="clear" onClick={pageBack}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <h3>Invite User</h3>
                            </div>
                            <FormProvider {...methods}>
                                <form className="login-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                    <IonRow>
                                        <IonCol size="12" className="email-field">
                                            <IonLabel className="form-lable">First Name*</IonLabel>
                                            <div className="input-with-icon">
                                                <IonIcon icon={personCircleSharp} />
                                                <Controller
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <IonInput
                                                            type="text"
                                                            onIonChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                                            placeholder="Add Text"
                                                            mode="md" />
                                                    )}
                                                    control={control}
                                                    name="firstName"
                                                    rules={{
                                                        required: "First Name is required",
                                                        minLength: {
                                                            value: 3,
                                                            message: "First Name should have minimum 3 character."
                                                        },
                                                        maxLength: {
                                                            value: 25,
                                                            message: "First Name should not exceed 25 character."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z ]*$/i,
                                                            message: "Enter a valid name",

                                                        }
                                                    }} />
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="firstName"
                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                        </IonCol>
                                        <IonCol size="12" className="password-field">
                                            <IonLabel className="form-lable">Last Name*</IonLabel>
                                            <div className="input-with-icon">
                                                <IonIcon icon={personCircleSharp} />
                                                <Controller
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <IonInput
                                                            type="text"
                                                            onIonChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                                            placeholder="Add Text"
                                                            mode="md" />
                                                    )}
                                                    control={control}
                                                    name="lastName"
                                                    rules={{
                                                        required: "Last Name is required",
                                                        minLength: {
                                                            value: 3,
                                                            message: "Last Name should have minimum 3 character."
                                                        },
                                                        maxLength: {
                                                            value: 25,
                                                            message: "Last Name should not exceed 25 character."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z ]*$/i,
                                                            message: "Enter a valid name",

                                                        }
                                                    }} />
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="lastName"
                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                        </IonCol>
                                        <IonCol size="12" className="forget-password">
                                            <IonLabel className="form-lable">Mobile No.*</IonLabel>
                                            <div className="input-with-icon">
                                                <IonIcon icon={phonePortraitSharp} />
                                                <Controller
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <IonInput
                                                            type="number"
                                                            onIonChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                                                            placeholder="Add Text"
                                                            mode="md" />
                                                    )}
                                                    control={control}
                                                    name="telephone"
                                                    rules={{
                                                        required: "Telephone is required",
                                                        minLength: {
                                                            value: 10,
                                                            message: "Please enter a valid phone number"
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: "Please enter a valid phone number"
                                                        }
                                                    }} />
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="telephone"
                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                        </IonCol>
                                        <IonCol size="12" className="email-field">
                                            <IonLabel className="form-lable">Email*</IonLabel>
                                            <div className="input-with-icon">
                                                <IonIcon icon={mailSharp} />
                                                <Controller
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <IonInput
                                                            onIonChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                            placeholder="Add Text"
                                                            mode="md" />
                                                    )}
                                                    control={control}
                                                    name="email"
                                                    rules={{
                                                        required: "Email required field",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    }} />
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="email"
                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                        </IonCol>
                                        <IonCol size="12" className="">
                                            <div className="tooltip-container">
                                                <IonLabel className="form-lable note-role">Role:*
                                                </IonLabel>
                                                {(permission == "0" || permission == "1" || ownerStatus == "true") ?
                                                    <Tooltip
                                                        placement="topLeft"
                                                        title="The Plus roles have their respective role access. With that they also have permission to edit inward and outward entries">
                                                        <IonIcon
                                                            icon={informationCircle} />
                                                    </Tooltip>
                                                    : null}
                                            </div>
                                            <Controller
                                                render={({ field }) => (
                                                    <IonSelect
                                                        placeholder="Select One"
                                                        value={field.value}
                                                        className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                                                        onIonChange={(e) => setValue('role', e.detail.value)}  >
                                                        {(permission == "0" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="builder">
                                                                Builder
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="manager">
                                                                Project Manager
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || permission == "2" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="site">
                                                                Site Incharge
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="sitePlus">
                                                                Site Incharge Plus
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || permission == "2" || permission == "3" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="supervisor">
                                                                Supervisor/Engineer
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="supervisorPlus">
                                                                Supervisor/Engineer Plus
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || permission == "2" || permission == "3" || permission == "4" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="stock">
                                                                Stock Manager
                                                            </IonSelectOption>) : null}
                                                        {(permission == "0" || permission == "1" || ownerStatus == "true") ? (
                                                            <IonSelectOption value="stockPlus">
                                                                Stock Manager Plus
                                                            </IonSelectOption>) : null}
                                                    </IonSelect>
                                                )}
                                                control={control}
                                                name="role"
                                                rules={{ required: 'Please select role' }} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="role"
                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                        </IonCol>

                                        <IonCol size="8" className="ion-align-self-center ion-margin-auto ion-text-center ion-margin-top">

                                            <IonButton type="submit" fill="solid" expand="block" disabled={buttonDisable}>
                                                Invite
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </form>
                            </FormProvider>
                        </IonCol>
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={2000}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default InviteUser;
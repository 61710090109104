import { useState, useEffect } from "react";

export default function useAuth(initialValue: boolean) {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isLoggedIn") ? true : false);
  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") == "true") {
      const interval = setInterval(() => {
        setIsAuth(true);
      }, 1000);
      return () => clearInterval(interval);
    }
    else {
      const interval = setInterval(() => {
        setIsAuth(true);
      }, 1000);
      return () => clearInterval(interval);
    }
  });
  return [isAuth];
}

import { IonApp, IonRouterOutlet,  setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  Route,Switch } from 'react-router-dom';
import {protected_routes,routes} from './routes';
import { Plugins, Capacitor } from "@capacitor/core";
import ProtectedRoute from './pages/ProtectedRoutes';
import useAuth from './pages/auth';
import Home from './pages/Splash';
import Login from './pages/Index';
import { useEffect } from "react";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import ResetPassword from './pages/Reset-Password';
import PrivacyTerms from './pages/privacy-terms';
import withAuthentication from './components/Authentication/index';
import '../src/assets/css/variables.css'

export const Routes = {
  indexURL: '/splash',
  login:'/index',
}

setupIonicReact();

const App: React.FC = () => {
  const [isAuth] = useAuth(false)
  
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e:any) => {
        if (window.location.pathname == "/") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure to exit app?");
          if (ans) {
            Plugins.App.exitApp();
          } else{
             window.location.reload();
          }
        } 
      });
      
    }
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet id="main">
        <Switch>
        <Route path="/" exact={true}>
            <Home />
        </Route>
        <Route exact path={Routes.login}>
            <Login />
        </Route> 
        <Route path="/reset-password/:id" component={ResetPassword}/>
        <Route path="/privacy-terms" component={PrivacyTerms}/>
       
        {/* For protedcted routes  */}
        {Object.entries(protected_routes).map(([path,component],index) => (
                        <ProtectedRoute key={index} path={path} component={component} auth={isAuth}/>  

        ))}
        {/* For Non protedcted routes  */}
        {Object.entries(routes).map(([path,component],index) => (
            <Route key={index} path={path} component={component} />
        ))}  
        </Switch>
        </IonRouterOutlet>
      </IonReactRouter>     
    </IonApp>
  );
};

export default App;

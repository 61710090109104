import { ENV } from '../config/config';
import { IonContent, IonPage, IonLabel, IonInput, IonGrid, IonRow, IonCol, IonToast } from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { RouteComponentProps, useHistory } from "react-router-dom";
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useState } from 'react';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonImgButton from '../components/Button/IonImgButton';
import SubmitButton from '../components/Button/SubmitButton';

// Define the route props for the component
interface resetPasswordPageProps
   extends RouteComponentProps<{
      id: string;
   }> { }
const ResetPassword: React.FC<resetPasswordPageProps> = ({ match }) => {
   let history = useHistory();
   // Initialize react-hook-form
   const methods = useForm();
   const { handleSubmit, getValues, control, formState: { errors } } = methods;
   // Get the username from local storage
   let username = localStorage.getItem('username');
   // State to manage the toast message and color   
   const [message, setMessage] = useState('');
   const [showToast1, setShowToast1] = useState(false);
   const [colordata, setColordata] = useState('');
   // Function to handle form submission
   const onSubmit = async (data: any) => {
      let userId = window.location.pathname.split("/").pop();
      try {
         await axios({
            method: "PUT",
            url: ENV.ENDPOINT + '/users/' + userId,
            data: {
               identifier: username,
               password: data.password1,
            },
            headers: {
               Authorization: HeaderAuthorization.Authorization,
               PageLocation: "reset",
            },
         })

         setColordata("success")
         setMessage("Password Reset successfully");
         setShowToast1(true)
         history.push('/index')

      } catch (error) {
         console.log(error);

      }

   };

   //Render JSX Component
   return (
      <IonPage>
         <IonContent fullscreen>
            <IonGrid className="full-height-div">
               <IonRow className="login-form-row">
                  <IonCol size="12" sizeMd="6" sizeLg="4">
                     <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="main-logo">
                              <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />
                           </div>
                           <h2 className='ion-text-center'>Create new password</h2>
                           <p>Your new password must be different from previous used password.</p>
                           <IonRow>
                              <IonCol size="12" className="ion-margin-top">
                                 <IonLabel className="form-lable">Enter new password:*</IonLabel>
                                 <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                       <IonInput
                                          type="password"
                                          onIonChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          className={`form-control ${errors.password1 ? 'is-invalid' : ''}`}
                                          placeholder=""
                                          mode="md" />
                                    )}
                                    control={control}
                                    name="password1"
                                    rules={{
                                       required: 'Password must contain at least 1 number and 1 uppercase and lowercase letter, and at 8 to 12 characters long',
                                       pattern: {
                                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/i,
                                          message: 'Password must contain at least 1 number and 1 uppercase and lowercase letter, and at 8 to 12 characters long'
                                       }
                                    }} />
                                 <ErrorMessage
                                    errors={errors}
                                    name="password1"
                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                              </IonCol>
                              <IonCol size="12" className="ion-margin-top">
                                 <IonLabel className="form-lable">Confirm new password:</IonLabel>
                                 <Controller
                                    render={({ field: { onChange, onBlur, value } }) => (
                                       <IonInput
                                          type="password"
                                          onIonChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          className={`form-control ${errors.password2 ? 'is-invalid' : ''}`}
                                          placeholder=""
                                          mode="md" />
                                    )}
                                    control={control}
                                    name="password2"
                                    rules={{
                                       validate: {
                                          noMatch: (value: string) => {
                                             return value != getValues("password1")
                                                ? "Passwords do not match"
                                                : undefined;
                                          },
                                       },
                                    }} />
                                 <ErrorMessage
                                    errors={errors}
                                    name="password2"
                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                              </IonCol>
                              <SubmitButton size="12" ColclassName="ion-margin-top" ButtonclassName="secondary-button" text="Reset Password" />
                           </IonRow>
                        </form>
                     </FormProvider>
                  </IonCol>
               </IonRow>
               {/* Toast message */}
               <IonToast
                  isOpen={showToast1}
                  onDidDismiss={() => setShowToast1(false)}
                  message={message}
                  duration={2000}
                  position="top"
                  color={colordata} />
            </IonGrid>
         </IonContent>
      </IonPage>
   );
};

export default ResetPassword;
// Importing necessary modules from the React library
import React from "react";

// Importing the custom IonImgButton component from the specified path
import IonImgButton from "../Button/IonImgButton";

// Defining a functional component called HeaderLogo that accepts any props
const HeaderLogo: React.FC<any> = () => {
    return (
        // Wrapping the logo inside a div with the class "main-logo"
        <div className="main-logo">
            {/* Rendering the custom IonImgButton component */}
            {/* The IonImgButton component is configured to navigate to "/" when clicked */}
            {/* It displays an image with the class "logo" and the specified image source */}
            <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />
        </div>
    );
};

// Exporting the HeaderLogo component to make it available for use in other parts of the application
export default HeaderLogo;

//Import All modules
import { ErrorMessage } from "@hookform/error-message"
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLabel, IonSelect, IonSelectOption, IonInput, IonTextarea, IonToast, IonBreadcrumb, IonBreadcrumbs } from "@ionic/react"
import { FormProvider, Controller } from "react-hook-form"
import Header from "../../components/Header/Header"


const Outward = (props: any) => {
    // Take all props
    const {
        doNothing,
        backPage,
        projectName,
        categoryName,
        methods,
        handleSubmit,
        onSubmit,
        materialListData,
        materialItem,
        errors,
        onChange,
        setValue,
        control,
        materialUnit,
        showToast1,
        setShowToast1,
        colordata,
        message,
        brandListData,
        purposeListData,
        outwardPurposeEnable,
        setWings,
        buildingListData,
        wing,
        setFlat,
        newWings,
        isSubmitting,
        flat,
        flatNos,
        getValues
    } = props

    //Check the route state and show bread crumbs

    const routeStateUnFor: any = localStorage.getItem("routeState");
    let routeState;

    if (routeStateUnFor) {
        routeState = JSON.parse(routeStateUnFor);
    }

    const breadCrumbs = [];
    if (!routeState?.editStatus) {
        if (routeState) {
            if (routeState?.subCategory) {
                if (routeState?.subCategory.length > 0) {

                    routeState?.subCategory.map((item: any) => {
                        if (!(JSON.stringify(item) == JSON.stringify({}))) {
                            breadCrumbs.push(item.categoryName);
                        }
                    });
                }
            }
            if (routeState?.categoryName) {
                breadCrumbs.push(routeState.categoryName);
            }
        }
    }


    const lowMaterialName = materialItem.toLowerCase();

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Outward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <div>
                                <IonBreadcrumbs>{
                                    breadCrumbs.map((breadCrumb, index) => {
                                        return (
                                            <IonBreadcrumb key={index}>{breadCrumb}</IonBreadcrumb>
                                        )
                                    })
                                }</IonBreadcrumbs>
                            </div>
                            <h2 className="category-name category-name-padding">Outward Entry {categoryName}</h2>
                            <FormProvider {...methods}>
                                <form className="material-add-form" onSubmit={handleSubmit(onSubmit)}>
                                    {(materialListData.length == 1 && materialListData[0].material_item_name == categoryName) ? "" : (
                                        <IonRow className="">
                                            <IonCol size="3">
                                                <IonLabel className="form-lable material-lable">{materialItem}:*</IonLabel>
                                            </IonCol>
                                            <IonCol size="9">
                                                <IonRow className="material-select-row ion-align-items-center">
                                                    <IonCol size="12" className="ion-no-padding">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <IonSelect
                                                                    placeholder={materialListData.length ? "Select One" : `Please Add ${lowMaterialName}`}
                                                                    value={field.value}
                                                                    className={`form-control ${errors.material ? 'is-invalid' : ''}`}
                                                                    onIonChange={(e: any) => {
                                                                        setValue('material', e.detail.value)
                                                                        onChange(e.detail.value, "material")
                                                                    }}  >
                                                                    {materialListData.map((item: any, index: number) => (
                                                                        <IonSelectOption key={index} value={item.id}>
                                                                            {item.material_item_name}
                                                                        </IonSelectOption>
                                                                    ))}
                                                                </IonSelect>
                                                            )}
                                                            control={control}
                                                            name="material"
                                                            rules={{ required: `Please select ${lowMaterialName}` }} />
                                                    </IonCol>
                                                    <IonCol size="12" className="ion-no-padding">
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="material"
                                                            as={<div className="error-message" style={{ color: 'red' }} />} />
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>)}
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Brand:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow className="material-select-row ion-align-items-center">
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field }) => (
                                                            <IonSelect
                                                                placeholder={brandListData.length ? "Select One" : `Please Add Brand`}
                                                                value={field.value}
                                                                className={`form-control ${errors.brand ? 'is-invalid' : ''}`}
                                                                onIonChange={(e: any) => {
                                                                    setValue('brand', e.detail.value)
                                                                    onChange(e.detail.value, "brand")
                                                                }} >
                                                                {brandListData.map((item: any, index: number) => (
                                                                    <IonSelectOption key={index} value={item.id}>
                                                                        {item.material_brand_name}
                                                                    </IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        )}
                                                        control={control}
                                                        name="brand"
                                                        rules={{ required: 'Please select brand' }} />
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="brand"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Purpose:</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow className="material-select-row ion-align-items-center">
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field }) => (
                                                            <IonSelect
                                                                placeholder="Select One"
                                                                value={field.value}
                                                                className={`form-control ${errors.purpose ? 'is-invalid' : ''}`}
                                                                onIonChange={(e) => {
                                                                    setValue('purpose', e.detail.value)
                                                                    onChange(e.detail.value, "purpose")
                                                                }} >
                                                                {purposeListData.map((item: any, index: number) => (
                                                                    <IonSelectOption key={index} value={item.id}>
                                                                        {item.attributes.material_purpose_name}
                                                                    </IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        )}
                                                        control={control}
                                                        name="purpose"
                                                    // rules={{ required: 'Please select purpose' }} 
                                                    />
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="purpose"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    {outwardPurposeEnable ?
                                        <IonRow className="">
                                            <IonCol size="4">
                                                <IonLabel className="form-lable">Building*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.building ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                setWings(true)
                                                                setValue('building', e.detail.value)
                                                                onChange(e.detail.value, "building")
                                                            }}  >
                                                            {buildingListData.filter((item: any, index: any, self: any) => {
                                                                return index === self.findIndex((o: any) => {
                                                                    return o.attributes.project_building
                                                                        === item.attributes.project_building
                                                                });
                                                            }).map((item: any, index: number) => (
                                                                <IonSelectOption key={index} value={item.attributes.project_building}>
                                                                    {item.attributes.project_building}

                                                                </IonSelectOption>
                                                            ))};
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name={'building'}
                                                    rules={{
                                                        required: "Please enter building number."
                                                    }} />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="building"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonLabel className="form-lable">Wing*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (
                                                        <IonSelect
                                                            disabled={wing ? false : true}
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.wing ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {
                                                                onChange(e.detail.value, "wing")
                                                                setValue('wing', e.detail.value)
                                                                setFlat(true)
                                                            }}
                                                        >
                                                            {newWings.sort((a: any, b: any) => a.localeCompare(b)).map((item: any, index: number) => (
                                                                <IonSelectOption key={index} value={item}>
                                                                    {item}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name={'wing'}
                                                    rules={{
                                                        required: "Please enter wing number."
                                                    }}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="wing"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonLabel className="form-lable">Flat No*</IonLabel>
                                                <Controller
                                                    render={({ field }) => (

                                                        <IonSelect
                                                            disabled={flat ? false : true}
                                                            placeholder="Select One"
                                                            value={field.value}
                                                            className={`form-control ${errors.flat ? 'is-invalid' : ''}`}
                                                            onIonChange={(e: any) => {

                                                                setValue('flat', e.detail.value)
                                                            }}  >
                                                            {flatNos.sort((a: any, b: any) =>
                                                                a.attributes.project_flatno - b.attributes.project_flatno
                                                            ).map((item: any, index: number) => (

                                                                <IonSelectOption key={index} value={item.attributes.project_flatno}>
                                                                    {item.attributes.project_flatno}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    )}
                                                    control={control}
                                                    name={'flat'}
                                                    rules={{
                                                        required: "Please enter flat number."
                                                    }}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="flat"
                                                    as={<div className="error-message" style={{ color: 'red' }} />} />
                                            </IonCol>
                                        </IonRow> : ""}
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Qty:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="4" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonInput
                                                                type="number"
                                                                onIonChange={(e: any) => { setValue('quantity', e.detail.value) }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md" />
                                                        )}
                                                        control={control}
                                                        name="quantity"
                                                        rules={{
                                                            required: "Please add quantity"
                                                        }} />
                                                </IonCol>
                                                <IonCol size="4" className="ion-no-padding ion-padding-start">
                                                    <p>{materialUnit}</p>
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="quantity"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Note:</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonTextarea
                                                                onIonChange={(e: any) => { setValue('Notes', e.detail.value) }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.Notes ? 'is-invalid' : ''}`}
                                                                placeholder="Add text" />
                                                        )}
                                                        control={control}
                                                        name="Notes" />
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="Notes"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="12" className="ion-text-end">
                                            <IonButton className="" type="submit" fill="solid" disabled={isSubmitting}>
                                                Issue
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </form>
                            </FormProvider>
                            <IonToast
                                isOpen={showToast1}
                                onDidDismiss={() => setShowToast1(false)}
                                message={message}
                                duration={1500}
                                position="middle"
                                color={colordata} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Outward;
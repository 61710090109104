import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {IonContent, IonPage,IonIcon,IonButton,IonLabel, IonGrid,IonRow, IonCol, IonCard, IonCardHeader,IonCardContent, IonList, IonItem,IonAlert, useIonViewWillEnter} from '@ionic/react';
import { arrowForwardCircleSharp, removeCircleSharp } from 'ionicons/icons';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import Header from '../components/Header/Header';
import axios from 'axios';
import { ENV } from '../config/config'
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Loader from "../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { setWorkspaceId, setWorkspaceName } from "../features/workspace/workspaceSlice";
import Logout from "../components/Logout";
const SelectWorkspace: React.FC = () => {
  const [subscribe, setSubscribe] = useState(false)
  const [removeFile, setRemoveFile] = useState(false);
  const [invite, setInvite] = useState(false);
  const [userworkspaceID, setUserWorkspaceID] = useState()
  const [workspaceID, setWorkspaceID] = useState<any>()
  const [ownerStatus, setOwnerStatus] = useState()
  const dispatch = useDispatch();


  const doNothing = () => {
    history.goBack();
  }

  let history = useHistory();
  const logOut = async () => {   

    let sessionId:any = localStorage.getItem("token")
        let userid:any = localStorage.getItem("userid");


        try {
         const sessionExists= await axios({
            method: 'get',
            url: ENV.ENDPOINT + `/sessions/${sessionId}`,
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userid,
          },            })
          if(sessionExists){
             await axios({
              method: 'delete',
              url: ENV.ENDPOINT + `/sessions/${sessionId}`,
              headers: {
                Authorization : HeaderAuthorization.Authorization,
                userId:userid,
            },            })
  
          }
         localStorage.clear();
         history.push('/index')
         window.location.reload();
        } catch (error) {
         console.log(error);
         localStorage.clear();
         history.push('/index')
         window.location.reload();
        }

  }
  const userid = localStorage.getItem('userid');
  const user_roles = localStorage.getItem('user_roles');
  const token: any = localStorage.getItem('token');

  const [isloading, setIsloading] = useState(true);
  const [workSpaceListData, setWorkSpaceListData] = useState([
    { id: 1, attributes: { "workspace_id": { "data": { "id": 1, "attributes": { "workspace_name": "" } } } } },
  ]);
  const loaderRef = useRef(null);
  const [endReach , setEndReach] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [inviteListData, setInviteListData] = useState([
    { id: 1, attributes: { "workspace_id": { "data": { "id": 1, "attributes": { "workspace_name": "" } } } } },
    { id: 2, attributes: { "workspace_id": { "data": { "id": 2, "attributes": { "workspace_name": "" } } } } },
  ]);
  const [mobileNumberData, setMobileNumberData] = useState("");
  const [shouldRedirect, setshouldRedirect] = useState(true);
  const userId:any = localStorage.getItem('userid');

  React.useEffect(() => {
    const fetchData = async () => {
     
        try {
          const result = await fetch(ENV.ENDPOINT + "/user-workspaces?populate=workspace_id&filters[user_id][id][$eq]=" + userid + '&filters[$or][0][user_status][$eq]=Active&filters[$or][1][user_status][$eq]=Active',{
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userId,
              token: token,
          },           });
          const response = await result.json();
          const userListArr = [];
          for (const [index, value] of response.data.entries()) {
            localStorage.setItem("roles", value.attributes.user_role);
            
            userListArr.push({
              userRoles: value.attributes.user_role,
            });
          }
        } catch (error:any) {
          console.log(error);
          if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
            Logout(history)
          }
        }
      // }
  
    }

   

    const myData = async () => {
     
      var url = ENV.ENDPOINT + "/users/" + userid;
      try {
        let userid = localStorage.getItem("userid");
        var url = ENV.ENDPOINT + "/users/" + userid;
        const response = await fetch(url,{
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userId,
            token: token,
        }, 
        });
        const responseData = await response.json();
        setMobileNumberData(responseData.mobileNumber)
        return responseData;

      } catch (error:any) {
        console.log(error);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      }
      }
 
 
    fetchData();
    myData();

    return () =>{
      setMobileNumberData("")
    }

  }, [subscribe]);


   React.useEffect(() => {
    const fetchData = async () =>{
      try {
        getWorkspace(1)
        
    
      } catch (error:any) {
        console.log(error);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      }
    }

    const myData = async () =>{
      try {
        const data = await getWorkspaceInvite()
          if (data['data'].length > 0) {
            setInvite(true);
          }
          setInviteListData(data['data']);      
      } catch (error:any) {
        console.log(error);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      }
    }
    fetchData();
    myData();
  },[]);


  const getWorkspace = async (page:number) => {
    if(window.location.href.split("/")[3] === "select-workspace"){
      try {
 
          const response =  await axios({
            url: ENV.ENDPOINT + '/user-workspaces?populate=workspace_id&filters[user_id][id][$eq]=' + userid + '+&filters[user_status][$eq]=Active&pagination[page]=' +page,
            method: 'get',
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userId,
              token: token,
          },           })
     
          const data = response.data;
        if (data.data.length === 0) {
          setIsloading(false);
          setHasMoreData(false);
          setEndReach(true)
        }  
        else{
          setWorkSpaceListData((prevData:any) => [...prevData, ...data.data]);
          setCurrentPage(page);
        }
        setIsloading(false);
           return data;
       }
       catch (error:any) {
        setIsloading(false);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      } 
      
    }
  };

  const handleObserver = (entries:any) => {
    const target = entries[0];
    if (target.isIntersecting && !isloading) {
      const nextPage = currentPage + 1;
      getWorkspace(nextPage);
    }
  };

  React.useEffect(() => {
    if(!endReach){
      const options = {
        root: null,
        rootMargin: '1px',
        threshold: 0.1,
      };
  
      const observer = new IntersectionObserver(handleObserver, options);
  
      if (loaderRef.current) {
        
        observer.observe(loaderRef.current);
      }
      return () => {
        if (loaderRef.current) {
          observer.unobserve(loaderRef.current);
        }
      };
    }
  }, [currentPage, isloading, hasMoreData,endReach]);

  const getWorkspaceInvite = async () => {
    if(window.location.href.split("/")[3] === "select-workspace"){
      try {
        const response =await  axios({
          url: ENV.ENDPOINT + '/user-workspaces?populate=*&filters[user_id][id][$eq]=' + userid + '+&filters[user_status][$eq]=Pending',
          method: 'get',
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userId,
            token: token,
        },         })
          return response.data;
      } catch (error:any) {
        console.log(error);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      }
    }


  }
  const acceptInvite = async (data: any) => {
    history.push('/select-workspace')
    try {
      await axios({
        method: "PUT",
        url: ENV.ENDPOINT + "/user-workspaces/" + data,
        data: {
          "data": {
            user_status: "Active",
          }
        },
        headers: {
          Authorization : HeaderAuthorization.Authorization,
          userId:userId,
          token: token,
      },       })
          history.go(0)
      
    } catch (error:any) {
      console.log(error);
      if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
        Logout(history)
      }
    }
 
     
  }
  const createWorkspace = () => {
    history.push({
      pathname: '/create-new-workspace',
      state: { "status": "createWorkspace" }
    }
    );
  };
  const selectWorkspace = (data: any) => {
    const roleFromBackend = data.attributes.user_role
          if(roleFromBackend.includes('Plus')){
            const finalRole = roleFromBackend.replace("Plus", "");
            localStorage.setItem('work_roles', finalRole);
            localStorage.setItem('edit_permision', "true");
          }else{
            localStorage.setItem('work_roles', roleFromBackend);
            localStorage.setItem('edit_permision', "false");
          }
    localStorage.setItem('workspaceId', data.attributes.workspace_id.data.id)
  }
  const handleUnsubscribe = async (data: any, owner: any) => {
    if (owner) {
      const getUserWorkspaceList = async () => {
        try {
          const response = await axios({
            url: ENV.ENDPOINT + '/user-workspaces?populate=*&filters[workspace_id][id][$eq]=' + workspaceID,
            method: 'get',
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userId,
              token: token,
          },           });
          const array: any[] = [];
          response.data.data.forEach((data: any) => {
            if (data.attributes.user_status === 'Active') {
              array.push(data.id)
            }
  
          })
          for (const dataItem of array) {
          
              await axios({
                method: "PUT",
                url: `${ENV.ENDPOINT}/user-workspaces/${dataItem}`,
                data: {
                  "data": {
                    user_status: "Inactive",
                  }
                },
                headers: {
                  Authorization: HeaderAuthorization.Authorization,
                  userId: userId,
                  token: token,
                },
              });
           
          }

          history.go(0);
          return response.data;
        } catch (error:any) {
          console.log(error);
          if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
            Logout(history)
          }
        }

      };
      getUserWorkspaceList()
    }
    else {
      try {
        await axios({
          method: "PUT",
          url: ENV.ENDPOINT + "/user-workspaces/" + data,
          data: {
            "data": {
              user_status: "Inactive",
            }
          },
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userId,
            token: token,
        },         })
            history.go(0);

      } catch (error:any) {
        console.log(error);
        if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
          Logout(history)
        }
      }
    
      
    }
  }
  const handleRemoveClick = async () => {
    try {
      const response = await axios({
        url: ENV.ENDPOINT + '/user-workspaces?populate=*&filters[workspace_id][id][$eq]=' + workspaceID + '&filters[user_id][id][$eq]=' + userid + '&filters[user_status][$eq]=Active',
        method: 'get',
        headers: {
          Authorization : HeaderAuthorization.Authorization,
          userId:userId,
          token: token,
      },       })
        setUserWorkspaceID(response.data.data[0].id)
        setOwnerStatus(response.data.data[0].attributes.owner)
        if (response.data.data[0].attributes.owner) {
          handleUnsubscribe(workspaceID, response.data.data[0].attributes.owner)
        } else {
          handleUnsubscribe(response.data.data[0].id, response.data.data[0].attributes.owner)
        }
        return response.data;
  
    } catch (error:any) {
      console.log(error);
      if(error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409){
        Logout(history)
      }
    }
   
  };


  const redirectfunction = async (data:any) =>{
    history.push("/select-project/" + data)
  }



  return (
    <IonPage>
      <Header class="" onBack={doNothing} />
      <IonContent fullscreen>
        <IonGrid className="full-height-div">
          <IonRow className="main-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <IonCard>
                <IonCardHeader>
                  <div> <h6>Workspace for {mobileNumberData}</h6></div>
                  <div style={{ display: "flex", gap: "15px" }}><IonButton className="edit-workspace-button ion-text-right" fill="clear" onClick={() => {
                    if (subscribe) {
                      setSubscribe(false)
                    }
                    else {
                      setSubscribe(true)
                    }
                  }}>
                    {(user_roles === "null" || user_roles === "manager" || user_roles === "site" || user_roles === "builder") ? (
                      <IonIcon icon="/assets/images/edit-icon.svg" onClick={() => {
                        setSubscribe(true)
                      }}></IonIcon>
                    ) : ""}
                  </IonButton>
                  
                    <div> <IonButton className="ion-text-right" fill="clear" onClick={logOut}>
                      <IonIcon slot="icon-only" src="assets/images/logout-icon.svg" ></IonIcon>
                    </IonButton>
                    </div>
                  </div>
                </IonCardHeader>
                <IonCardContent>
                  <IonList mode="md" className="">
                    {workSpaceListData && workSpaceListData.map((item:any,index) => (

                      <div key={index}>
                         {
                      item?.attributes?.workspace_id?.data != null && item?.attributes?.workspace_id?.data?.attributes?.workspace_name != "" && 

                      <IonItem key={index} className="position-relative">
                      {subscribe ? (
                        <><IonLabel mode='md'>{item.attributes.workspace_id.data.attributes.workspace_name}</IonLabel><IonIcon icon={removeCircleSharp} slot="end" />
                          <IonButton className="abosolute-button-with-link" expand="full" fill="clear" slot="end" onClick={() => { setWorkspaceID(item.attributes.workspace_id.data.id); setRemoveFile(true); }}>
                          </IonButton>
                        </>
                      ) : (<>

                      
                        <IonLabel mode='md'>{item?.attributes?.workspace_id?.data?.attributes?.workspace_name}</IonLabel><IonIcon icon={arrowForwardCircleSharp} slot="end" />
                        <IonButton className="abosolute-button-with-link" expand="full" fill="clear" slot="end"  onClick={() => {
                          const id = item?.attributes?.workspace_id?.data?.id;
                          const name = item?.attributes?.workspace_id?.data?.attributes?.workspace_name;
                            if(name && id){
                              dispatch(setWorkspaceName(name));
                              dispatch(setWorkspaceId(id));
                            }
                           redirectfunction(item?.attributes?.workspace_id?.data?.id)
                           selectWorkspace(item) 
                           }}>
                        </IonButton>
                      </>
                      )}
                      <IonAlert
                        isOpen={removeFile}
                        onDidDismiss={() => setRemoveFile(false)}
                        cssClass='red-alert'
                        mode='md'
                        header={'Unsubscribe Workspace'}
                        message={'<p>Are you sure you want to unsubscribe from this workspace?</p>'}
                        buttons={[
                          {
                            text: 'Yes',
                            cssClass: 'btn-secondary',
                            handler: (e) => {
                              handleRemoveClick();
                            }
                          },
                          {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'btn-outline',
                            handler: () => {
                            }
                          }
                        ]} />
                    </IonItem>
                      }
                      </div>
                      

                    ))
                    
                  }
                       <div ref={loaderRef}> {(isloading && <Loader />)}</div>

                  </IonList>
                </IonCardContent>
              </IonCard>
              {(user_roles === "null" || user_roles === "manager" || user_roles == "site" || user_roles == "builder" || user_roles == "sitePlus") ? (
                <IonCard className="light-pink-card">
                  <h5>Want to create another Workspace?</h5>
                  <IonButton className="secondary-button" fill="solid" onClick={createWorkspace}>
                    Create Workspace
                  </IonButton>
                </IonCard>
              ) : ""}
              <h5 className="margin-bottom-invite">Pending Invitations</h5>
              <IonCard>
                <IonCardHeader className="card-header-without-icon">
                  <h6>Invitation for {mobileNumberData}</h6>
                </IonCardHeader>
                <IonCardContent>
                  {(invite) ? (
                    <IonList mode="md" className="list-with-seperate-button">
                      {inviteListData.map((item,index) => (
                        <IonItem key={index} className="position-relative">
                          <IonLabel mode='md'>{item.attributes.workspace_id.data.attributes.workspace_name}</IonLabel>
                          <IonButton fill="solid" onClick={() => acceptInvite(item.id)}>
                            Join
                          </IonButton>
                        </IonItem>
                      ))}
                    </IonList>) :
                    <IonList mode="md" className="list-with-seperate-button">
                      <IonItem className="position-relative">
                        <IonLabel mode='md'>No Pending Invitation</IonLabel>
                      </IonItem>
                    </IonList>
                  }
               {(isloading && <Loader />)}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SelectWorkspace;
// Import required dependencies from "react" and "@ionic/react"
import React from "react";
import { IonButton, IonGrid, IonRow, IonCol, IonLabel, IonRadioGroup, IonRadio, IonItem, IonIcon } from "@ionic/react";
// Import required hooks and components from "react-hook-form" and "@hookform/error-message"
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
// Import custom components "CustomItem" and "SubmitButton"
import CustomItem from "./CustomItem";
import SubmitButton from "./Button/SubmitButton";
// Define the RegistrationStep3 component as a functional component using React.FC
// The component receives the 'prev' function as a prop
export const RegistrationStep3: React.FC<any> = ({ prev }) => {
    // Retrieve necessary hook methods and state from useFormContext()
    const { register, control, formState: { errors } } = useFormContext(); // retrieve all hook method

    return (
        <div className="slide-main ion-padding">
            <div className="form-content form-step-2">
                <IonGrid>
                    {/* Display the back arrow and bind the 'prev' function to the button onClick event */}
                    <div className="back-arrow">
                        <IonButton className="ion-text-right" fill="clear" onClick={() => prev()}   >
                            <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                        </IonButton>
                    </div>
                    {/* Display the header */}
                    <div className="back-arrow">
                        <h4>Select your role*</h4>
                    </div>
                    <IonRow>
                        {/* Display the radio group with error handling */}
                        <IonCol size="12" className="email-field">
                            {/* Display the error message for the 'radioGrp' field if there are errors */}

                            <ErrorMessage
                                errors={errors}
                                name="radioGrp"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                            {/* Use the 'Controller' component to control the radio group */}

                            <Controller
                                control={control}
                                name="radioGrp"
                                rules={{ required: 'Please make a selection' }}
                                render={({ field: { onChange, value } }) => (
                                    <IonRadioGroup
                                        value={value}
                                        onIonChange={(e: any) => {
                                            onChange(e);
                                        }}
                                        // Register the 'radioGrp' field with 'required' validation

                                        {...register('radioGrp', { required: 'Please make a selection' })}  >
                                        {/* Render custom radio items with labels and values */}

                                        <CustomItem Label='Builder / Developer' value="builder" />
                                        <CustomItem Label='Project Manager' value="manager" />
                                        <CustomItem Label='Site Incharge' value="site" />
                                        <CustomItem Label='Supervisor / Engineer' value="supervisor" />
                                        <CustomItem Label='Stock Manager' value="stock" />
                                    </IonRadioGroup>
                                )} />
                        </IonCol>
                        {/* Render the SubmitButton component */}

                        <SubmitButton size="12" ColclassName="sign-up-btn" ButtonclassName="secondary-button" text="Register" />
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};
// Export the RegistrationStep3 component to be used in other parts of the application

export default RegistrationStep3;
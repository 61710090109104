// Importing necessary dependencies and styles
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonSelect, IonSelectOption, IonToast } from '@ionic/react';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Header from "../components/Header/Header";
import Logout from "../components/Logout";
// Interface to define the type of the location state

interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    materialItem: string;
    backpath?: string;
    inwardId: number;
    materialName: string;
    materialId: number;
    quantity: number;
}
// Defining the CreateMaterial functional component
const CreateMaterial: React.FC = () => {
    const locations = useLocation();
    // State variables for managing material data and toast messages
    const [materialItem, setMaterialItem] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [inwardId, setInwardId] = useState(0);
    // Retrieving user information from local storage

    let userid: any = localStorage.getItem("userid");
    let token: any = localStorage.getItem("token");
    // Using useHistory hook from react-router-dom to manage navigation
    let history = useHistory();

    // Using react-hook-form useForm to manage form state and validation
    const methods = useForm();
    const { handleSubmit, setValue, control, formState: { errors } } = methods;
    // Function to handle form submission

    const onSubmit = async (data: any) => {
        try {
            const materialResponse = await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/material-items',
                data: {
                    "data": {
                        material_item_name: data.newMaterial,
                        material_unit: data.materialUnit,
                        material_category: categoryId,
                        workspace: workspaceId
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            if (materialResponse) {
                setColordata("success");
                setMessage("Material added Successfully");
                setShowToast1(true)
                setValue('newMaterial', '');
                setValue('materialUnit', '');
                setTimeout(() => {
                    history.goBack();
                }, 1000);
            }
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    };
    // Function to navigate back to the previous page or do nothing
    const doNothing = () => {
        history.goBack();
    }
    // Function to navigate back to the category page
    const backPage = () => {

        history.push({
            pathname: categoryUrl,
            state: { "categoryName": categoryName, "categoryId": categoryId, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backPath, "inwardId": inwardId, "materialId": materialId, "materialName": materialName, "quantity": quantity }
        }
        );
    }
    // State variable for managing material unit list

    const [materialUnitList, setMaterialUnitList] = useState([
        { id: 1, attributes: { "material_unit_name": "materialUnit1" } },
        { id: 2, attributes: { "material_unit_name": "materialUnit2" } },
    ]);
    // State variables for managing category data

    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [backPath, setBackPath] = useState('');
    const workspaceId = localStorage.getItem('workspaceId');
    const projectName = localStorage.getItem('projectName');
    // useEffect hook to fetch material unit list and update category data from the location state

    React.useEffect(() => {
        const getMaterialUnit = async () => {
            try {
                const response = await axios({
                    url: ENV.ENDPOINT + '/material-units',
                    method: 'get',
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userid,
                        token: token,
                    },
                });
                setMaterialUnitList(response.data['data']);
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        };
        getMaterialUnit()
        // Update category data from the location state

        if (locations.state) {

            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setMaterialItem(location.materialItem)
            setCategoryUrl(location.categoryUrl);
            setInwardId(location.inwardId);
            setMaterialId(location.materialId);
            setMaterialName(location.materialName);
            setQuantity(location.quantity);
            if (location?.backpath) {

                setBackPath(location?.backpath);
            }

        }
    }, []);

    const lowMaterialName = materialItem.toLowerCase();
    // JSX content of the CreateMaterial component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Inward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <h2 className="category-name">{categoryName}</h2>
                            <FormProvider {...methods}>
                                <form className="material-add-form" onSubmit={handleSubmit(onSubmit)}>
                                    {(materialItem) ? (
                                        <IonRow className="">
                                            <IonCol size="3">
                                                <IonLabel className="form-lable material-lable">{materialItem}:*</IonLabel>
                                            </IonCol>
                                            <IonCol size="9">
                                                <IonRow className="material-select-row ion-align-items-center">
                                                    <IonCol size="12" className="ion-no-padding">
                                                        <Controller
                                                            render={({ field: { onBlur, value } }) => (
                                                                <IonInput
                                                                    type={"text"}
                                                                    onIonChange={(e: any) => {
                                                                        setValue('newMaterial', e.detail.value);
                                                                    }}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    className={`form-control ${errors.newMaterial ? 'is-invalid' : ''}`}
                                                                    placeholder="Add text"
                                                                    mode="md" />
                                                            )}
                                                            control={control}
                                                            name="newMaterial"
                                                            rules={{
                                                                required: `Please enter ${lowMaterialName}`,
                                                                maxLength: {
                                                                    value: 50,
                                                                    message: "Material name should not exceed 50 character."
                                                                }
                                                            }} />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="newMaterial"
                                                            as={<div className="error-message" style={{ color: 'red' }} />} />
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>) : ""}
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Material Unit:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow className="material-select-row ion-align-items-center">
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field }) => (
                                                            <IonSelect
                                                                placeholder="Select One"
                                                                value={field.value}
                                                                className={`form-control ${errors.materialUnit ? 'is-invalid' : ''}`}
                                                                onIonChange={(e) => setValue('materialUnit', e.detail.value)}  >
                                                                {materialUnitList.map((item) => (
                                                                    <IonSelectOption key={item.id} value={item.id}>
                                                                        {item.attributes.material_unit_name}
                                                                    </IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        )}
                                                        control={control}
                                                        name="materialUnit"
                                                        rules={{ required: 'Please select material unit' }} />
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="materialUnit"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="3">
                                        </IonCol>
                                        <IonCol size="9" className="ion-text-end">
                                            <IonButton className="" type="submit" fill="solid" >
                                                Add
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </form>
                            </FormProvider>
                        </IonCol>
                        {/* Toast to display success or error message */}
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={1000}
                            position="middle"
                            color={colordata} />
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

// Exporting the CreateMaterial component as the default export
export default CreateMaterial;
import { Redirect, Route,Switch} from 'react-router-dom';
type path = {
  component:any;
  path:any;
  auth: any;
};
  const PrivateRoute: React.FC<path> = ({component: Component, auth, ...rest}) =>{

   
    return (
      <Switch>
      <Route exact
        {...rest}
        render={(props) => auth == true
          ? <Component {...props} />
          : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
      />
 
      </Switch>
    )
  }
export default PrivateRoute;    
// Import necessary modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Loader from "../components/Loader/Loader";
import Logout from "../components/Logout";

const MyAccount: React.FC = () => {
    // Get the history object to handle navigation
    let history = useHistory();

    // Function to handle user logout
    const logOut = async () => {
        let sessionId: any = localStorage.getItem("token")
        let userid: any = localStorage.getItem("userid");


        try {
            const sessionExists = await axios({
                method: 'get',
                url: ENV.ENDPOINT + `/sessions/${sessionId}`,
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                },
            })
            if (sessionExists) {
                await axios({
                    method: 'delete',
                    url: ENV.ENDPOINT + `/sessions/${sessionId}`,
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userid,
                    },
                })

            }
            localStorage.clear();
            history.push('/index')
            window.location.reload();
        } catch (error) {
            console.log(error);
            localStorage.clear();
            history.push('/index')
            window.location.reload();
        }
    }
    // Function to navigate back to the previous page
    const backPage = () => {
        // history.go(-1);
        history.push('/dashboard/' + projectId)
    }
    // Get data from local storage
    const projectId = localStorage.getItem('projectId');
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    const workspaceId = localStorage.getItem('workspaceId');
    const userRoles = localStorage.getItem('work_roles')
    // State to manage owner status and loading state
    const [ownerStatus, setOwnerStatus] = useState([
        { id: null, attributes: { "owner": false } }
    ]);
    const [isloading, setIsloading] = useState(true);
    // Fetch owner status when the component mounts
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await checkOwnerStatus();
                if (data['data'].length != 0) {
                    setOwnerStatus(data['data']);
                }
                setIsloading(false);
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        fetchData();

    }, []);
    // Function to check owner status
    const checkOwnerStatus = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/user-workspaces?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[user_id][id][$eq]=' + userId + '&filters[owner][$eq]=true',
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            return response.data;
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }


    };
    // Function to navigate back to the previous page
    const doNothing = () => {
        history.goBack();
    }

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header my-account-header">
                                <IonButton className="ion-text-right" fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>My Account</b></h3>
                                </div>
                            </div>
                            <IonRow className="dashboar-buttons">
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" routerLink='/my-profile'>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/my-profile-icon.svg" />
                                            <h6 className="">My Profile</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                                {
                                    userRoles != "stock" ? <IonCol size="6" sizeMd="6" sizeLg="6">
                                        <IonButton expand="block" fill="outline" routerLink='/users'>
                                            <div className="button-inner">
                                                <IonIcon slot="icon-only" src="/assets/images/users-icon.svg" />
                                                <h6 className="">Users</h6>
                                            </div>
                                        </IonButton>
                                    </IonCol> : ""
                                }

                                {/* {(() => {
                                    if (ownerStatus[0].attributes.owner == true) {
                                        return (
                                            <IonCol size="6" sizeMd="6" sizeLg="6">
                                                <IonButton expand="block" fill="outline" routerLink='/billing-pro-plan'>
                                                    <div className="button-inner">
                                                        <IonIcon slot="icon-only" src="/assets/images/billing-icon.svg" />
                                                        <h6 className="">Billing</h6>
                                                    </div>
                                                </IonButton>
                                            </IonCol>
                                        )
                                    }
                                })()} */}
                                <IonCol size="6" sizeMd="6" sizeLg="6">
                                    <IonButton expand="block" fill="outline" onClick={logOut}>
                                        <div className="button-inner">
                                            <IonIcon slot="icon-only" src="/assets/images/logout-icon.svg" />
                                            <h6 className="" >Logout</h6>
                                        </div>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {/* Show a loader if data is loading */}
                {(isloading && <Loader />)}
            </IonContent>
        </IonPage>
    );
};

export default MyAccount;
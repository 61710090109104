import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import Inward from "../views/Inward/Index";
import Logout from "../components/Logout";

interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    editStatus: boolean;
    inwardId: number;
    categoryTrace: object;
    materialItem: string;
    backpath: string;
    parentCatId: number;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}
interface MaterialListArr {
    id: number;
    material_item_name: string;
    material_unit: string;
    material_unitId: number;
}
interface BrandListArr {
    id: number;
    material_brand_name: string;
}
const InwardEntry: React.FC = () => {
    const locations = useLocation();
    const [materialListData, setMaterialListData] = useState([
        { id: 1, "material_item_name": "materialitem1", "material_unit": "unit1", material_unitId: 1 },
        { id: 2, "material_item_name": "materialitem2", "material_unit": "unit2", material_unitId: 2 },
    ]);
    const [materialUnit, setMaterialUnit] = useState('');
    const [materialUnitId, setMaterialUnitId] = useState(0);
    const [brandListData, setBrandListData] = useState([
        { id: 1, "material_brand_name": "materialbrand1" },
        { id: 2, "material_brand_name": "materialbrand2" },
    ]);
    const [brandCounter, setBrandCounter] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [materialItem, setMaterialItem] = useState('');
    const [inwardId, setInwardId] = useState(0);
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    let history = useHistory();
    const [categoryTrace, setCategoryTrace] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [colordata, setColordata] = useState('');
    const [backData, setBackData] = useState('');
    const [materialItemId, SetMaterialItemId] = useState(0);
    const [parentCatId, setParentCatId] = useState(0);
    const [isloading, setIsloading] = useState(false);
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [quantity, setQuantity] = useState(0);
    let userid: any = localStorage.getItem("userid");
    let token: any = localStorage.getItem("token");
    //Function for back page

    const backPage = () => {
        if (backData == 'materialstock') {
            // history.go(-1);
            history.push({ pathname: '/material-stock', state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace, "parentCatId": parentCatId } });
        } else if (backData == 'stockreport') {
            // history.go(-1);
            history.push({ pathname: '/stock-report', state: { "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialId": materialItemId, "parentCatId": parentCatId, "inwardId": inwardId, "materialName": materialName, "quantity": quantity } });
        }
        else {
            var pushCategoryTrace = {} as pushState
            if (categoryTrace) {
                const categoryPush = categoryTrace as pushState
                pushCategoryTrace['categoryName'] = categoryPush['categoryName']
                pushCategoryTrace['categoryId'] = categoryPush['categoryId']
            }
            pushCategoryTrace["pushBack"] = true
            pushCategoryTrace["subCategory"] = subCategory;
            // history.go(-1)
            history.push({
                pathname: '/inward',
                state: pushCategoryTrace
            });

        }
    };
    const methods = useForm();
    const { handleSubmit, setValue, getValues, control, formState: { errors } } = methods;
    //Function that handles the form request while submiting the form

    const onSubmit = async (data: any) => {
        let inwardDate = new Date().toISOString();
        inwardDate = format(parseISO(inwardDate), 'yyyy-MM-dd')
        let materialItemData: any;
        if (data.material == '' || data.material == undefined) {
            materialItemData = null;
        } else {
            materialItemData = data.material;
        }

        let quantityInwardValue = 0;
        let quantityOutwardValue = 0;
        let totInwardValue = 0;
        let urlInwardLink: any;
        let urlOutwardLink: any;
        if (data.material == undefined || data.material == '') {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_brand]][id][$eq]=' + data.brand;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_brand]][id][$eq]=' + data.brand;
        }
        else {
            urlInwardLink = ENV.ENDPOINT + '/inward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material + '&filters[material_brand]][id][$eq]=' + data.brand;
            urlOutwardLink = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + data.material + '&filters[material_brand]][id][$eq]=' + data.brand;
        }

        try {
            const response = await axios({
                url: urlInwardLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userid,
                    token: token,
                },
            })
            if (response.data['data'].length != 0) {
                response.data['data'].forEach((list: any) => {
                    quantityInwardValue = quantityInwardValue + list.attributes.inward_quantity;
                })
                totInwardValue = quantityInwardValue + parseInt(data.quantity);
                const outwardResponse = await axios({
                    url: urlOutwardLink,
                    method: 'get',
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        userId: userid,
                        token: token,
                    },
                })
                if (outwardResponse.data['data'].length != 0) {
                    outwardResponse.data['data'].forEach((list: any) => {
                        quantityOutwardValue = quantityOutwardValue + list.attributes.outward_quantity;
                    })
                }
                if ((totInwardValue - quantityOutwardValue >= 0)) {
                    try {
                        await axios({
                            method: 'PUT',
                            url: ENV.ENDPOINT + '/inward-entries/' + inwardId,
                            data: {
                                "data": {
                                    material_item: materialItemData,
                                    material_brand: data.brand,
                                    inward_quantity: data.quantity,
                                    inward_note: data.Notes
                                }
                            },
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })
                        setColordata("success");
                        setMessage("Inward Entry Updated successfully");
                        setShowToast1(true)
                        setValue('material', '');
                        setValue('brand', '');
                        setValue('quantity', '');
                        setValue('Notes', '');

                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }

                } else {
                    setColordata("danger");
                    setMessage("Total Inward  quantity should not less than total outward");
                    setShowToast1(true)
                }

            } else {
                try {
                    await axios({
                        method: 'PUT',
                        url: ENV.ENDPOINT + '/inward-entries/' + inwardId,
                        data: {
                            "data": {
                                material_item: materialItemData,
                                material_brand: data.brand,
                                inward_quantity: data.quantity,
                                inward_note: data.Notes
                            }
                        },
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    setColordata("success");
                    setMessage("Inward Entry Updated successfully");
                    setShowToast1(true)
                    setValue('material', '');
                    setValue('brand', '');
                    setValue('quantity', '');
                    setValue('Notes', '');

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    };
    //Function for back button

    const doNothing = () => {
        history.goBack();
    }
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');

    //API calling 
    React.useEffect(() => {
        let location: any;
        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (location) {
            setParentCatId(location.parentCatId);
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setCategoryTrace(location.categoryTrace)
            setSubCategory(location.subCategory)
            setBackData(location.backpath)
            setMaterialId(location.materialId);
            setMaterialName(location.materialName);
            setQuantity(location.quantity);
            if (location.materialItem == null || location.materialItem == undefined || location.materialItem == "") {
                setMaterialItem("Material")
            } else {
                setMaterialItem(location.materialItem)
            }

            const myData = async () => {
                try {


                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-items?populate=*&pagination[limit]=-1&filters[material_category][id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userid,
                            token: token,
                        },
                    })
                    const materialdata: MaterialListArr[] = [];
                    if (response.data['data'].length > 0) {
                        response.data['data'].forEach((list: any) => {

                            if (list.attributes.workspace.data == null) {
                                materialdata.push({
                                    "id": list.id, "material_item_name": list.attributes.material_item_name,
                                    "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                                })
                            }
                            else if (list.attributes.workspace.data.id == workspaceId) {
                                materialdata.push({
                                    "id": list.id, "material_item_name": list.attributes.material_item_name,
                                    "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                                })
                            }
                        })
                        if (materialdata.length == 1 && materialdata[0].material_item_name == location.categoryName) {
                            setMaterialUnitId(materialdata[0].material_unitId)
                            setMaterialUnit(materialdata[0].material_unit);
                        }
                    }
                    setMaterialListData(materialdata);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const fetchData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];
                    while (true) {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/material-brands?populate=*&filters[material_category][id][$eq]=' + location.categoryId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })

                        const responseData = response.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }

                    const branddata: BrandListArr[] = [];
                    allData.forEach((list: any) => {
                        if (list.attributes.workspace.data == null) {
                            branddata.push({
                                "id": list.id, "material_brand_name": list.attributes.material_brand_name
                            })
                        }
                        else if (list.attributes.workspace.data.id == workspaceId) {
                            branddata.push({
                                "id": list.id, "material_brand_name": list.attributes.material_brand_name
                            })
                        }
                    })
                    setBrandListData(branddata);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }
            setIsloading(true);
            setTimeout(() => {
                SetMaterialItemId(location.materialId)
                setInwardId(location.inwardId)

                const InwardData = async () => {
                    try {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/inward-entries?&populate=*&filters[id][$eq]=' + location.inwardId,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        })
                        if (response.data['data'][0].attributes.material_item.data != null) {
                            setValue('material', response.data['data'][0].attributes.material_item.data.id)
                        }
                        setValue('quantity', response.data['data'][0].attributes.inward_quantity)
                        setValue('Notes', response.data['data'][0].attributes.inward_note)
                        setValue('brand', response.data['data'][0].attributes.material_brand.data.id)

                    } catch (error: any) {
                        console.log(error);
                        if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                            Logout(history)
                        }
                    }
                }
                InwardData();
                setIsloading(false);
            }, 1000);
            setCategoryUrl(location.categoryUrl);

            myData();
            fetchData();
        }
    }, [locations]);

    //Function to create new material

    const createNewMaterial = () => {
        history.push({
            pathname: '/create-material',
            state: { "categoryId": categoryId, "categoryName": categoryName, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backData, "inwardId": inwardId, "materialId": materialId, "materialName": materialName, "quantity": quantity }
        }
        );
    }
    //Function to create new material
    const addNewBrand = async () => {
        const newBrand = getValues('newBrand')
        if (newBrand != '') {
            try {
                await axios({
                    method: 'POST',
                    url: ENV.ENDPOINT + '/material-brands',
                    data: {
                        "data": {
                            material_brand_name: newBrand,
                            material_category: categoryId,
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userid,
                                token: token,
                            },
                        }
                    },
                    headers: HeaderAuthorization
                })
                setValue('newBrand', '');
                setBrandCounter(brandCounter - 1);
                history.go(0)

            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
    }
    //handles the adding of brand 

    const handleBrandClick = () => {
        setBrandCounter(brandCounter + 1);
    };
    //handles the removal of brand 

    const handleRemoveBrand = (index: any) => {
        setBrandCounter(brandCounter - 1);
    };
    const materialListArray = [
        { type: "text", value: "" },
    ];
    const brandListArray = [
        { brand1: "ABC", brand2: "XYZ" },
    ];
    const [brandList, setBrandList] = useState(brandListArray);
    const handleAddBrand = () => {
        setBrandList([...brandList, { brand1: "ABC", brand2: "XYZ" }]);
    };
    // function that calls when  material unit values are changing
    const onChange = async (data: any) => {
        const materialSetUnit = materialListData.filter(list => {
            if (list.id == data) {
                setMaterialUnitId(list.material_unitId)
                setMaterialUnit(list.material_unit);
                return list
            }
        })
    }
    //passing the props

    const props = {
        type: "normal",
        Isupdate: "true",
        doNothing: doNothing,
        backPage: backPage,
        projectName: projectName,
        categoryName: categoryName,
        methods: methods,
        handleSubmit: handleSubmit,
        onSubmit: onSubmit,
        materialListData: materialListData,
        materialItem: materialItem,
        errors: errors,
        onChange: onChange,
        setValue: setValue,
        control: control,
        createNewMaterial: createNewMaterial,
        materialUnit: materialUnit,
        showToast1: showToast1,
        setShowToast1: setShowToast1,
        colordata: colordata,
        isloading: isloading,
        message: message,
        brandListData: brandListData,
        brandCounter: brandCounter,
        handleBrandClick: handleBrandClick,
        handleRemoveBrand: handleRemoveBrand,
        addNewBrand: addNewBrand,
    };

    return (
        Inward(props)
    );
};

export default InwardEntry;
// Import necessary dependencies
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from "recharts";
import { IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon, } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Logout from "../components/Logout";

// Define interfaces for data objects
interface LocationState {
    id: number;
    subCategoryId: number;
    subCategoryName: string;
    categoryId: number;
    categoryName: string;
    status: string;
    categoryURL: string;
    materialName: string;
    quantity: number;
    materialId: number;
    categoryTrace: object;
    subCategory: object;
}
interface OutwardData {
    name: string;
    value: number;
}

// Functional component definition
const StockUsedReport: React.FC = () => {
    // Get the history and current location using React Router hooks
    let history = useHistory();
    const locations = useLocation();
    // Function to navigate back to the previous page
    const backPage = () => {
        // history.go(-1)
        setMaterialName('');
        history.push({
            pathname: "/stock-report",
            state: { "categoryName": categoryName, "categoryId": categoryId, "materialId": materialId, "materialName": materialName, "categoryURL": categoryUrl, "subCategory": subCategory, "categoryTrace": categoryTrace }
        }
        );
    };
    // Function to do nothing when the user clicks on a button (history.goBack())
    const doNothing = () => {
        history.goBack();
    }
    // Ref for the slider
    const slider = useRef<any>(null);
    // State for value and category ID and name

    const [value, setValue] = useState("0");
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [materialName, setMaterialName] = useState('');
    const [materialId, setMaterialId] = useState(0);
    const [usedQuantity, setUsedQuantity] = useState(0);
    const [materialUnit, setMaterialUnit] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [subCategory, setSubCategory] = useState({});
    const [categoryTrace, setCategoryTrace] = useState({});
    // State for the circle radius
    const [circleRadius, setCircleRadius] = useState(150)
    // Get user ID and token from local storage
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    // Default list for outward report
    const defaultList = [
        {
            value: 0,
            name: ""
        }
    ];
    const [outwardReportList, setoutwardReportList] = useState(defaultList);
    // useEffect to set circle radius based on window width
    useEffect(() => {
        if (window.innerWidth <= 425) {
            setCircleRadius(95);
        }
    }, [])
    // useEffect to fetch data when the component mounts or location state changes
    React.useEffect(() => {
        if (locations.state) {
            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setMaterialId(location.materialId);
            setCategoryUrl(location.categoryURL);
            setCategoryTrace(location.categoryTrace);
            setSubCategory(location.subCategory)
            let urlOutward: any;
            if (location.materialId) {
                urlOutward = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + location.materialId;
            } else {
                urlOutward = ENV.ENDPOINT + '/outward-entries?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&filters[project][id][$eq]=' + projectId;
            }

            const fetchData = async () => {
                try {
                    const response = await axios({
                        url: urlOutward,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const entries: OutwardData[] = [];
                    setMaterialUnit(response.data['data'][0].attributes.material_unit.data.attributes.material_unit_name)
                    if (response.data['data'][0].attributes.material_item.data != null) {
                        setMaterialName(response.data['data'][0].attributes.material_item.data.attributes.material_item_name);
                    } else {
                        setMaterialName('');
                    }
                    response.data['data'].forEach((list: any) => {
                        let index = entries.findIndex(item => item.name == list?.attributes?.material_purpose?.data?.attributes?.material_purpose_name)
                        if (index >= 0) {
                            entries[index].value += list?.attributes?.outward_quantity
                        }
                        else {

                            entries.push({ name: list?.attributes?.material_purpose?.data?.attributes?.material_purpose_name, value: list?.attributes?.outward_quantity })
                        }
                    })
                    let totUsed = 0;
                    entries.forEach((list: any) => {
                        totUsed += list.value
                    })
                    setUsedQuantity(totUsed);
                    entries.map((item: any) => {
                        item.name = item.name ? item.name : "No purpose"
                    })

                    setoutwardReportList(entries)

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }
            fetchData();
        }
    }, [locations]);
    // Get workspace ID and project ID from local storage
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        loop: false,
        pagination: {
            el: null
        },

    };
    // Handle segment change function
    const handleSegmentChange = async (e: any) => {
        await slider.current.lockSwipes(false);
        setValue(e.detail.value);
        slider.current!.slideTo(e.detail.value);
        await slider.current.lockSwipes(true);
    };
    // Handle slide change function 
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        index = await event.target.getActiveIndex();
        setValue('' + index)
    }
    // Sample data for the pie chart
    const myData = [
        { name: "Concreting Coulomb", value: 50 },
        { name: "Concreting Slab", value: 60 },
        { name: "Concreting Slab1", value: 10 },
        { name: "Concreting Slab1", value: 40 },
    ];
    // COLORS for the pie chart
    const COLORS = ['#434348', '#7CB5EC', '#800080', '#FF0000', '#000080',
        '#808000', '	#008080', '#DEB887', '#12962a', '#501296',
        '#006400', '#E9967A', '#483D8B', '#778899', '#DB7093'];
    // Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Stock</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <h4 className="ion-no-margin">{categoryName}</h4>
                            {(materialName != "") ? (
                                <h2 className="ion-no-margin">/ {materialName} </h2>) : ""}
                            <IonRow className="ion-align-items-center stock-report-title">
                                <IonCol size="12" sizeMd="6" sizeLg="6">
                                    <div className="current-stock">
                                        <h3><b>{usedQuantity}</b></h3>
                                        <p>{materialUnit} used</p>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <div className="chart">
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={true}
                                            data={outwardReportList}
                                            outerRadius={circleRadius}
                                            fill="#7CB5EC"
                                            label >
                                            {
                                                outwardReportList.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                            }
                                        </Pie>
                                        <Tooltip />
                                        <Legend verticalAlign="top" height={36} />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <IonGrid className="table">
                                <IonRow className="table-row table-header">
                                    <IonCol size="6" sizeMd="6" sizeLg="6" className="">
                                        <h6 className="ion-no-margin">PARTICULAR</h6>
                                    </IonCol>
                                    <IonCol size="6" sizeMd="6" sizeLg="6" className="ion-text-end">
                                        <h6 className="ion-no-margin">USED</h6>
                                    </IonCol>
                                </IonRow>
                                {outwardReportList.map((item) => (
                                    <IonRow className="table-row table-header">
                                        <IonCol size="6" sizeMd="6" sizeLg="6" className="">
                                            <p className="">{item.name ? item.name : "No purpose"}</p>
                                        </IonCol>
                                        <IonCol size="6" sizeMd="6" sizeLg="6" className="ion-text-end">
                                            <p><b>{item.value}</b></p>
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </IonGrid>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default StockUsedReport;
// Import required modules from external libraries and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonButton, IonGrid, IonRow, IonCol, IonToast, useIonAlert } from '@ionic/react';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import Logout from "./Logout";

// Define the StockOutwardReport functional component
const StockOutwardReport: React.FC = ({ children: id }) => {
    const outwardObj = (JSON.parse(JSON.stringify(id)))
    let history = useHistory();
    // Extract user roles, workspaceId, projectId, userId, and token from localStorage

    const userId: any = localStorage.getItem('userid')
    const user_roles = localStorage.getItem('work_roles');
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const token: any = localStorage.getItem('token');
    // State variables to manage the component's data and display

    const [outLoaded, setOutLoaded] = useState(false);
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    const [present] = useIonAlert();

    const defaultList = [
        {
            id: "",
            outwardDate: "",
            outwardTime: "",
            outwardUser: "",
            outwardBrand: "",
            outwardPhoto1: "",
            outwardPhoto2: "",
            outwardQuantity: 0,
            outwardYear: "",
            outwardPurpose: "",
            outwardPurposeId: "",
            outwardMaterialId: "",
            outwardBuildingInfo: "", 
            outwardWingInfo : ""

        },
    ];
    const [outwardReportList, setoutwardReportList] = useState(defaultList);
    // get and check edit the outward permission or not 
    const edit_permisionRaw = localStorage.getItem("edit_permision");
    if (edit_permisionRaw != null) {
        var edit_permision = JSON.parse(edit_permisionRaw);
    } else {
        edit_permision = false
    }
    // handle click event of the Remove item

    const handleRemoveClick = async (index: any, data: any) => {
        const list = [...outwardReportList];

        try {
            await axios({
                method: "DELETE",
                url: ENV.ENDPOINT + "/outward-entries/" + data,
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })

            list.splice(index, 1);
            setoutwardReportList(list);
            setColordata("success")
            setMessage("Outward Entry Deleted Successfully");
            setShowToast1(true)
            history.go(0)
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    }

    // Function to fetch outward entries list
    const getOutwardList = async () => {
        let urlLink;
        if (outwardObj.materialId && outwardObj.materialId != undefined) {
            urlLink = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + outwardObj.categoryId + '&filters[project][id][$eq]=' + projectId + '&filters[material_item][id][$eq]=' + outwardObj.materialId
        }
        else {
            urlLink = ENV.ENDPOINT + '/outward-entries?populate=*&fields=outward_quantity,createdAt,outward_date&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + outwardObj.categoryId + '&filters[project][id][$eq]=' + projectId
        }
        try {
            const response = await axios({
                url: urlLink,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            const outentriesArr = [];
            for (const [index, value] of response.data['data'].entries()) {
                let brandData = '';
                if (value?.attributes?.material_brand?.data != null) {
                    brandData = value.attributes.material_brand.data.attributes.material_brand_name;
                }
                let outwardDate = new Date(value.attributes.createdAt).toISOString();
                outwardDate = format(parseISO(outwardDate), 'dd-MM-yyyy')
                let userdata = '';
                if (value.attributes.outward_createdby.data != null) {
                    userdata = value.attributes.outward_createdby.data.attributes.firstName;
                }
                let materialItem1 = '';
                if (value.attributes.material_item.data != undefined || value.attributes.material_item.data != null) {
                    materialItem1 = value.attributes.material_item.data.id
                }
                
                outentriesArr.push({
                    id: value.id,
                    outwardDate: value.attributes.outward_date,
                    outwardYear: value.attributes.outward_date,
                    outwardBrand: brandData,
                    outwardUser: userdata,
                    outwardTime: value.attributes.createdAt,
                    outwardPhoto1: "",
                    outwardPhoto2: "",
                    outwardPurpose: value.attributes.material_purpose.data ? value.attributes.material_purpose.data.attributes.material_purpose_name : "",
                    outwardBuildingInfo: value.attributes.project_building_info.data.attributes.project_flatno                    ,
                    outwardWingInfo:  value.attributes.project_building_info.data.attributes.project_wing,
                    outwardPurposeId: value.attributes.material_purpose.data ? value.attributes.material_purpose.data.id : "",
                    outwardMaterialId: materialItem1,
                    outwardQuantity: parseInt(value.attributes.outward_quantity)
                })
            }
            const newentries = outentriesArr.sort((a: any, b: any) => a.outwardDate < b.outwardDate ? 1 : -1);
            setoutwardReportList(newentries)
            sessionStorage.removeItem("reloading");
            setOutLoaded(true);
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }


    }
    let reloading = sessionStorage.getItem("reloading");
    if (outwardObj.categoryId && reloading) {
        getOutwardList();
    }

    // Function to edit outward entries list

    const editOutwardEntry = async (data: any) => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[id][$eq]=' + outwardObj.categoryId,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })

            if (outwardObj.materialName && outwardObj.quantity) {
                history.push({
                    pathname: '/edit-outward-entry',
                    state: { "backpath": "stockreport", "categoryName": response.data['data'][0].attributes.material_category_name, "categoryId": response.data['data'][0].id, "categoryUrl": "/outward-entry", "editStatus": true, "outwardId": data.id, "materialItem": response.data['data'][0].attributes.material_item, "purposeId": data.outwardPurposeId, "materialId": data.outwardMaterialId, "materialName": outwardObj.materialName, "quantity": outwardObj.quantity }
                }
                );
            } else {

                history.push({
                    pathname: '/edit-outward-entry',
                    state: { "backpath": "stockreport", "categoryName": response.data['data'][0].attributes.material_category_name, "categoryId": response.data['data'][0].id, "categoryUrl": "/outward-entry", "editStatus": true, "outwardId": data.id, "materialItem": response.data['data'][0].attributes.material_item, "purposeId": data.outwardPurposeId, "materialId": data.outwardMaterialId }
                }
                );
            }
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    }


    const [ownerStatus, setOwnerStatus] = useState([
        { id: null, attributes: { "owner": false } }
    ]);
    // Fetch owner status when the component mounts
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await checkOwnerStatus();
                if (data['data'].length != 0) {
                    setOwnerStatus(data['data']);
                }
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        fetchData();
    
    }, []);
    // Function to check owner status
    const checkOwnerStatus = async () => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/user-workspaces?filters[workspace_id][id][$eq]=' + workspaceId + '&filters[user_id][id][$eq]=' + userId + '&filters[owner][$eq]=true',
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            return response.data;
        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }
    
    
    };
    //render and display particular outward entry for stock

    const renderCategoryList = () => {
        return outwardReportList.map((x, i) => {            
            return (
                <IonRow key={i} className="table-row table-header">
                    <IonCol size="3" sizeMd="3" sizeLg="3" className="">
                        {(x.outwardDate) ? (<p className=""><b>{String(new Date(x.outwardDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long' })).split(" ").reverse().join(" ")}</b></p>) : ''}
                        {(x.outwardYear) ? (<p className="">{x.outwardYear.split('-')[0]}</p>) : ''}
                        {(x.outwardTime) ? (<p className="">{new Date(x.outwardTime).toLocaleTimeString('en-IN', { timeZone: 'IST', hour12: true, hour: '2-digit', minute: 'numeric' })}</p>) : ''}
                        <p className="person-name">{x.outwardUser}</p>
                    </IonCol>
                    <IonCol size="5" sizeMd="5" sizeLg="5" className="">
                        <p className=""></p>
                        <p className="">{x.outwardBrand}</p>
                        <p className="">Wing {x.outwardWingInfo} - {x.outwardBuildingInfo}</p>
                        <p className="">{x.outwardPurpose}</p>

                    </IonCol>
                    <IonCol size="4" sizeMd="4" sizeLg="4" className="ion-text-end quantity-col">
                        <p><b>{x.outwardQuantity}</b></p>
                        <div className="buttons-wrap">
                            {(user_roles == "null" || user_roles == "manager" || user_roles == "builder" || edit_permision || ownerStatus[0].attributes.owner == true) ? (
                                <IonButton fill="clear" className="secondary-button clear-button-with-border" onClick={() => editOutwardEntry(x)} >
                                    Edit
                                </IonButton>) : ""}
                            {(user_roles == "null" || user_roles == "manager" || user_roles == "builder" || ownerStatus[0].attributes.owner == true) ? (
                                <IonButton id={x.id} fill="clear" className="secondary-button clear-button-with-border" onClick={(x) => {

                                    present({
                                        cssClass: 'red-alert',
                                        header: 'Remove Outward',
                                        message: '<p>Are you sure you want to remove this Item?</p>',
                                        buttons: [
                                            'Cancel',
                                            {
                                                text: 'Ok',
                                                handler: () => {
                                                    handleRemoveClick(i, (x.target as HTMLElement).id);

                                                }
                                            },
                                        ],
                                        onDidDismiss: (e) => { },
                                    })
                                }
                                }>
                                    Delete
                                </IonButton>) : ""}
                        </div>
                    </IonCol>
                </IonRow>
            );
        })
    }
    // The main JSX content of the component

    return (
        <IonGrid className="table report-table">
            <IonRow className="table-row table-header">
                <IonCol size="3" sizeMd="3" sizeLg="3" className="">
                    <h6 className="ion-no-margin">DATE</h6>
                </IonCol>
                <IonCol size="5" sizeMd="5" sizeLg="5" className="stock-outward-particular">
                    <h6 className="ion-no-margin">PARTICULAR</h6>
                </IonCol>
                <IonCol size="4" sizeMd="4" sizeLg="4" className="ion-text-end">
                    <h6 className="ion-no-margin">QUANTITY</h6>
                </IonCol>
            </IonRow>
            {renderCategoryList()}
            {/* Toast for showing error messages */}

            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message={message}
                duration={1500}
                position="middle"
                color={colordata} />
        </IonGrid>
    );
};

export default StockOutwardReport;
// Import the ExploreContainer.css file from '../assets/css/ExploreContainer.css'
import '../assets/css/ExploreContainer.css';

// Define an interface named ContainerProps
// This interface describes the expected props that the ExploreContainer component will receive
interface ContainerProps {
  name: string; // The 'name' prop is expected to be a string
}

// Define the ExploreContainer component as a functional component using React.FC
// The component takes the 'ContainerProps' interface as its generic type to define its props
const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    // Return a div with the 'container' CSS class
    <div className="container">
      {/* Display the 'name' prop wrapped in a <strong> element */}
      <strong>{name}</strong>
      {/* Display a paragraph with the text 'Explore' */}
      {/* Include an anchor (<a>) element with a link to 'https://ionicframework.com/docs/components' */}
      {/* 'target="_blank"' opens the link in a new tab */}
      {/* 'rel="noopener noreferrer"' adds security-related attributes to the link */}
      <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
    </div>
  );
};

// Export the ExploreContainer component to be used in other parts of the application
export default ExploreContainer;

//Import All modules
import { Capacitor } from "@capacitor/core";
import { ErrorMessage } from "@hookform/error-message";
import { IonPage, IonContent, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonSelect, IonSelectOption, IonInput, IonTextarea, IonToast } from "@ionic/react";
import { addCircleSharp, removeCircleSharp } from "ionicons/icons";
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react';
import { FormProvider, Controller } from "react-hook-form";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import Modal from "react-modal";
import { useHistory } from "react-router";

const Inward = (props: any) => {
    // Take all props
    const {
        type,
        Isupdate,
        doNothing,
        isOpen,
        toggleModal,
        toggleModal1,
        fileInput,
        fileInput01,
        fileInput1,
        fileInput2,
        loadImageFromDevice,
        loadImageFromDevice1,
        onImageClick,
        onImageClick1,
        isOpen1,
        backPage,
        projectName,
        categoryName,
        methods,
        handleSubmit,
        onSubmit,
        materialListData,
        materialItem,
        errors,
        onChange,
        setValue,
        control,
        createNewMaterial,
        truckListData,
        createNewTruck,
        materialUnit,
        fileList,
        fileList1,
        renderList,
        renderList1,
        inwardButton,
        showToast1,
        setShowToast1,
        colordata,
        isloading,
        message,
        brandListData,
        brandCounter,
        handleBrandClick,
        handleRemoveBrand,
        addNewBrand,
        rendeLengthList,
        renderHeightList,
        renderWidthList,
        calculateQuantity,
        setBlocksValue,
        setHeightErrorMsg,
        calculateQuantitySand,
        heightError,
        setHeightError,
        heightErrorMsg



    } = props

    //Check the route state and show bread crumbs
    const routeStateUnFor: any = localStorage.getItem("routeState");
    let routeState;

    if (routeStateUnFor) {
        routeState = JSON.parse(routeStateUnFor);
    }

    const breadCrumbs = [];
    if (!routeState?.editStatus) {
        if (routeState) {
            if (routeState?.subCategory) {
                if (routeState?.subCategory.length > 0) {

                    routeState?.subCategory.map((item: any) => {
                        if (!(JSON.stringify(item) == JSON.stringify({}))) {
                            breadCrumbs.push(item.categoryName);
                        }
                    });
                }
            }
            if (routeState?.categoryName) {
                breadCrumbs.push(routeState.categoryName);
            }
        }

    }

    //check it has edit permission or not in rcc
    const isReadOnlyCondition = () => {
        if (type == "rcc" || type== "sand") {
            return true
        }
        else {
            return false
        }
    };

    const lowMaterialName = materialItem.toLowerCase();
    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <div className="Appppp">
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={toggleModal}
                        contentLabel="My dialog" >
                        <div>Select Image</div>
                        <input
                            ref={fileInput}
                            hidden
                            type="file"
                            accept="image/*"
                            capture="camera"
                            name="postFile-camera"
                            onChange={loadImageFromDevice}
                            onClick={event =>
                                onImageClick(event)
                            } />
                        <input
                            ref={fileInput01}
                            hidden
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            name="postFile-camera"
                            onChange={loadImageFromDevice}
                            onClick={event =>
                                onImageClick(event)} />
                        <IonButton
                            className="upload-photo-btn yellow-button"
                            fill="solid"
                            shape="round"
                            onClick={() => {
                                fileInput?.current?.click();
                            }} >Camera</IonButton>
                        <IonButton
                            className="upload-photo-btn yellow-button"
                            fill="solid"
                            shape="round"
                            onClick={() => {
                                // @ts-ignore
                                fileInput01?.current?.click();
                            }}>Gallery</IonButton>
                        <IonButton
                            className="upload-photo-btn blue-button"
                            fill="solid"
                            shape="round"
                            onClick={toggleModal} >Close</IonButton>
                    </Modal>
                    <Modal
                        isOpen={isOpen1}
                        onRequestClose={toggleModal1}
                        contentLabel="My dialog"  >
                        <div>Select Image</div>
                        <input
                            ref={fileInput1}
                            hidden
                            type="file"
                            accept="image/*"
                            capture="camera"
                            name="postFile1"
                            onChange={loadImageFromDevice1}
                            onClick={event =>
                                onImageClick1(event)
                            } />
                        <input
                            ref={fileInput2}
                            hidden
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            name="postFile1"
                            onChange={loadImageFromDevice1}
                            onClick={event =>
                                onImageClick1(event)
                            } />
                        <IonButton
                            className="upload-photo-btn yellow-button"
                            fill="solid"
                            shape="round"
                            onClick={() => {
                                // @ts-ignore
                                fileInput1?.current?.click();
                            }} >
                            Camera
                        </IonButton>
                        <IonButton
                            className="upload-photo-btn yellow-button"
                            fill="solid"
                            shape="round"
                            onClick={() => {
                                // @ts-ignore
                                fileInput2?.current?.click();
                            }} >
                            Gallery
                        </IonButton>
                        <IonButton
                            className="upload-photo-btn blue-button"
                            fill="solid"
                            shape="round"
                            onClick={toggleModal1} >Close</IonButton>
                    </Modal>
                </div>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={backPage}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Inward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <div>
                                <IonBreadcrumbs>{
                                    breadCrumbs.map((breadCrumb, index) => {
                                        return (
                                            <IonBreadcrumb key={index}>{breadCrumb}</IonBreadcrumb>
                                        )
                                    })
                                }</IonBreadcrumbs>
                            </div>

                            {
                                type == "brick" ?
                                    <h2 className="category-name category-name-padding">Inward Entry Brick</h2>
                                    :
                                    type == "rcc" ?
                                        <h2 className="category-name category-name-padding">Inward Entry RCC</h2>
                                        :
                                        type == "sand" ?
                                            <h2 className="category-name category-name-padding">Inward Entry {categoryName}</h2>
                                            : <h2 className="category-name category-name-padding">Inward Entry {categoryName}</h2>

                            }
                            <FormProvider {...methods}>
                                <form className="material-add-form" onSubmit={handleSubmit(onSubmit)}>
                                    {
                                        type == "rcc" && <>
                                            <IonRow className="size-select-row">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable material-lable">Size:</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow className="material-select-row">
                                                        <IonCol size="4" className="ion-no-padding">
                                                            <IonLabel className="form-lable small-form-lable ion-text-center">Length</IonLabel>
                                                            {rendeLengthList()}
                                                        </IonCol>
                                                        <IonCol size="4" className="ion-no-verticle-padding">
                                                            <IonLabel className="form-lable small-form-lable ion-text-center">Height</IonLabel>
                                                            {renderHeightList()}
                                                        </IonCol>
                                                        <IonCol size="4" className="ion-no-padding">
                                                            <IonLabel className="form-lable small-form-lable ion-text-center">Width</IonLabel>
                                                            {renderWidthList()}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </>

                                    }


                                    {
                                      materialListData.length == 1 && materialListData[0].material_item_name == categoryName  ? "" : (

                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable material-lable">{materialItem}:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow className="material-select-row ion-align-items-center">
                                                        <IonCol size="10" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <IonSelect
                                                                        placeholder={materialListData.length ? "Select One" : `Please Add ${lowMaterialName}  → `}
                                                                        value={field.value}
                                                                        className={`form-control ${errors.material ? 'is-invalid' : ''}`}
                                                                        onIonChange={(e: any) => {
                                                                            setValue('material', e.detail.value)
                                                                            {
                                                                                type == "sand" || type == "normal" ? onChange(e.detail.value, "material") : onChange(e.detail.value)
                                                                            }

                                                                        }} >
                                                                        {materialListData.map((item: any) => (
                                                                            <IonSelectOption key={item.id} value={item.id}>
                                                                                {item.material_item_name}
                                                                            </IonSelectOption>
                                                                        ))}
                                                                    </IonSelect>
                                                                )}
                                                                control={control}
                                                                name="material"
                                                                rules={{ required: `Please select ${lowMaterialName}` }} />
                                                        </IonCol>
                                                        <IonCol size="2">
                                                            <IonButton fill="clear" onClick={createNewMaterial}><IonIcon icon={addCircleSharp} /></IonButton>
                                                        </IonCol>
                                                        <IonCol size="12" className="ion-no-padding">
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="material"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        )

                                    }



                                    {
                                        type == "normal" && <>
                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable material-lable">Brand:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow className="material-select-row ion-align-items-center">
                                                        <IonCol size="10" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <IonSelect
                                                                        placeholder={brandListData.length ? "Select One" : `Please Add Brand  →`}
                                                                        value={field.value}
                                                                        className={`form-control ${errors.brand ? 'is-invalid' : ''}`}
                                                                        onIonChange={(e) => setValue('brand', e.detail.value)}  >
                                                                        {brandListData.map((item: any) => (
                                                                            <IonSelectOption key={item.id} value={item.id}>
                                                                                {item.material_brand_name}
                                                                            </IonSelectOption>
                                                                        ))}
                                                                    </IonSelect>
                                                                )}
                                                                control={control}
                                                                name="brand"
                                                                rules={{ required: 'Please select brand' }} />
                                                        </IonCol>
                                                        {(brandCounter >= 1) ? '' : (
                                                            <IonCol size="2">
                                                                <IonButton fill="clear" onClick={handleBrandClick}><IonIcon icon={addCircleSharp} /></IonButton>
                                                            </IonCol>
                                                        )}
                                                        <IonCol size="12" className="ion-no-padding">
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="brand"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                    </IonRow>
                                                    {Array.from(Array(brandCounter)).map((c, index) => {
                                                        return (
                                                            <IonRow key={c} className="material-select-row ion-align-items-center">
                                                                <IonCol size="10" className="ion-no-padding">
                                                                    <Controller
                                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                                            <IonInput
                                                                                type={"text"}
                                                                                onIonChange={onChange}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                className={`form-control ${errors.newBrand ? 'is-invalid' : ''}`}
                                                                                placeholder="Add text"
                                                                                mode="md" />
                                                                        )}
                                                                        control={control}
                                                                        name="newBrand"
                                                                        rules={{ required: 'Please enter brand name' }} />
                                                                </IonCol>
                                                                <IonCol size="2">
                                                                    <IonButton fill="clear" onClick={handleRemoveBrand}><IonIcon icon={removeCircleSharp} /></IonButton>
                                                                </IonCol>
                                                                <IonCol size="12" className="ion-no-padding">
                                                                    <IonButton className="small-button" fill="solid" onClick={addNewBrand}>
                                                                        Add
                                                                    </IonButton>
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name="newBrand"
                                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                                </IonCol>
                                                            </IonRow>
                                                        );
                                                    })}
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }

                                    {
                                        type == "brick" || type == "rcc" ? <>
                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable material-lable">Truck No:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow className="material-select-row ion-align-items-center">
                                                        <IonCol size="10" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <IonSelect
                                                                        placeholder={truckListData.length ? "Select One" : `Please Add Truck No  →`}
                                                                        value={field.value}
                                                                        className={`form-control ${errors.truckno ? 'is-invalid' : ''}`}
                                                                        onIonChange={(e) => setValue('truckno', e.detail.value)} >
                                                                        {truckListData.map((item: any) => (
                                                                            <IonSelectOption key={item.id} value={item.id}>
                                                                                {item.attributes.truck_no}
                                                                            </IonSelectOption>
                                                                        ))}
                                                                    </IonSelect>
                                                                )}
                                                                control={control}
                                                                name="truckno"
                                                                rules={{ required: 'Please select truck no' }} />
                                                        </IonCol>
                                                        <IonCol size="2">
                                                            <IonButton fill="clear" onClick={createNewTruck}><IonIcon icon={addCircleSharp} /></IonButton>
                                                        </IonCol>
                                                        <IonCol size="12" className="ion-no-padding">
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="truckno"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>


                                        </> : ""
                                    }

                                    {
                                        type == "sand" && <>

                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable material-lable">Truck No:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow className="material-select-row ion-align-items-center">
                                                        <IonCol size="10" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <IonSelect
                                                                        placeholder={truckListData.length ? "Select One" : `Please Add Truck No  →`}
                                                                        value={field.value}
                                                                        className={`form-control ${errors.truckno ? 'is-invalid' : ''}`}
                                                                        onIonChange={(e) => {
                                                                            setValue('truckno', e.detail.value); setValue('materialHeightFeet', ''); setValue('materialHeightInch', ''); setValue('quantity', '');
                                                                            setHeightError(false);
                                                                            setHeightErrorMsg("")
                                                                        }}  >
                                                                        {truckListData.map((item: any) => (
                                                                            <IonSelectOption key={item.id} value={item.id}>
                                                                                {item.attributes.truck_no}
                                                                            </IonSelectOption>
                                                                        ))}
                                                                    </IonSelect>
                                                                )}
                                                                control={control}
                                                                name="truckno"
                                                                rules={{ required: 'Please select truck no' }} />
                                                        </IonCol>
                                                        <IonCol size="2">
                                                            <IonButton fill="clear" onClick={createNewTruck}><IonIcon icon={addCircleSharp} /></IonButton>
                                                        </IonCol>
                                                        <IonCol size="12" className="ion-no-padding">
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="truckno"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }

                                    {
                                        type == "sand" && <>
                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable two-line-label material-lable">Height of Material:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow>
                                                        <IonCol size="3" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field: { onChange, onBlur, value } }) => (
                                                                    <IonInput
                                                                        type="text"
                                                                        onIonChange={(e) => {
                                                                            setValue('materialHeightFeet', e.detail.value)
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        onKeyUp={(e) => { calculateQuantitySand() }}
                                                                        className={`form-control ${errors.materialHeightFeet ? 'is-invalid' : ''}`}
                                                                        placeholder=""
                                                                        mode="md" />
                                                                )}
                                                                control={control}
                                                                name="materialHeightFeet"
                                                                rules={{
                                                                    required: "Please add  material height in feet",
                                                                    maxLength: {
                                                                        value: 2,
                                                                        message: "Maximum length of number is 2"
                                                                    },
                                                                    pattern: {
                                                                        value: /^[0-9 ]*$/i,
                                                                        message: "Enter numbers",
                                                                    }
                                                                }} />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="materialHeightFeet"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                        <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                            <p>Feet</p>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field: { onChange, onBlur, value } }) => (
                                                                    <IonInput
                                                                        type="text"
                                                                        onIonChange={(e) => {
                                                                            setValue('materialHeightInch', e.detail.value)
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        onKeyUp={(e) => { calculateQuantitySand() }}

                                                                        className={`form-control ${errors.materialHeightInch ? 'is-invalid' : ''}`}
                                                                        placeholder=""
                                                                        mode="md" />
                                                                )}
                                                                control={control}
                                                                name="materialHeightInch"
                                                                rules={{
                                                                    required: "Please add  material height Inch",
                                                                    maxLength: {
                                                                        value: 2,
                                                                        message: "Maximum length of number is 2"
                                                                    },
                                                                    pattern: {
                                                                        value: /^[0-9 ]*$/i,
                                                                        message: "Enter numbers",
                                                                    }
                                                                }} />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="materialHeightInch"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                        <IonCol size="2" className="ion-no-padding ion-padding-start">
                                                            <p>Inch</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>

                                            <IonRow className="">
                                                <IonCol size="3">
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow>
                                                        <IonCol size="12" className="ion-no-padding">
                                                            {heightError ? <div className="error-message" style={{ color: 'red' }}
                                                            >{heightErrorMsg}</div> : null}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }
                                    {
                                        type == "rcc" && <>
                                            <IonRow className="">
                                                <IonCol size="3">
                                                    <IonLabel className="form-lable two-line-label material-lable">Number of Blocks:*</IonLabel>
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonRow>
                                                        <IonCol size="4" className="ion-no-padding">
                                                            <Controller
                                                                render={({ field: { onChange, onBlur, value } }) => (
                                                                    <IonInput
                                                                        type="number"
                                                                        id="blocks"
                                                                        onIonChange={(e) => { setValue('blocks', e.detail.value); setBlocksValue(e.detail.value) }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        onKeyUp={calculateQuantity}
                                                                        className={`form-control ${errors.blocks ? 'is-invalid' : ''}`}
                                                                        placeholder=""
                                                                        mode="md" />
                                                                )}
                                                                control={control}
                                                                name="blocks"
                                                                rules={{
                                                                    required: "Please add number of blocks"
                                                                }} />
                                                        </IonCol>
                                                        <IonCol size="4" className="ion-no-padding ion-padding-start">
                                                            <p>Pcs</p>
                                                        </IonCol>
                                                        <IonCol size="12" className="ion-no-padding">
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="blocks"
                                                                as={<div className="error-message" style={{ color: 'red' }} />} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }

                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Qty:*</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="4" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onBlur, value } }) => (
                                                            <IonInput
                                                                type="text"
                                                                onIonChange={(e) => setValue('quantity', e.detail.value)}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                mode="md"

                                                                readonly={isReadOnlyCondition()} />
                                                        )}
                                                        control={control}
                                                        name="quantity"
                                                        rules={{
                                                            required: "Please add quantity",
                                                            pattern: {
                                                                value: /^\d*\.?\d*$/,

                                                                message: "Enter quantity in decimal or number value"
                                                            },
                                                            validate: (value) => {
                                                                if (value === "0") {
                                                                    return "Quantity cannot be zero";
                                                                }
                                                                return true;
                                                            }
                                                        }} />
                                                </IonCol>
                                                <IonCol size="4" className="ion-no-padding ion-padding-start">
                                                    <p>{materialUnit}</p>
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="quantity"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="">
                                        <IonCol size="3">
                                            <IonLabel className="form-lable material-lable">Note:</IonLabel>
                                        </IonCol>
                                        <IonCol size="9">
                                            <IonRow>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <Controller
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <IonTextarea
                                                                onIonChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                className={`form-control ${errors.Notes ? 'is-invalid' : ''}`}
                                                                placeholder="Add text" />
                                                        )}
                                                        control={control}
                                                        name="Notes" />
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="Notes"
                                                        as={<div className="error-message" style={{ color: 'red' }} />} />
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>

                                    {
                                        type == "brick" || type == "rcc" || type == "sand" ? <>
                                            <IonRow className="">
                                                <IonCol size="6">
                                                    <input
                                                        ref={fileInput}
                                                        hidden
                                                        type="file"
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        name="postFile"
                                                        onChange={loadImageFromDevice}
                                                        onClick={event =>
                                                            onImageClick(event)
                                                        } />
                                                    {Capacitor.isNativePlatform() ? (
                                                        <IonButton
                                                            className="upload-photo-btn yellow-button"
                                                            fill="solid"
                                                            shape="round"
                                                            onClick={toggleModal}  >
                                                            Capture Image-1
                                                        </IonButton>
                                                    ) : (<IonButton
                                                        className="upload-photo-btn yellow-button"
                                                        fill="solid"
                                                        shape="round"
                                                        onClick={() => {
                                                            // @ts-ignore
                                                            fileInput?.current?.click();
                                                        }}  >
                                                        Capture Image-1
                                                    </IonButton>)}
                                                    {(() => {
                                                        if (fileList.fileName != "") {
                                                            return (
                                                                renderList()
                                                            )
                                                        }
                                                    })()}
                                                </IonCol>
                                                <IonCol size="6">
                                                    <input
                                                        ref={fileInput1}
                                                        hidden
                                                        type="file"
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        name="postFile1"
                                                        onChange={loadImageFromDevice1}
                                                        onClick={event =>
                                                            onImageClick1(event)
                                                        } />
                                                    {Capacitor.isNativePlatform() ? (
                                                        <IonButton
                                                            className="upload-photo-btn yellow-button"
                                                            fill="solid"
                                                            shape="round"
                                                            onClick={toggleModal1} >
                                                            Capture Image-2
                                                        </IonButton>
                                                    ) : (<IonButton
                                                        className="upload-photo-btn yellow-button"
                                                        fill="solid"
                                                        shape="round"
                                                        onClick={() => {
                                                            // @ts-ignore
                                                            fileInput1?.current?.click();
                                                        }} >
                                                        Capture Image-2
                                                    </IonButton>)}
                                                    {(() => {
                                                        if (fileList1.fileName != "") {
                                                            return (
                                                                renderList1()
                                                            )
                                                        }
                                                    })()}
                                                </IonCol>
                                            </IonRow>
                                        </> : ""
                                    }
                                    {
                                        Isupdate != "true" && (type == "brick" || type == "normal" || type == "sand") ? <>
                                            <IonRow className="">
                                                <IonCol size="12" className="ion-text-end">
                                                    <IonButton disabled={inwardButton} className="" type="submit" fill="solid" >
                                                        Add
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </> : ""
                                    }

                                    {
                                        Isupdate != "true" && type == "rcc" && <>
                                            <IonRow className="">
                                                <IonCol size="12" className="ion-text-end">
                                                    <IonButton disabled={inwardButton} className="" type="submit" fill="solid" onClick={addNewBrand}>
                                                        Add
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }

                                    {
                                        Isupdate == "true" && <>
                                            <IonRow className="">
                                                <IonCol size="12" className="ion-text-end">
                                                    <IonButton className="" type="submit" fill="solid" >
                                                        Update
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>

                                        </>
                                    }

                                </form>
                            </FormProvider>
                            <IonToast
                                isOpen={showToast1}
                                onDidDismiss={() => setShowToast1(false)}
                                message={message}
                                duration={1500}
                                position="middle"
                                color={colordata} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {(isloading && <Loader />)}
            </IonContent>
        </IonPage>
    )
}

export default Inward;
// Importing necessary modules from the React and Ionic libraries
import React from 'react';
import { IonImg } from '@ionic/react';

// Defining a functional component called Loader with no props
const Loader: React.FC = () => {
  return (
    // Rendering a div container to wrap the loader image
    <div>
      {/* Rendering an IonImg component from Ionic framework */}
      {/* The image has the class "loader-image" and the specified image source */}
      {/* The image source points to a "loading.gif" file in the "/assets/images/" directory */}
      {/* The "alt" attribute is provided for accessibility purposes */}
      <IonImg className="loader-image" src="/assets/images/loading.gif" alt="Loader" />
    </div>
  );
};
// Exporting the Loader component to make it available for use in other parts of the application
export default Loader;
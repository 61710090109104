// Importing necessary Ionic components and styles
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonImg, IonButton } from '@ionic/react';
import '../assets/css/Custom.css';      // Custom CSS for styling
import '../assets/css/Responsive.css';  // Responsive CSS for styling

// Defining the PageNotFound functional component
const PageNotFound: React.FC = () => {
  return (

    <IonPage>
      {/* The content section of the page */}

      <IonContent className="splash-screen-content page-not-found-section" fullscreen>
        <IonGrid className="full-height-div">
          <IonRow>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="login-header splash-screen-header">
                <IonImg src="/assets/images/csm-logo.png" alt="Logo" />
              </div>
              <h1 className="ion-text-center">404</h1>
              <h6 className="">Look like you're lost</h6>
              <p>the page you are looking for not avaible!</p>
              <IonButton routerLink="/index" fill="solid" >
                Go to Home
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
// Exporting the PageNotFound component as the default export

export default PageNotFound;

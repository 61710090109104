// Importing necessary modules from the React and Ionic libraries
import React from "react";
import { IonCol, IonButton } from '@ionic/react';

// Defining a functional component called SubmitButton that accepts any props
const SubmitButton: React.FC<any> = (props) => {
    return (
        // Rendering an IonCol component from Ionic framework
        // The column's size and className props are set based on the provided props
        <IonCol size={props.size} className={props.ColclassName}>
            {/* Rendering an IonButton component from Ionic framework */}
            {/* The button's className, type, expand, shape, and fill props are set based on the provided props */}
            {/* The text inside the button is determined by the props.text */}
            <IonButton className={props.ButtonclassName} type="submit" expand="block" shape="round" fill="solid">
                {props.text}
            </IonButton>
        </IonCol>
    );
};

export default SubmitButton;
import indexURL from '../pages/Splash';
import Login from '../pages/Index';
import ForgetPassword from '../pages/Forget-Password';
import ChangePassword from '../pages/Change-Password';
import ResetPassword from '../pages/Reset-Password';
import SelectWorkspace from '../pages/Select-Workspace';
import SelectProject from '../pages/Select-Project';
import Registration from '../pages/registration';
import CreateNewWorkspace from '../pages/Create-New-Workspace';
import CreateProject from '../pages/Create-Project';
import Dashboard from '../pages/Dashboard';
import MyAccount from '../pages/My-Account';
import MyProfile from '../pages/My-Profile';
import Users from '../pages/Users';
import InviteUser from '../pages/Invite-User';
import EditUser from '../pages/Edit-User';
// import BillingFreePlan from '../pages/Billing-Free-Plan';
// import BillingProPlan from '../pages/Billing-Pro-Plan';
// import BuyPlan from '../pages/Buy-Plan';
import Inward from '../pages/Inward';
import InwardSubCategory from '../pages/Inward-Sub-Category';
import InwardEntry from '../pages/Inward-Entry';
import InwardEntryRcc from '../pages/Inward-Entry-Rcc';
import InwardEntryBrick from '../pages/Inward-Entry-Brick';
import InwardEntrySand from '../pages/Inward-Entry-Sand';
import CreateMaterial from '../pages/Create-Material';
import CreateTruck from '../pages/Create-Truck';
import Outward from '../pages/Outward';
import OutwardSubCategory from '../pages/Outward-Sub-Category';
import OutwardEntry from '../pages/Outward-Entry';
import MaterialStock from '../pages/Material-Stock';
import StockReport from '../pages/Stock-Report';
import StockUsedReport from '../pages/Stock-Used-Report';
import StockCategory from '../pages/Stock-Category';
import StockSubCategory from '../pages/Stock-Sub-Category';
import PrivacyTerms from '../pages/privacy-terms';
import PageNotFound from '../pages/404';
import EditWorkspace from '../pages/Edit-Workspace';
import Page from '../pages/Page';
import EditProject from '../pages/Edit-Project';
import EditInwardEntry from '../pages/Edit-Inward-Entry';
import EditInwardEntryBrick from '../pages/Edit-Inward-Entry-Brick';
import EditInwardEntryRcc from '../pages/Edit-Inward-Entry-Rcc'
import EditOutwardEntry from '../pages/Edit-Outward-Entry';
import EditInwardEntrySand from '../pages/Edit-Inward-Entry-Sand';

export const protected_routes = {
  '/change-password':ChangePassword,
   '/select-workspace':SelectWorkspace,
   '/select-project/:id':SelectProject,
   '/create-new-workspace':CreateNewWorkspace,
   '/edit-workspace':EditWorkspace,
    '/create-project':CreateProject,
    '/edit-project':EditProject,
    '/dashboard/:id':Dashboard,
    '/my-account':MyAccount,
    '/my-profile':MyProfile,
    '/users':Users,
    '/invite-user':InviteUser,
    '/edit-user':EditUser,
    // '/billing-free-plan':BillingFreePlan,
    // '/billing-pro-plan':BillingProPlan,
    // '/buy-plan':BuyPlan,
    '/inward':Inward,
    '/inward-sub-category':InwardSubCategory,
    '/inward-entry':InwardEntry,
    '/edit-inward-entry':EditInwardEntry,
    '/inward-entry-rcc':InwardEntryRcc,
    '/edit-inward-entry-rcc':EditInwardEntryRcc,
    '/inward-entry-brick':InwardEntryBrick,
    '/edit-inward-entry-brick':EditInwardEntryBrick,
    '/edit-inward-entry-sand':EditInwardEntrySand,
    '/inward-entry-sand':InwardEntrySand,
    '/create-material':CreateMaterial,
    '/create-truck':CreateTruck,
    '/outward':Outward,
    '/outward-sub-category':OutwardSubCategory,
    '/outward-entry':OutwardEntry,
    '/edit-outward-entry':EditOutwardEntry,
    '/material-stock':MaterialStock,
    '/stock-report':StockReport,
    '/stock-used-report':StockUsedReport,
    '/stock-category':StockCategory,
    '/stock-sub-category':StockSubCategory,
    '/privacy-terms':PrivacyTerms,
    '/page-not-found':PageNotFound,
    '/page/:name':Page,
  };
  
export const routes = {
    '/index':Login,
    '/registration':Registration,
    '/*/*':PageNotFound,
    '/*/*/*':PageNotFound,
    '/forget-password':ForgetPassword,
    '/reset-password/:id':ResetPassword,
  }



// Import required modules from external libraries and components
import React, { useEffect, useState } from "react";
import { IonButton, IonInput, IonGrid, IonRow, IonCol, IonIcon, IonToast } from "@ionic/react";
import { reloadSharp } from 'ionicons/icons';
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonImgButton from "./Button/IonImgButton";

export const RegistrationStep2: React.FC<any> = ({ next, prev, flag, verifyotp,time, Count , fired , setFired, verify }) => {

    const { getValues, control, formState: { errors } } = useFormContext(); // retrieve all hook methods
    // State variables to manage the count, toast, and messages
    const [count, setCount] = useState(0)
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [colordata, setColordata] = useState('');
    // useEffect to handle OTP generation on flag change


    const fetchData = async () => {
        try {
            await axios({
                method: 'post',
                url: ENV.ENDPOINT + '/otps/createotp',
                data: {
                    "data": {
                        Mobile_Number: '91' + getValues('telephone'),
                        genrate_otp: "otp",
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    PageLocation: "registration",
                },
            })
        } catch (error) {
            console.log(error);

        }
    }





    useEffect(() => {
        if (flag == true && !fired && !verify) {
            
            // Function to fetch and generate OTP
            fetchData();
        }
        setFired(false)
    }, [flag]);

    useEffect(() => {
        setTime(time)
        setCount(Count)
    } , [time])
    // useState to manage the countdown timer for OTP resend
    const [[hrs, mins, secs], setTime] = useState([0, 10, 0]);
    // CountDownTimer component to display the timer in HH:mm:ss format
    const CountDownTimer: React.FC<any> = () => {
        // Function to handle the countdown logic
        const tick = () => {
            if (hrs == 0 && mins == 0 && secs == 0)
                reset()
            else if (mins == 0 && secs == 0) {
                setTime([0, 0, 0]);
            } else if (secs == 0) {
                setTime([hrs, mins - 1, 59]);
            } else {
                setTime([hrs, mins, secs - 1]);
            }
        };
        const reset = () => { }
        // useEffect to update the timer every second

        useEffect(() => {
            const timerId = setInterval(() => tick(), 1000);
            return () => clearInterval(timerId);
        });
        return (
            <>
                {`${hrs.toString().padStart(2, '0')}:${mins
                    .toString()
                    .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}
            </>
        );
    }
    // Function to resend OTP
    const resend = async () => {
        try {


            const res = await axios({
                method: 'post',
                url: ENV.ENDPOINT + '/otps/createotp',
                data: {
                    "data": {
                        Mobile_Number: '91' + getValues('telephone'),
                        genrate_otp: "otp",
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    PageLocation: "registration",
                },
            })

            setColordata("success")
            setMessage("OTP send please check");
            setShowToast1(true)
            setCount(1)
            verifyotp(false)

            // setTimeout(() => {
            //     if (res.data.otp_status != "approved") {
            //         setColordata("danger");
            //         setMessage("Wrong OTP please check your OTP");
            //         setShowToast1(true)
            //     }
            //     else {
            //         next(['verifyText'])
            //     }
            // }, 2000);
        } catch (error) {
            console.log(error);

        }

    }
    // Function to handle OTP verification

    const otpverification = async () => {
        if (getValues('verifyText') == "" || getValues('verifyText') == undefined) {
            setColordata("danger");
            setMessage("Please enter OTP verification");
            setShowToast1(true)

            // next(['verifyText'])
        }
        else {

            try {
                const res = await axios({
                    method: 'POST',
                    url: ENV.ENDPOINT + '/otps/createotp',
                    data: {
                        "data": {
                            status: "verifyotp",
                            Mobile_Number: '91' + getValues('telephone'),
                            Genrate_OTP: getValues('verifyText'),
                        }
                    },
                    headers: {
                        Authorization: HeaderAuthorization.Authorization,
                        PageLocation: "registration",
                    },
                })
                if (res.data.data.otp_status != undefined) {
                    if (res.data.data.otp_status != "approved") {
                        setColordata("danger");
                        setMessage("Wrong OTP please check your OTP");
                        setShowToast1(true)
                        verifyotp(false)
                    }
                    else {
                        next(['verifyText'])
                        verifyotp(true)
                    }
                }

            } catch (error) {
                console.log(error);
                verifyotp(false)
            }


        }
    }
    // Define initial values for the countdown timer
    const hoursMinSecs = { hours: 0, minutes: 1, seconds: 0 };

   


    // The main JSX content of the component
    return (
        <div className="slide-main ion-padding">
            <div className="form-content form-step-2">
                <IonGrid>
                    {/* Back arrow */}
                    <div className="back-arrow">
                        <IonButton className="ion-text-right" fill="clear" onClick={() => prev()}   >
                            <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                        </IonButton>
                    </div>
                    <div className="main-logo ion-hide-md-up">
                        <IonImgButton routerLink="/" className="logo" src="assets/images/csm-logo.png" />
                    </div>
                    <p className="ion-text-center">Check your Mobile for a magic code. The code expires shortly, so please enter it soon.</p>
                    {/* Form inputs */}
                    <IonRow>
                        <IonCol size="12" className="email-field">
                            <Controller
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <IonInput
                                        type="text"
                                        onIonChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className={`form-control ${errors.verifyText ? 'is-invalid' : ''}`}
                                        placeholder="Add text"
                                        mode="md" />
                                )}
                                control={control}
                                name="verifyText"
                                rules={{
                                    required: "Please enter a valid code",
                                }} />
                            {
                                errors.verifyText && errors.verifyText.type == "validate" && (
                                    <div className="error-message">Wrong OTP! Please enter valid OTP</div>
                                )
                            }
                            <ErrorMessage
                                errors={errors}
                                name="verifyText"
                                as={<div className="error-message" style={{ color: 'red' }} />} />
                        </IonCol>
                        <IonCol size="12" className="sign-up-btn">
                            <IonButton expand="block" shape="round" id="btn"
                                fill="solid" onClick={otpverification} >
                                Verify
                            </IonButton>
                        </IonCol>
                        <IonCol size="12" className="form-footer-note">
                            {
                                hrs == 0 && mins == 0 && secs == 0 ?
                                    <div>
                                        {
                                            count != 1 ? <IonButton className="ion-text-right" onClick={resend} fill="clear" routerLink="#">Resend Code <IonIcon icon={reloadSharp} ></IonIcon>
                                            </IonButton>
                                                : <p>If you not received then please check your number and try again </p>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <p>
                                            Not received?
                                            <IonButton className="ion-text-right" fill="clear" routerLink="#">resend Code in :
                                                {flag == true ? <CountDownTimer hoursMinSecs={hoursMinSecs} /> : ""}
                                            </IonButton>
                                        </p>
                                    </div>
                            }
                        </IonCol>
                        {/* Toast for showing error messages */}
                        <IonToast
                            isOpen={showToast1}
                            onDidDismiss={() => setShowToast1(false)}
                            message={message}
                            duration={2000}
                            position="middle"
                            color={colordata}
                        />
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};
export default RegistrationStep2;
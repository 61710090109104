const AWS = require('aws-sdk');
const config = {
  accessKeyId: 'DO00G82G6ZWM23H7HMKZ',
  secretAccessKey: 'PmR44Y7PeMZdTNUUvfvF79Sr26C1+oXZUwitugbeR7Y',
  endpoint: 'blr1.digitaloceanspaces.com', // Adjusted endpoint URL
  withCredentials: true,
};
const s3 = new AWS.S3(config);
// UploadImg function to handle file uploads
const UploadImg = async (file: any, fileName: any) => { 
  const options = {
    Bucket: 'cms-ionic-app',
    region: 'blr1',
    Key: file.name,
    Body: file,
    ACL: 'public-read',
      ExtraParams: {
      // Add your custom headers here
      'Access-Control-Allow-Origin': '*', // Allow requests from all origins
      'Access-Control-Allow-Headers': "*" , 
      'Access-Control-Allow-Methods':  "*",
      // Add other headers as needed
    },
  }
  try {
   // Uploading the file to the S3 bucket
   const data = await s3.upload(options).promise();
  
   // Returning the URL of the uploaded file
   return data.Location || null;
  } catch (err) {
    // Handling errors if the upload fails
    console.log(err);
    return null;
  }
};

// Exporting the UploadImg function to make it available for use in other parts of the application
export default UploadImg;

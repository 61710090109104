// Importing required dependencies
import axios from "axios";
import { HeaderAuthorization } from "../../authorization/HeaderAuthorization";
import { ENV } from "../../config/config";

// Logout function takes 'history' as a parameter (used for navigation)
const Logout = async (history: any) => {
  // Get the sessionId and userid from localStorage
  let sessionId: any = localStorage.getItem("token")
  let userid: any = localStorage.getItem("userid");

  try {
    // Check if the session exists by sending a GET request to the server

    const sessionExists = await axios({
      method: 'get',
      url: ENV.ENDPOINT + `/sessions/${sessionId}`,
      headers: {
        // Include Authorization token and userId in the headers

        Authorization: HeaderAuthorization.Authorization,
        userId: userid,
      },
    })
    // If the session exists, proceed with the logout process

    if (sessionExists) {
      // Send a DELETE request to the server to delete the session

      await axios({
        method: 'delete',
        url: ENV.ENDPOINT + `/sessions/${sessionId}`,
        headers: {
          // Include Authorization token and userId in the headers
          Authorization: HeaderAuthorization.Authorization,
          userId: userid,
        },
      })

    }
    // Clear localStorage to remove the token and user id

    localStorage.clear();
    // Redirect the user to the 'index' page
    history.push('/index')
    // Reload the window to apply the changes and update the UI

    window.location.reload();
  } catch (error) {
    // If an error occurs during the logout process, handle it 

    console.log(error);
    localStorage.clear();
    history.push('/index')
    window.location.reload();
  }

}
// Export the Logout function to be used in other parts of the application

export default Logout
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonList, IonItem } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import IonIconButton from "../components/Button/IonIconButton";
import Logout from "../components/Logout";

// Define interfaces for data objects
interface SubCategoryData {
    id: number;
    parentCategoryId: number;
    categoryName: string;
    categoryURL: string;
    categoryStatus: string
}interface LocationState {
    subCategoryId: number;
    subCategoryName: string;
    categoryId: number;
    categoryName: string;
    status: string
}
// Functional component definition
const StockSubCategory: React.FC = () => {
    // Get the current location state and history using React Router hooks
    const locations = useLocation();
    let history = useHistory();

    // Function to go back in the history
    const doNothing = () => {
        history.goBack();
    };

    // Function to navigate to the material stock page
    const materialStock = (data: any) => {
        history.push({
            pathname: data.categoryURL,
            state: { "subCategoryId": data.id, "subCategoryName": data.categoryName, "categoryId": data.parentCategoryId, "status": "subCategory", "categoryUrl": data.categoryURL }
        }
        );
    }
    // State for sub-category list
    const [subCategoryList, setSubCategoryList] = useState([
        { id: 1, parentCategoryId: 1, categoryName: "material1", categoryURL: "" },
        { id: 2, parentCategoryId: 2, categoryName: "material2", categoryURL: "" },
    ]);
    // State for category ID and name
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');

    // Get user ID and token from local storage
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');

    // Fetch data when the component mounts or location state changes
    React.useEffect(() => {
        if (locations.state) {
            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);

            const fetchData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_parent_id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const subCategoryListArray: SubCategoryData[] = []
                    response.data['data'].forEach((data: any) => {
                        if (data.attributes.material_items.data.length != 0) {
                            subCategoryListArray.push({ id: data.id, parentCategoryId: data.attributes.material_parent_category.data.id, categoryName: data.attributes.material_sub_category_name, categoryURL: "/material-stock", "categoryStatus": 'subCategory' })
                        } else {
                            subCategoryListArray.push({ id: data.id, parentCategoryId: data.attributes.material_parent_category.data.id, categoryName: data.attributes.material_sub_category_name, categoryURL: "/stock-report", "categoryStatus": 'subCategory' })
                        }
                    })
                    setSubCategoryList(subCategoryListArray);
                    return response.data;

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            fetchData();
        }
    }, [locations]);
    // Get project name from local storage
    const projectName = localStorage.getItem('projectName');

    // Render the sub-category list
    const renderCategoryList = () => {
        return subCategoryList.map((x, i) => {
            return (
                <IonItem key={i} className="category-button" onClick={() => materialStock(x)}>
                    <IonLabel>
                        {x.categoryName}
                    </IonLabel>
                    <IonIcon icon="/assets/images/arrow-right-icon.svg" slot="end" />
                </IonItem>
            );
        })
    }

    //Render JSX Component
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonIconButton routerLink='/stock-category' icon="/assets/images/arrow-left-icon.svg" />
                                <div className="project-title-wrap">
                                    <h3><b>Stock</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>                            </div>
                            <h2 className="category-name">{categoryName}</h2>
                            <IonList className="category-list">
                                {renderCategoryList()}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default StockSubCategory;
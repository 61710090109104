import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IonButton, IonIcon, IonAlert } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useForm } from "react-hook-form";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import { format, parseISO } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import Inward from "../views/Inward/Index";
import Modal from "react-modal";
import exifr from 'exifr'
import UploadImg from "../components/Image/UploadImg";
import Logout from "../components/Logout";

declare global {
    interface Window {
        Buffer: any;
    }
}
window.Buffer = window.Buffer || require("buffer").Buffer;
Modal.setAppElement("#root");

//interface to check types checking
interface LocationState {
    categoryId: number;
    categoryName: string;
    status: string;
    categoryUrl: string;
    editStatus: boolean;
    inwardId: number;
    categoryTrace: object;
    materialItem: string;
    parentCatId: number;
}
interface MaterialListArr {
    id: number;
    material_item_name: string;
    material_unit: string;
    material_unitId: number;
}
interface pushState {
    categoryName: string;
    categoryId: number;
    pushBack: boolean;
    subCategory: object;
}
const InwardEntryBrick: React.FC = () => {
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image1Name, setImage1Name] = useState("");
    const [image2Name, setImage2Name] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [subCategory, setSubCategory] = useState({});
    const [colordata, setColordata] = useState('');
    const [parentCatId, setParentCatId] = useState(0);
    //For S3 Upload
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal1() {
        setIsOpen1(!isOpen1);
    }
    const locations = useLocation();
    const [categoryTrace, setCategoryTrace] = useState({});
    const [materialListData, setMaterialListData] = useState([
        { id: 1, "material_item_name": "materialitem1", "material_unit": "unit1", material_unitId: 1 },
        { id: 2, "material_item_name": "materialitem2", "material_unit": "unit2", material_unitId: 2 },
    ]);
    const [materialUnit, setMaterialUnit] = useState('');
    const [materialUnitId, setMaterialUnitId] = useState(0);
    const [truckListData, setTruckListData] = useState([
        { id: 1, attributes: { "truck_no": "truckno1", "truck_width": 1, "truck_height": 2, "truck_length": 3 } },
        { id: 2, attributes: { "truck_no": "truckno2", "truck_width": 1, "truck_height": 2, "truck_length": 3 } },
    ]);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [categoryUrl, setCategoryUrl] = useState('');
    const [materialItem, setMaterialItem] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [message, setMessage] = useState('');
    const [backData, setBackData] = useState('');
    const [imgDelete, setImgDelete] = useState(false);
    const [imgDelete1, setImgDelete1] = useState(false);
    const [materialItemId, SetMaterialItemId] = useState(0);
    const [isloading, setIsloading] = useState(false);
    const [inwardButton, setInwardButton] = useState(false);
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    var min = 1;
    var max = 100000;
    let history = useHistory();
    //Function for back page

    const backPage = () => {
        if (backData == 'materialstock') {
            // history.go(-1);
            history.push({ pathname: '/material-stock', state: { "categoryName": categoryName, "categoryId": categoryId, "subCategory": subCategory, "categoryTrace": categoryTrace, "parentCatId": parentCatId } });
        } else if (backData == 'stockreport') {
            // history.go(-1);
            history.push({ pathname: '/stock-report', state: { "categoryName": categoryName, "categoryId": categoryId, "categoryTrace": categoryTrace, "subCategory": subCategory, "materialId": materialItemId } });
        }
        else {
            var pushCategoryTrace = {} as pushState
            if (categoryTrace) {
                const categoryPush = categoryTrace as pushState
                pushCategoryTrace['categoryName'] = categoryPush['categoryName']
                pushCategoryTrace['categoryId'] = categoryPush['categoryId']
            }
            pushCategoryTrace["pushBack"] = true
            pushCategoryTrace["subCategory"] = subCategory;
            // history.go(-1);
            history.push({
                pathname: '/inward',
                state: pushCategoryTrace
            });
        }
    };
    const methods = useForm();
    const { handleSubmit, setValue, control, formState: { errors } } = methods;
    //Function that handles the form request while submiting the form
    const onSubmit = async (data: any) => {
        var one: any;
        var two: any;
        if (image1 != undefined) {
            try {
                const location = await UploadImg(image1, image1Name);

                console.log("location" , location);

                
                one = location;
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        if (image2 != undefined) {
            try {
                const location = await UploadImg(image2, image2Name);
                two = location;
            } catch (error: any) {
                console.log(error);
                if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                    Logout(history)
                }
            }
        }
        let inwardDate = new Date().toISOString();
        inwardDate = format(parseISO(inwardDate), 'yyyy-MM-dd')
        let materialItemData: any;
        if (data.material == '' || data.material == undefined) {
            materialItemData = null;
        } else {
            materialItemData = data.material;
        }
        setInwardButton(true)
        try {
            await axios({
                method: 'POST',
                url: ENV.ENDPOINT + '/inward-entries',
                data: {
                    "data": {
                        material_item: materialItemData,
                        inward_quantity: data.quantity,
                        material_truck: data.truckno,
                        inward_note: data.Notes,
                        workspace: workspaceId,
                        project: projectId,
                        material_category: categoryId,
                        inward_createdby: userId,
                        inward_date: inwardDate,
                        material_unit: materialUnitId,
                        inward_capture1: one,
                        inward_capture2: two,
                    }
                },
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            setColordata("success");
            setMessage("Inward Entry Created successfully");
            setShowToast1(true)
            setValue('material', '');
            setValue('truckno', '');
            setValue('quantity', '');
            setValue('Notes', '');
            setValue('postFile', '');
            setValue('postFile1', '');
            setFileList(defaultList);
            setFileList1(defaultList);
            setInwardButton(false)

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

        if (imgDelete) {
            one = "";
        }
        if (imgDelete1) {
            two = "";
        }
    };
    //Function for back button
    const doNothing = () => {
        history.goBack();
    }
    //API calling 
    React.useEffect(() => {
        let location: any;

        if (locations.state) {
            location = locations.state as LocationState;
            localStorage.setItem('routeState', JSON.stringify(location))
        } else {
            location = localStorage.getItem('routeState')
            if (location) location = JSON.parse(location)
        }
        if (locations) {
            setParentCatId(location.parentCatId);
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            setCategoryUrl(location.categoryUrl);
            setCategoryTrace(location.categoryTrace)
            setSubCategory(location.subCategory)
            setBackData(location.backpath)
            if (location.materialItem == null || location.materialItem == undefined || location.materialItem == "") {
                setMaterialItem("Material")
            } else {
                setMaterialItem(location.materialItem)
            }

            const myData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];

                    while (true) {
                        const response = await axios({
                            url: ENV.ENDPOINT + '/material-items?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&filters[material_category][id][$eq]=' + location.categoryId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userId,
                                token: token,
                            },
                        })
                        const responseData = response.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }

                    const materialdata: MaterialListArr[] = [];
                    allData.forEach((list: any) => {
                        if (list.attributes.workspace.data == null) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                        else if (list.attributes.workspace.data.id == workspaceId) {
                            materialdata.push({
                                "id": list.id, "material_item_name": list.attributes.material_item_name,
                                "material_unit": list.attributes.material_unit.data.attributes.material_unit_name, "material_unitId": list.attributes.material_unit.data.id
                            })
                        }
                    })
                    if (materialdata.length == 1 && materialdata[0].material_item_name == location.categoryName) {
                        setMaterialUnitId(materialdata[0].material_unitId)
                        setMaterialUnit(materialdata[0].material_unit);
                    }
                    setMaterialListData(materialdata);

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }

            const fetchData = async () => {
                try {
                    let page = 1;
                    let allData: any = [];
                    while (true) {
                        const response = await axios({

                            url: ENV.ENDPOINT + '/material-trucks?populate=*&filters[workspace][id][$eq]=' + workspaceId + '&pagination[page]=' + page,
                            method: 'get',
                            headers: {
                                Authorization: HeaderAuthorization.Authorization,
                                userId: userId,
                                token: token,
                            },
                        })
                        const responseData = response.data;
                        allData.push(...responseData.data);
                        if (responseData.data.length === 0) {
                            break; // No more data available
                        }
                        page++;
                    }
                    setTruckListData(allData);
                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }
            }
            setValue('material', '');
            setValue('truckno', '');
            setValue('quantity', '');
            setValue('Notes', '');
            setValue('postFile', '');
            setValue('postFile1', '');
            setFileList(defaultList);
            setFileList1(defaultList);

            myData();
            fetchData();
        }
    }, [locations]);
    //Function to create new material
    const createNewMaterial = () => {
        history.push({
            pathname: '/create-material',
            state: { "categoryId": categoryId, "categoryName": categoryName, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backData }
        }
        );
    }
    //Function to create new truck
    const createNewTruck = () => {
        history.push({
            pathname: '/create-truck',
            state: { "categoryId": categoryId, "categoryName": categoryName, "categoryUrl": categoryUrl, "materialItem": materialItem, "backpath": backData }
        }
        );
    }
    const workspaceId = localStorage.getItem('workspaceId');
    const projectId = localStorage.getItem('projectId');
    const projectName = localStorage.getItem('projectName');
    // function that calls when  material unit values are changing
    const onChange = async (data: any) => {

        const materialSetUnit = materialListData.filter(list => {
            if (list.id == data) {
                setMaterialUnit(list.material_unit);
                setMaterialUnitId(list.material_unitId)
                return list
            }
        })
    }
    const defaultList =
        { id: "", fileName: "" }
        ;

    const fileInput = useRef(null);
    const fileInput01 = useRef(null);
    const [removeFile, setRemoveFile] = useState(false);
    const [fileList, setFileList] = useState(defaultList);

    //handles the removal of image

    const handleRemoveClick = () => {
        setFileList(defaultList);
        setImgDelete(true);
        setValue('postFile', "");
    };
    var crd
    var lat1: any
    var lon1: any
    //Get the locaiton of device
    const location = () => {
        if (Capacitor.isNativePlatform()) {
            const printCurrentPosition = async () => {
                const coordinates = await Geolocation.getCurrentPosition();
                crd = coordinates.coords;
                lat1 = coordinates.coords.latitude
                lon1 = coordinates.coords.longitude
            }
            printCurrentPosition()
        }
        else {
            const printCurrentPosition = async () => {
                const coordinates = await Geolocation.getCurrentPosition();
                crd = coordinates.coords;
                lat1 = coordinates.coords.latitude
                lon1 = coordinates.coords.longitude
            }
            printCurrentPosition()
        }
    }
    location()

    //Funtion that handles the image loads from images
    const loadImageFromDevice = async (event: any) => {
        location()
        //location metadata
        let dataa = await exifr.gps(event.target.files[0])
        function computeDistance([prevLat, prevLong]: any, [lat, long]: any) {
            const prevLatInRad = toRad(prevLat);
            const prevLongInRad = toRad(prevLong);
            const latInRad = toRad(lat);
            const longInRad = toRad(long);
            return (
                6377.830272 *
                Math.acos(
                    Math.sin(prevLatInRad) * Math.sin(latInRad) +
                    Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
                )
            );
        }
        function toRad(angle: any) {
            return (angle * Math.PI) / 180;
        }
        const diff_minutes = (timeStart: any, timeEnd: any) => {
            var diff = ((timeEnd - timeStart) / 1000)
            diff /= 60;
            return Math.abs(Math.round(diff))
        }
        const file = event.target.files[0];
        var imgTime = new Date(file.lastModifiedDate).getTime();
        var currTime = Date.now();
        if (diff_minutes(imgTime, currTime) > 5) {
            setColordata("danger");
            setMessage("Upload latest image")
            setShowToast1(true)
        }
        else {
            if (dataa == undefined) {
                setImage1(event.target.files[0])
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    // get the blob of the image:
                    let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                    // create blobURL, such that we could use it in an image element:
                    let blobURL: string = URL.createObjectURL(blob);
                    let filename: string = file.name + "  " + file.lastModifiedDate;
                    let nameOnly = [];
                    let name = file.name;
                    nameOnly = name.split(".");
                    let timestamp = file.lastModifiedDate;
                    // timestamp=timestamp.toString().replace(/\s/g,"")
                    timestamp = timestamp.toString().split('GMT')
                    var number = Math.floor(Math.random() * (max - min + 1)) + min;
                    setValue('postFile', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                    setImage1Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                    setFileList({ id: "", fileName: filename });
                    if (isOpen) {
                        toggleModal()
                    }
                };
                reader.onerror = (error) => {
                };
            }
            else {
                if (computeDistance([lat1, lon1], [dataa.latitude, dataa.longitude]) > 2) {
                    setColordata("danger");
                    setMessage("out of range")
                    setShowToast1(true)
                }
                else {
                    setMessage("Got location and time")
                    setShowToast1(true)
                    setImage1(event.target.files[0])
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                        let blobURL: string = URL.createObjectURL(blob);
                        let filename: string = file.name + "  " + file.lastModifiedDate;
                        let nameOnly = [];
                        let name = file.name;
                        nameOnly = name.split(".");
                        let timestamp = file.lastModifiedDate;
                        timestamp = timestamp.toString().split('GMT')
                        var number = Math.floor(Math.random() * (max - min + 1)) + min;
                        setValue('postFile', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                        setImage1Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                        setFileList({ id: "", fileName: filename });
                        if (isOpen) {
                            toggleModal()
                        }
                    };
                    reader.onerror = (error) => {
                        //handle errors
                    };
                }
            }
        }
    };
    // capture images 
    const onImageClick = (event: any) => {
        event.target.value = ''
    }
    //render list for file of images
    const renderList = () => {
        return (
            <div className="uploaded-file">
                <p className="uploaded-file-name read-only">{fileList.fileName}</p>
                <IonButton fill="clear" onClick={() => setRemoveFile(true)}>
                    <IonIcon icon={close} />
                </IonButton>
                <IonAlert
                    isOpen={removeFile}
                    onDidDismiss={() => setRemoveFile(false)}
                    cssClass='red-alert'
                    mode='md'
                    header={'Remove File'}
                    message={'<p>Are you sure you want to remove this file?</p>'}
                    buttons={[
                        {
                            text: 'Yes',
                            cssClass: 'btn-secondary',
                            handler: (e) => {
                                handleRemoveClick();
                            }
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'btn-outline',
                            handler: () => {
                            }
                        }
                    ]} />
            </div>
        );
    }
    const fileInput1 = useRef(null);
    const fileInput12 = useRef(null);
    const [removeFile1, setRemoveFile1] = useState(false);
    const [fileList1, setFileList1] = useState(defaultList);
    //remove function for capture images
    const handleRemoveClick1 = () => {
        setFileList1(defaultList);
        setImgDelete1(true)
        setFileList1(defaultList)
    };
    //Funtion that handles the image loads from images
    const loadImageFromDevice1 = async (event: any) => {
        await location()
        //location metadata
        let dataa = await exifr.gps(event.target.files[0])
        function computeDistance([prevLat, prevLong]: any, [lat, long]: any) {
            const prevLatInRad = toRad(prevLat);
            const prevLongInRad = toRad(prevLong);
            const latInRad = toRad(lat);
            const longInRad = toRad(long);
            return (
                // In kilometers
                6377.830272 *
                Math.acos(
                    Math.sin(prevLatInRad) * Math.sin(latInRad) +
                    Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
                )
            );
        }
        function toRad(angle: any) {
            return (angle * Math.PI) / 180;
        }
        const diff_minutes = (timeStart: any, timeEnd: any) => {
            var diff = ((timeEnd - timeStart) / 1000)
            diff /= 60;
            return Math.abs(Math.round(diff))
        }
        const file = event.target.files[0];
        var imgTime = new Date(file.lastModifiedDate).getTime();
        var currTime = Date.now();
        if (diff_minutes(imgTime, currTime) > 5) {
            setColordata("danger");
            setMessage("Upload latest image")
            setShowToast1(true)
        }
        else {
            if (dataa == undefined) {
                setImage2(event.target.files[0])
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                    let blobURL: string = URL.createObjectURL(blob);
                    let filename: string = file.name + "  " + file.lastModifiedDate;
                    let nameOnly = [];
                    let name = file.name;
                    nameOnly = name.split(".");
                    let timestamp = file.lastModifiedDate;
                    timestamp = timestamp.toString().split('GMT')
                    var number = Math.floor(Math.random() * (max - min + 1)) + min;
                    setValue('postFile1', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                    setImage2Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                    setFileList1({ id: "", fileName: filename });
                    if (isOpen1) {
                        toggleModal1()
                    }
                };
                reader.onerror = (error) => {
                };
            }
            else {
                if (computeDistance([lat1, lon1], [dataa.latitude, dataa.longitude]) > 2) {
                    setColordata("danger");
                    setMessage("out of range")
                    setShowToast1(true)
                }
                else {
                    setImage2(event.target.files[0])
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        // get the blob of the image:
                        let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
                        // create blobURL, such that we could use it in an image element:
                        let blobURL: string = URL.createObjectURL(blob);
                        let filename: string = file.name + "  " + file.lastModifiedDate;
                        let nameOnly = [];
                        let name = file.name;
                        nameOnly = name.split(".");
                        let timestamp = file.lastModifiedDate;
                        // timestamp=timestamp.toString().replace(/\s/g,"")
                        timestamp = timestamp.toString().split('GMT')
                        var number = Math.floor(Math.random() * (max - min + 1)) + min;
                        setValue('postFile1', nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1]);
                        setImage2Name(nameOnly[0] + number + "( " + timestamp[0] + " )" + "." + nameOnly[1])
                        setFileList1({ id: "", fileName: filename });
                        if (isOpen1) {
                            toggleModal1()
                        }
                    };
                    reader.onerror = (error) => {
                        //handle errors
                    };
                }
            }
        }
    };
    // capture images 

    const onImageClick1 = (event: any) => {
        event.target.value = ''
    }
    //render list for file of images
    const renderList1 = () => {
        return (
            <div className="uploaded-file">
                <p className="uploaded-file-name read-only">{fileList1.fileName}</p>
                <IonButton fill="clear" onClick={() => setRemoveFile1(true)}>
                    <IonIcon icon={close} />
                </IonButton>
                <IonAlert
                    isOpen={removeFile1}
                    onDidDismiss={() => setRemoveFile1(false)}
                    cssClass='red-alert'
                    mode='md'
                    header={'Remove File'}
                    message={'<p>Are you sure you want to remove this file?</p>'}
                    buttons={[
                        {
                            text: 'Yes',
                            cssClass: 'btn-secondary',
                            handler: () => {
                                handleRemoveClick1();
                            }
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'btn-outline',
                            handler: () => {
                            }
                        }
                    ]} />
            </div>
        );
    }
    //passing the props

    const props = {
        type: "brick",
        Isupdate: "false",
        doNothing: doNothing,
        isOpen: isOpen,
        toggleModal: toggleModal,
        toggleModal1: toggleModal1,
        fileInput: fileInput,
        fileInput01: fileInput01,
        fileInput1: fileInput1,
        fileInput2: fileInput12,
        loadImageFromDevice: loadImageFromDevice,
        loadImageFromDevice1: loadImageFromDevice1,
        onImageClick: onImageClick,
        onImageClick1: onImageClick1,
        isOpen1: isOpen1,
        backPage: backPage,
        projectName: projectName,
        categoryName: categoryName,
        methods: methods,
        handleSubmit: handleSubmit,
        onSubmit: onSubmit,
        materialListData: materialListData,
        materialItem: materialItem,
        errors: errors,
        onChange: onChange,
        setValue: setValue,
        control: control,
        createNewMaterial: createNewMaterial,
        truckListData: truckListData,
        createNewTruck: createNewTruck,
        materialUnit: materialUnit,
        fileList: fileList,
        fileList1: fileList1,
        renderList: renderList,
        renderList1: renderList1,
        inwardButton: inwardButton,
        showToast1: showToast1,
        setShowToast1: setShowToast1,
        colordata: colordata,
        isloading: isloading,
        message: message,
    };


    return (
        Inward(props)
    );
};



export default InwardEntryBrick;
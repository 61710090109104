// Import necessary modules and components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonLabel, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonList, IonItem } from '@ionic/react';
import Header from "../components/Header/Header";
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from "../components/Logout";

// Define the interfaces for the data used in the component
interface SubCategoryData {
    id: number;
    parentCategoryId: number;
    categoryName: string;
    categoryURL: string
}
interface LocationState {
    subCategoryId: number;
    subCategoryName: string;
    categoryId: number;
    categoryName: string;
    status: string;
    isChildStatus: boolean
}

// Define the functional component for InwardSubCategory
const InwardSubCategory: React.FC = () => {
    // Get the current location using React Router's useLocation hook
    const locations = useLocation();
    // Get the history object to navigate between routes
    let history = useHistory();

    // Function to navigate back to the previous page
    const pageBack = () => {
        history.goBack();
    };

    // Function that does nothing (placeholder for future use)
    const doNothing = () => {
        history.goBack();
    }

    // Get the user ID and token from local storage
    const userId: any = localStorage.getItem('userid');
    const token: any = localStorage.getItem('token');
    // Function to add an inward entry
    const addInwardEntry = async (data: any) => {
        try {
            const response = await axios({
                url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_parent_id][$eq]=' + data.id,
                method: 'get',
                headers: {
                    Authorization: HeaderAuthorization.Authorization,
                    userId: userId,
                    token: token,
                },
            })
            if (response.data.data.length == 0) {
                history.push({
                    pathname: data.categoryURL,
                    state: { "subCategoryId": data.id, "subCategoryName": data.categoryName, "categoryId": data.parentCategoryId, "status": "subCategory", "categoryUrl": data.categoryURL }
                }
                );
            } else {
                history.push({
                    pathname: '/inward-sub-category',
                    state: { "categoryName": data.categoryName, "categoryId": data.id, "status": "category", "categoryUrl": data.categoryURL }
                }
                );
            }

        } catch (error: any) {
            console.log(error);
            if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                Logout(history)
            }
        }

    }
    // Initialize state variables for subCategoryList, categoryId, and categoryName
    const [subCategoryList, setSubCategoryList] = useState([
        { id: 1, parentCategoryId: 1, categoryName: "material1", categoryURL: "" },
        { id: 2, parentCategoryId: 2, categoryName: "material2", categoryURL: "" },
    ]);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState('');

    // Fetch data from the API when the component mounts or the location changes
    React.useEffect(() => {
        if (locations.state) {
            let location = locations.state as LocationState;
            setCategoryId(location.categoryId);
            setCategoryName(location.categoryName);
            const fetchData = async () => {
                try {
                    const response = await axios({
                        url: ENV.ENDPOINT + '/material-categories?populate=*&filters[material_parent_id][$eq]=' + location.categoryId,
                        method: 'get',
                        headers: {
                            Authorization: HeaderAuthorization.Authorization,
                            userId: userId,
                            token: token,
                        },
                    })
                    const subCategoryListArray: SubCategoryData[] = []
                    response.data['data'].forEach((data: any) => {
                        subCategoryListArray.push({ id: data.id, parentCategoryId: data.attributes.material_parent_id, categoryName: data.attributes.material_category_name, categoryURL: "/inward-entry" })
                    })
                    setSubCategoryList(subCategoryListArray);
                    return response.data;

                } catch (error: any) {
                    console.log(error);
                    if (error?.response?.status == 410 || error?.response?.status == 440 || error?.response?.status == 409) {
                        Logout(history)
                    }
                }

            }
            fetchData()

        }
    }, [locations]);
    // Get the projectName from local storage
    const projectName = localStorage.getItem('projectName');

    // Render the list of categories
    const renderCategoryList = () => {
        return subCategoryList.map((x, i) => {
            return (
                <IonItem key={i} className="category-button" onClick={() => addInwardEntry(x)}>
                    <IonLabel>
                        {x.categoryName}
                    </IonLabel>
                    <IonIcon icon="/assets/images/arrow-right-icon.svg" slot="end" />
                </IonItem>
            );
        })
    }
    // Render the component JSX
    return (
        <IonPage>
            <Header class="with-back-arrow" onBack={doNothing} />
            <IonContent fullscreen>
                <IonGrid className="full-height-div">
                    <IonRow className="main-row">
                        <IonCol size="12" sizeMd="6" sizeLg="4">
                            <div className="select-project-header">
                                <IonButton fill="clear" onClick={pageBack}>
                                    <IonIcon icon="/assets/images/arrow-left-icon.svg" ></IonIcon>
                                </IonButton>
                                <div className="project-title-wrap">
                                    <h3><b>Inward</b></h3>
                                    <h3><b>{projectName}</b></h3>
                                </div>
                            </div>
                            <h2 className="category-name">{categoryName}</h2>
                            <IonList className="category-list">
                                {renderCategoryList()}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default InwardSubCategory;
import {IonContent, IonPage, IonIcon,IonInput,IonButton,IonLabel, IonGrid,IonRow, IonCol,IonImg,IonToast,} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { personCircleSharp, keySharp } from "ionicons/icons";
import { FormProvider, useForm, FieldErrors, Controller, FieldValues } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import '../assets/css/Custom.css';
import '../assets/css/Responsive.css';
import axios from 'axios';
import React, { useState } from "react";
import { ENV } from '../config/config';
import { HeaderAuthorization } from "../authorization/HeaderAuthorization";
import Logout from '../components/Logout';
import Loader from "../components/Loader/Loader";

const Login: React.FC = () => {
  let history = useHistory();
  const loggedIn = localStorage.getItem("isLoggedIn")
  const [showToast1, setShowToast1] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [message, setMessage] = useState('');
  const [colordata, setColordata] = useState('');
  let userid:any = localStorage.getItem("userid");

  const methods = useForm({ mode: "onSubmit" });
  const {  handleSubmit, control, formState: { errors } } = methods;
    const onSubmit = async (data: any) => {
      setIsloading(true);
      try {
       const response = await axios({
          method: 'post',
          url: ENV.ENDPOINT + '/auth/local',
          data: {
            identifier: data.username,
            password: data.password
          },
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userid,
            PageLocation: "index",

        },            })


       const sessionExists = await axios({
          method: 'get',
          url: ENV.ENDPOINT + `/sessions?populate=*&filters[users_permissions_user][id][$eq]=${response.data.user.id}`,
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userid,
            PageLocation: "index",
        },            })
        // console.log("sessionExists.data.length", sessionExists.data.length, sessionExists.data);
        

        if(sessionExists.data.data.length > 0){
          // console.log("inside if");
          
          var session = await axios({
            method: 'put',
            url: ENV.ENDPOINT + `/sessions/${sessionExists.data.data[0].id}`,
            data: {
              "data": {
                token: response.data.jwt
              }
            },
            headers: {
              Authorization : HeaderAuthorization.Authorization,
              userId:userid,
              PageLocation: "index",
          },            })

        }else{
          // console.log("inside elsessss");
        session = await axios({
          method: 'post',
          url: ENV.ENDPOINT + '/sessions',
          data: {
            "data": {
              users_permissions_user: response.data.user.id,
              token: response.data.jwt
            }
          },
          headers: {
            Authorization : HeaderAuthorization.Authorization,
            userId:userid,
            PageLocation: "index",
        },            })
      }
        // console.log(session, "session");
        
          localStorage.clear();
          localStorage.setItem('isLoggedIn', "true")
          localStorage.setItem('userid', response.data.user.id);
          localStorage.setItem('user_roles', response.data.user.user_roles);
          localStorage.setItem('email', response.data.user.email);
          localStorage.setItem('logged_status' , response.data.user.logged_status)
          localStorage.setItem('token' , session.data.data.id)
          try {
            await axios({
              method: 'PUT',
              url: ENV.ENDPOINT + '/users/' + response.data.user.id?.toString(),
              data: {
                "logged_status": true,
              },
              headers: {
                Authorization : HeaderAuthorization.Authorization,
                userId:userid,
                PageLocation: "index",
            },                })
              history.push("/select-workspace");
              setIsloading(false);
              setColordata("success");
              setMessage("Login successfully");
              setShowToast1(true)
     
          } catch (error: any) {
            console.log(error); 
            setIsloading(false);
            if(error?.response?.status == 410){
                Logout(history)
              }           
        } 
      
      } 
        catch (error: any) {
          console.log(error); 
          setIsloading(false);
          if(error?.response?.status == 410){
              Logout(history)
            }           
        setIsloading(false);
        setColordata("danger");
        setMessage("Please check username and password");
        setShowToast1(true)
      }

  };
  React.useEffect(() => {
    if (loggedIn) {
      history.push('/select-workspace');
    }
    else {
      history.push('/index');
    }
  }, []);

  type ErrorSummaryProps<T extends FieldValues> = {
    errors: FieldErrors<T>;
  };
  function ErrorSummary<T extends FieldValues>({ errors }: ErrorSummaryProps<T>): JSX.Element | null {
    if (Object.keys(errors).length == 0) {
      return null;
    }
    return (
      <div className="error-summary">
        {Object.keys(errors).map((fieldName) => (
          <ErrorMessage errors={errors} name={fieldName as any} as="div" key={fieldName} />
        ))}
      </div>
    );
  }

  type ErrorMessageContainerProps = {
    children?: React.ReactNode;
  };
  const ErrorMessageContainer = ({ children }: ErrorMessageContainerProps) => (
    <span className="error">{children}</span>
  );

  return (
    <IonPage>
      <IonContent fullscreen>
      {isloading && <Loader />}
        <IonGrid className="full-height-div">
          <IonRow className="login-form-row">
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <div className="login-header">
                <IonImg src="/assets/images/login-icon.png" alt="Login Icon" />
                <h1>Sign in to CSM</h1>
              </div>
              <FormProvider {...methods}>
                <form className="login-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" className="email-field">
                        <IonLabel className="form-lable" >Mobile No. or Email address:*</IonLabel>
                        <div className="input-with-icon">
                          <IonIcon icon={personCircleSharp} />
                          <Controller
                            render={({ field: { onChange, onBlur, value } }) => (
                              <IonInput
                                type="text"
                                onIonChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                placeholder="Add text"
                                mode="md" />
                            )}
                            control={control}
                            name="username"
                            rules={{
                              required: "Please enter email address or phone number",
                              pattern: {
                                
                                value: /^([1-9][0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/,
                                message: "invalid email address or phone number"
                              }
                            }} />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="username"
                          as={<div className="error-message" style={{ color: 'red' }} />}  />
                      </IonCol>
                      <IonCol size="12" className="password-field">
                        <IonLabel className="form-lable" >Password:*</IonLabel>
                        <div className="input-with-icon">
                          <IonIcon icon={keySharp} />
                          <Controller
                            render={({ field: { onChange, onBlur, value } }) => (
                              <IonInput
                                type="password"
                                onIonChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                placeholder="Add text"
                                mode="md"  />
                            )}
                            control={control}
                            name="password"
                            rules={{
                              required: "Please enter password"
                            }}  />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="password"
                          as={<div className="error-message" style={{ color: 'red' }} />} />
                      </IonCol>
                      <IonCol size="6" className="login-btn">
                        <IonButton type="submit" fill="solid" >
                          Login
                        </IonButton>
                      </IonCol>
                      <IonCol size="6" className="forget-password ion-text-right">
                        <IonButton routerLink='/forget-password' fill="clear">
                          Forgot password
                        </IonButton>
                      </IonCol>
                      <IonCol size="12" className="create-account-btn">
                        <IonLabel className="form-lable" >New to CSM?</IonLabel>
                        <IonButton routerLink='/registration' expand="block" fill="solid" >
                          Create an Account
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </form>
              </FormProvider>
            </IonCol>
            <IonToast
              isOpen={showToast1}
              onDidDismiss={() => setShowToast1(false)}
              message={message}
              duration={1500}
              position="middle"
              color={colordata} />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;